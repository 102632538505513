import { Component, OnInit } from '@angular/core';
import { CountryI, CityI } from '../../../core/empresa/model.interface';
import { HttpClient, HttpEventType, HttpErrorResponse } from '@angular/common/http';

import { DataService } from '../../../services/empresa/articulo/data.service.service';

import { Router, Params } from '@angular/router';
import { FaConfig, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faBellSlash, faHandPaper, faUser } from '@fortawesome/free-regular-svg-icons';
import { faCoffee, faTrash, faTrashAlt, faPencilAlt, faTh, faCalendar, faCalendarAlt, faInfoCircle, faTrashRestore } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import * as $ from 'jquery';
import { FormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

//Modelo
import { buscarperfil } from '../../../core/empresa/buscarperfil';
import { consultarperfil } from '../../../core/empresa/consultarperfil';
import { Lregiones, Lcomuna } from '../../../core/parametros/regiones.model';
import { Lturnos, Lcargo, Lcursos, LSexo } from '../../../core/parametros/filtros.model';
import { perfil } from '../../../core/empresa/perfil';
//Servicio
import { BuscarempleadoService } from "../../../services/empresa/buscarempleado/buscarempleado.service";
import { LisregionesService } from '../../../services/parametros/lisregiones.service';
import { FiltrosService } from '../../../services/parametros/filtros.service';

//Material
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogoconfirmacionComponent } from '../../../dialogoconfirmacion/dialogoconfirmacion.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Inject } from '@angular/core';
import { faKickstarter } from '@fortawesome/free-brands-svg-icons';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

declare var $: any;
declare var jQuery: any;


@Component({
  selector: 'app-detalleempleado',
  templateUrl: './detalleempleado.component.html',
  styleUrls: ['./detalleempleado.component.css']
})
export class DetalleempleadoComponent implements OnInit {

  rutPefil: string;
  idCargo: string;


  //Modelo Evaluacion Empleados
  public perfilModel: perfil = new perfil('','', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '');


  formDetallePostulante: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private http: HttpClient,
    private ruta: ActivatedRoute,
    private router: Router,
    private regionService: LisregionesService,
    private buscarPerfilServices: BuscarempleadoService,
    private comboService: FiltrosService,
    //Material
    private snackBar: MatSnackBar,
    private dialogo: MatDialog,
    public dialogRef: MatDialogRef<DetalleempleadoComponent>,
  ) {
    this.crearForm();
  }

  ngOnInit(): void {

    this.rutPefil = this.data['rutPerfil'];
    this.idCargo = this.data['idcargo'];
    //LLama Servicio Carga

    this.obtenerPerfiles();
  }


  obtenerPerfiles() {
    return this.buscarPerfilServices.getVerDetalleEmpleado(this.rutPefil, this.idCargo).subscribe((perfilModel: perfil) => {
      this.perfilModel = perfilModel

      console.log(this.perfilModel);
    })
  }


  onSubmit() {
    this.onClose();
  }

  onClose() {
    this.dialogRef.close();
  }


  crearForm() {
    this.formDetallePostulante = this.fb.group({
      rut: [''],
      nombreCompleto: [''],
      fechaNacimiento: [''],
      nombreSexo: [''],
      nacionalidad: ['']
    })
  }


  imprimirLista() {

    const DATA = document.getElementById('dataImprime');
    const doc = new jsPDF('p', 'pt', 'a4');
    const options = {
      background: 'white',
      scale: 3,
      fontSize: 15
    };
    html2canvas(DATA, options).then((canvas) => {

      const img = canvas.toDataURL('image/PNG');

      // Add image Canvas to PDF
      const bufferX = 15;
      const bufferY = 15;
      const imgProps = (doc as any).getImageProperties(img);
      const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');

      console.log(imgProps);
      console.log(pdfWidth);
      console.log(pdfHeight);



      return doc;
    }).then((docResult) => {
      docResult.save(`${new Date().toISOString()}_tutorial.pdf`);
    });
  }



}
