

<!-- <footer class="footer">
  <div class="container">
  <div class="content has-text-centered">
    <p>
      © 2020 Copyright:
      <a href="https://mdbootstrap.com/"> MDBootstrap.com</a>
    </p>
  </div>
  </div>
  </footer> -->

  <footer class="footer">
    <div class="text-center">
      <span class="text-muted">REDDINF.COM - RED DE INFORMACIÓN</span>
    </div>
  </footer>