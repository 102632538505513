import { TitulosService } from './../../../services/parametros/titulos.service';
import { EncriptarService } from './../../../services/seguridad/encriptar.service';
import { IngempresaService } from './../../../services/admin/Ingempresa/ingempresa.service';
import { PerfilEmpresa, ExisteRutEmpresa } from './../../../core/admin/perfilempresa';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { FormGroup, FormControl, Validators, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { faCoffee, faTrash, faTrashAlt, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { ConexionService } from './../../../services/conexion.service';
import { confirmedValidator } from '../../../confirmed.validator';
import { RutService } from 'rut-chileno';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogoconfirmacionComponent } from "../../../dialogoconfirmacion/dialogoconfirmacion.component";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import * as $ from 'jquery';


declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css']
})
export class RegistroComponent implements OnInit {

  hide = true;

  dataSource = null;

  public perfilempresaModel: PerfilEmpresa = new PerfilEmpresa('', '', '', '', '', '', '', '', '', '');
  public listadoEmpresa: PerfilEmpresa[] = [new PerfilEmpresa('', '', '', '', '', '', '', '', '', '')];
  public exiteRutEmpresa: ExisteRutEmpresa = new ExisteRutEmpresa('', '');


  formRegEmpresa: FormGroup;
  errorMessage: string = '';
  successMessage: string = '';

  titulo: string;
  imagen: string;
  curso: string;

  faTrashAlt = faTrashAlt;
  faPencilAlt = faPencilAlt;

  listarRegistro: boolean;
  mostrarFormEmpleo: boolean;
  public muestraTabla: string;

  rutBuscar: string;
  out1_rut: String;

  item: any = {
    name: '',
    id: ''
  };

  eliminaEmpresa: any = {
    idEmpresa: ''
  };

  constructor(
    public http: HttpClient,
    private ruta: ActivatedRoute,
    private ingempresaService: IngempresaService,
    private router: Router,
    private fb: FormBuilder,
    private service: ConexionService,
    private rutService: RutService,
    public encriptar: EncriptarService,
    private snackBar: MatSnackBar,
    private dialogo: MatDialog,
    private nombreTituloService: TitulosService
  ) {
    this.titulo = "Formulario de Registro Empresa";
    this.imagen = "https://media.istockphoto.com/vectors/online-registration-form-vector-id1199278357";
    this.curso = "OS-10";
    this.createForm();
  }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  ngOnInit(): void {
    $(document).ready(function () {
      $("input#rutEmpresa").rut({ validateOn: 'blur' }).on('rutInvalido', function () {
        $(".rutErrorEmp").addClass("alert alert-danger")
        $(".rutErrorEmp").text("Rut inválido");
        $('input#rutEmpresa').val('');


      }).on('rutValido', function () {
        $(".rutErrorEmp").removeClass("alert alert-danger ");
        $(".rutErrorEmp").empty();
      });

      $("input#rutRepresentante").rut({ validateOn: 'blur' }).on('rutInvalido', function () {
        $(".rutErrorRep").addClass("alert alert-danger");
        $(".rutErrorRep").text("Rut inválido");
      }).on('rutValido', function () {
        $(".rutErrorRep").removeClass("alert alert-danger ");
        $(".rutErrorRep").empty();
      });


      $('#mostrar').click(function () {
        //Comprobamos que la cadena NO esté vacía.
        if ($(this).hasClass('mdi-eye') && ($("#passEmpresa").val() != '')) {
          $('#passEmpresa').removeAttr('type');
          $('#mostrar').addClass('mdi-eye-off').removeClass('mdi-eye');
          $('.pwdtxt').html("Ocultar contraseña");
        }
        else {
          $('#passEmpresa').attr('type', 'password');
          $('#mostrar').addClass('mdi-eye').removeClass('mdi-eye-off');
          $('.pwdtxt').html("Mostrar contraseña");
        }
      });
    });
    this.seteaBloques();
  }

  private emailPattern: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


  createForm() {
    this.formRegEmpresa = this.fb.group({
      rutEmpresa: ['', [Validators.required]],
      nomEmpresa: ['', [Validators.required]],
      rutRepresentante: ['', [Validators.required]],
      nomRepresentante: ['', [Validators.required]],
      dirEmpresa: ['', [Validators.required]],
      telEmpresa: ['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      passEmpresa: ['', [Validators.required]],
      passEmpresa1: ['', [Validators.required]],
      emailEmpresa: ['', [Validators.required, Validators.pattern(this.emailPattern), Validators.maxLength(20)]],
    },
      {
        validator: confirmedValidator('passEmpresa', 'passEmpresa1')
      }
    );
  }


  public checkError = (controlName: string, errorName: string) => {
    return this.formRegEmpresa.controls[controlName].hasError(errorName);
  }


  onSubmit() {

    console.log(this.perfilempresaModel);

    if (this.formRegEmpresa.valid) {

      // this.perfilempresaModel.passEmpresa = this.encriptar.encriptarDatos(this.perfilempresaModel.passEmpresa);
      this.perfilempresaModel.rutEmpresa = String(this.rutService.getRutChile(2, this.perfilempresaModel.rutEmpresa));
      this.perfilempresaModel.rutRepresentante = String(this.rutService.getRutChile(2, this.perfilempresaModel.rutRepresentante));

      this.ingempresaService.addEmpresa(this.perfilempresaModel).subscribe(event => {
        if (event.type === HttpEventType.Response) {
          console.log("With Parsed JSON :", event.body);
          if (event.body['resultado'] === false) {
            alert("ERROR INGRESO");
          }
        }
        this.router.navigate(['/accesoAdmin/RegistrarEmpresa/1']);
        this.formRegEmpresa.reset();
      })
    }
    else {
            this.dialogo.open(DialogoconfirmacionComponent, {
        data: `Por favor complete todos los campos`,
      });
    }
  }


  validaRut(rut) {
    rut = String(this.rutService.getRutChile(2, rut));
    console.log(rut);

    return this.ingempresaService.verificaRutEmpresa(rut).subscribe((exiteRutEmpresa: ExisteRutEmpresa) => {
      this.exiteRutEmpresa = exiteRutEmpresa
      if (exiteRutEmpresa.rutEmpresa) {
        alert("USUARIO YA EXISTE EN EL SISTEMA");
        this.perfilempresaModel.rutEmpresa = null;
      }
    })
  }



  listadoEmpresas() {
    return this.ingempresaService.listadoEmpresa().subscribe((listadoEmpresa: PerfilEmpresa[]) => {
      this.listadoEmpresa = listadoEmpresa
      this.dataSource = new MatTableDataSource<PerfilEmpresa>(this.listadoEmpresa);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }


  deleteEmpresa(Item) {

    this.dialogo
      .open(DialogoconfirmacionComponent, {
        data: `¿Realmente quieres eliminar la Empresa N° ` + Item + `?`
      })
      .afterClosed()
      .subscribe((confirmado: Boolean) => {
        if (!confirmado) return;
        this.ingempresaService
          .deleteEmpresa(Item)
          .subscribe(() => {
            this.snackBar.open('Empresa eliminada ', Item, {
              duration: 3000,
            });
            this.listadoEmpresas();
          });
      });
  }

  editarEmpresa(idEmpresa) {
    this.eliminaEmpresa = idEmpresa;
    console.log(this.eliminaEmpresa);
  }


  public seteaBloques() {
    this.ruta.params.subscribe(params => {
      switch (params['id']) {
        case '1': {
          this.nombreTituloService.nombreTitulos = "Gestionar Empresa";
          this.mostrarFormEmpleo = true;
          this.muestraTabla = 'none';
          break;
        }
        case '2': {
          this.nombreTituloService.nombreTitulos = "Listar Empresas";
          this.mostrarFormEmpleo = false;
          this.muestraTabla = 'block';
          this.listadoEmpresas();
          break;
        }
        default: {
          this.router.navigate(['/accesoAdmin']);
          break;
        }
      }
    })
  }


  getRut(rut: string): void {
    console.log(rut);
  }



}