import { TitulosService } from './../services/parametros/titulos.service';
import { Globals } from './../globals';
import { Router } from '@angular/router';
import { ApiService } from '../services/login/api.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-encabezado',
  templateUrl: './encabezado.component.html',
  styleUrls: ['./encabezado.component.css']
})
export class EncabezadoComponent implements OnInit {

  loginbtn: boolean;
  logoutbtn: boolean;
  perfilAdmin: boolean = false;
  perfilEmpresa: boolean = false;
  perfilPostulante: boolean = false;
  nombreUsuario: string;


  constructor(
    protected dataService: ApiService,
    private router: Router,
    public globals: Globals,
    public nombreTituloService: TitulosService
  ) {

  }

  public app_name: string = 'App';
  public isLogged: boolean = false;


  ngOnInit() {


    this.perfilAdmin = false;
    this.perfilEmpresa = false;
    this.perfilPostulante = false;
    // this.globals.nombre = "Usuari@";

    this.dataService.getLoggedInName.subscribe(name => this.changeName(name));




    if (this.dataService.isLoggedIn()) {

      this.globals.perfil = this.dataService.getPerfil();
      this.globals.email = this.dataService.getEmail();
      this.globals.rutEmpresa = this.dataService.getRutEmpresa();
      this.globals.nombre = this.dataService.getToken();
      this.nombreTituloService.NombreUser = this.dataService.getToken();

      this.loginbtn = false;
      this.logoutbtn = true;

      if (this.globals.perfil === "1") {
        this.perfilAdmin = true;
        // this.perfilEmpresa = false;
        // this.perfilPostulante = false;    

      }
      if (this.globals.perfil === "2") {
        this.perfilEmpresa = true;
        // this.perfilAdmin = false;
        // this.perfilPostulante = false;

      }
      if (this.globals.perfil === "3") {
        this.perfilPostulante = true;
        this.nombreUsuario = this.dataService.getToken();
        // this.perfilAdmin = false;
        // this.perfilEmpresa = false;
      }
    }
    else {
      this.loginbtn = true;
      this.logoutbtn = false;
      this.perfilAdmin = false;
      this.perfilEmpresa = false;
      this.perfilPostulante = false;
    }
  }


  private changeName(name: boolean): void {
    console.log("name");
    console.log(name);
    this.logoutbtn = name;
    this.loginbtn = !name;

    this.globals.perfil = this.dataService.getPerfil();
    this.nombreTituloService.NombreUser = this.dataService.getToken();

    if (this.globals.perfil === "1") {
      this.perfilAdmin = true;
      // this.perfilEmpresa = false;
      // this.perfilPostulante = false;

    }

    if (this.globals.perfil === "2") {
      this.perfilEmpresa = true;
      // this.perfilAdmin = false;
      // this.perfilPostulante = false;
    }

    if (this.globals.perfil === "3") {
      this.perfilPostulante = true;
      // this.nombreTituloService.NombreUser = this.dataService.getToken();
      // this.perfilAdmin = false;
      // this.perfilEmpresa = false;
    }
  }

  logout() {
    this.dataService.deleteToken();
    this.logoutbtn = false;
    this.loginbtn = true;
    this.perfilAdmin = false;
    this.perfilEmpresa = false;
    this.perfilPostulante = false;
    this.nombreUsuario = "";
    this.nombreTituloService.NombreUser = "Usuari@";
    this.router.navigate(['/inicio']);
  }

  loginResponsive() {
    if (!this.dataService.getToken()) {
      this.router.navigate(['/loginPersona']);
    }
  }
}
