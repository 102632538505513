import { PostulantesModel } from './../../core/publico/postulantes.model';
import { PubempleosService } from './../../services/admin/pubempleos/pubempleos.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ListEmpleos } from './../../core/admin/empleos';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogConfig } from '@angular/material/dialog';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { PostularComponent } from './../../privado/postulantes/postular/postular.component';
import { throwToolbarMixedModesError } from '@angular/material/toolbar';

@Component({
  selector: 'app-detalle-empleo',
  templateUrl: './detalle-empleo.component.html',
  styleUrls: ['./detalle-empleo.component.css']
})
export class DetalleEmpleoComponent implements OnInit {

  public empleosModel: ListEmpleos = new ListEmpleos('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '');


  constructor(
    private ruta: ActivatedRoute,
    private router: Router,
    private pubEmpleosService: PubempleosService,
    private dialog: MatDialog,

  ) { }

  ngOnInit(): void {

    // this.callbackImprimir(() => { });
    this.obternerDatos();
  }

  obternerDatos() {
    let idEmpleo = this.ruta.snapshot.paramMap.get("id");
    return this.pubEmpleosService.getOneEmpleo(idEmpleo)
      .subscribe((empleosModel: ListEmpleos) => {
        this.empleosModel = empleosModel

        setTimeout(() => {
          console.log("TIEMPO TRANSCURIDO");
          // this.imprimirLista();  // prueba se debe descomentar para imprimir al abrir pagina
        }, 900);
      })
  }


  imprimirLista() {
    const DATA = document.getElementById('htmlData');
    const doc = new jsPDF('l', 'pt', 'letter');
    const options = {
      background: 'white',
      scale: 3,
      fontSize: 15
    };
    html2canvas(DATA, options).then((canvas) => {
      const img = canvas.toDataURL('image/PNG');

      // Add image Canvas to PDF
      const bufferX = 15;
      const bufferY = 15;
      const imgProps = (doc as any).getImageProperties(img);
      const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');

      console.log(imgProps);
      console.log(pdfWidth);
      console.log(pdfHeight);

      return doc;
    }).then((docResult) => {
      docResult.save(`${new Date().toISOString()}_tutorial.pdf`);
    });
  }

  postularEmpleo(datosEmpleo: ListEmpleos) {

    console.log(datosEmpleo.correoEmpresa);

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.dialog.open(PostularComponent, {
      height: '400px',
      width: '650px',
      disableClose: true,
      data: {
        nombreEmpresa: datosEmpleo.empresa,
        cargo: datosEmpleo.cargoEmpleo,
        correoEmpresa: datosEmpleo.correoEmpresa,
        idEmpleo: this.ruta.snapshot.paramMap.get("id")
      }
    });
  }


  volver() {
    this.router.navigate(['/accesoPostulante/portalEmpleo']);
  }

}

