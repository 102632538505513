import { TitulosService } from './../../../services/parametros/titulos.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FaConfig, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faBellSlash, faHandPaper, faUser } from '@fortawesome/free-regular-svg-icons';
import { faCoffee, faTrash, faTrashAlt, faPencilAlt, faTh, faCalendar, faCalendarAlt, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import * as $ from 'jquery';

//Material
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogoconfirmacionComponent } from '../../../dialogoconfirmacion/dialogoconfirmacion.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
//Servicios
import { consultarempleado } from '../../../core/empresa/consultarempleado';
import { ConsultarempleadoService } from '../../../services/empresa/consultarempleado/consultarempleado.service';
import { DetallemisevaluacionesComponent } from '../../empresa/detallemisevaluaciones/detallemisevaluaciones.component';
import { ConstantPool } from '@angular/compiler';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-listaevaluacionesempleado',
  templateUrl: './listaevaluacionesempleado.component.html',
  styleUrls: ['./listaevaluacionesempleado.component.css']
})
export class ListaevaluacionesempleadoComponent implements OnInit {
  public disableTextbox;
  public rutConsultado;
  public showSpinner = false;
  dataSource = null;


  rutPefil: string;
  idCargo: string;
  // formConsultaEmp: FormGroup;


  public empleadosModel: consultarempleado[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ruta: ActivatedRoute,
    private router: Router,
    private servicioConsulta: ConsultarempleadoService,
    private dialogo: MatDialog,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private nombreTituloService: TitulosService,
    public dialogRef: MatDialogRef<ListaevaluacionesempleadoComponent>,
  ) { }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  ngOnInit(): void {

    this.rutPefil = this.data['rutPerfil'];
    console.log("rut lleganfo");
    console.log(this.rutPefil);
    this.idCargo = this.data['idcargo'];

    this.nombreTituloService.nombreTitulos = "Consulta Empleado";
    
    this.rutConsultado = this.data['rutPerfil'];
    this.obtenerConsultado();

    $(document).ready(function () {
      $("input#rutConsultado").rut({ validateOn: 'blur' }).on('rutInvalido', function () {
        $(".rutError").addClass("alert alert-danger");
        $(".rutError").text("Rut inválido");
        $('input#rutConsultado').val('');

      }).on('rutValido', function () {
        $(".rutError").removeClass("alert alert-danger ");
        $(".rutError").empty();
      });
    })

    // this.obtenerConsultado();  
  }


  onChange(enable: boolean) {

    if (enable) {
      this.rutConsultado = "";
      this.rutConsultado = "Traer Todos los evaluados";
      this.disableTextbox = true;

      this.showSpinner = true;
      setTimeout(() => {
        this.showSpinner = false;
      }, 5000
      )

      return this.servicioConsulta.ListarTodosLosEvaluados().
        subscribe((empleados: consultarempleado[]) => {

          console.log(empleados);
          this.empleadosModel = empleados;
          this.dataSource = new MatTableDataSource<consultarempleado>(this.empleadosModel);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        });


    }
    else {
      this.disableTextbox = false;
      console.log("luego b" + this.disableTextbox);
      this.rutConsultado = "";
      this.dataSource = new MatTableDataSource<consultarempleado>();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }

  }


  onSubmit() {

  }

  onClose() {
    this.dialogRef.close();
  }
  verDetalle(empleado: consultarempleado) {
    console.log("Desde mis eval:" + empleado);
    console.log(empleado);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    this.dialog.open(DetallemisevaluacionesComponent, {
      height: '600px',
      width: '800px',
      data: {
        idEvaluacion: empleado.idEvaluacion,
        dataorigen: "consultarempleado",
        estado: empleado.estado,
        observacion: "",

      }
    });
  }

  obtenerConsultado() {
    if (!this.rutConsultado) {

      let snackBarRef = this.snackBar.open('DEBE INGRESAR UN RUT A CONSULTAR', undefined, {
        duration: 2000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
        panelClass: ["custom-style"]
      });

    }
    else {

      console.log("Ejecuto" + this.rutConsultado);
      return this.servicioConsulta.ConsultarEmpleado(this.rutConsultado).
        subscribe((empleados: consultarempleado[]) => {
          this.empleadosModel = empleados
          this.dataSource = new MatTableDataSource<consultarempleado>(this.empleadosModel);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        });

    }
  }
}
