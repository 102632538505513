<section class="hero-area" id="home">
  <div class="hero-area-slider">
    <div class="hero-area-single-slide1">
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <div class="hero-area-content">
              <h1>Red de Información</h1>
              <h3>¿Quiere saber, a quien esta contratando?
                contamos con la información que usted necesita</h3>
              <br>
              <!-- <a href="#" class="appao-btn" routerLink="/loginPersona">Ingreso Empresa</a> -->
              <a *ngIf="!loginbtn" class="appao-btn" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="/postula">Ingresa tu Curriculum</a>
              <!-- <a href="#" class="appao-btn" routerLink="/loginPersona">Ingreso Administrador</a> -->
              <a *ngIf="!loginbtn" class="appao-btn" data-toggle="collapse" data-target=".navbar-collapse.show"
              routerLink="/portal">Bolsa de Empleos</a>
            </div>
          </div>
          <div class="col-lg-5">
            <div class="hand-mockup text-lg-left text-center">
              <img src="../../assets/img/kisspng-security.png" alt="Hand Mockup" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="about">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 mx-autob ">
        <owl-carousel-o [options]="customOptions">
          <ng-template carouselSlide *ngFor="let item of publicidades; index as i">
            <a [href]="item.url" target="_blank"><img [src]="'/funaWS/Upload/docs/' +  item.imagenUrl" class="img-thumbnail media"></a>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>