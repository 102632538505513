<!-- <form (ngSubmit)="onSubmit()" [formGroup]="formBuscar"> -->
<section id="BuscarEmpleado">
    <div class="container col-lg-12 col-md-12 col-sm-12 py-2 mx-auto">
        <div class="card card0 border-0">
            <div class="row d-flex">
                <div class="container-fluid">
                    <div class="row gutters-sm">
                        <div class="col-md-4 mt-2 ml-2 mb-2">
                            <div class="form-group card mt-2">
                                <div class="card-body">
                                    <div class="d-flex flex-column align-items-center text-center">
                                        <img src="https://cflvdg.avoz.es/sc/4UkTJCAYrB0wJWd_hd4lLzTr33Y=/x/2017/01/22/00121485107936127344615/Foto/trabajoh.jpg"
                                            alt="Admin" class="rounded-circle" width="150" />
                                        <div class="mt-2">
                                            <h4>{{titulo}}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group card mt-1 mx-auto">
                                <div class="card-header">Busqueda Nuevo Empleado
                                    <span class="float-right" ngbTooltip="Ayuda">
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-info-circle"
                                            fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd"
                                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path
                                                d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z" />
                                            <circle cx="8" cy="4.5" r="1" />
                                        </svg>
                                    </span>
                                </div>
                                <p class="text-justify mx-auto" style="width: 90%;">Mediante este formularios, podrá
                                    realizar
                                    busqueda de nuevo personal, para esto deberá utilizar los filtros, por lo cuales
                                    desea segmentar, a los
                                    posibles candidatos.</p>
                            </div>
                        </div>
                        <div class="col-md-7 mt-3 mb-2 ">
                            <div class="card mb-1">
                                <div class="card-body">

                                    <div class="form-row">
                                        <div class="form-group col-md-4">
                                            <label for="lblFecIniPub">cargo </label>
                                            <select name="cargo" id="cargo" class="form-control my-1" ng-model="cargo"
                                                [(ngModel)]="busquedaPerfil.idcargo">
                                                <option value="0">Seleccione Cargo</option>
                                                <option *ngFor="let cargos of cargoModel" value="{{cargos.pra_val}}">
                                                    {{cargos.pra_des}}
                                                </option>
                                            </select>

                                        </div>

                                        <div class="form-group col-md-4">
                                            <label for="lblFecIniPub">Cursos</label>
                                            <select name="curso" id="curso" class="form-control my-1" ng-model="curso"
                                                [(ngModel)]="busquedaPerfil.idcurso">
                                                <option value="0" selected>Seleccione Cursos</option>
                                                <option *ngFor="let cursos of cursoModel" value="{{cursos.pra_val}}">
                                                    {{cursos.pra_des}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="form-group col-md-4">
                                            <label for="lblJornada">Sexo Empleado</label>
                                            <select name="sexo" id="sexo" class="form-control my-1" ng-model="sexo"
                                                [(ngModel)]="busquedaPerfil.idsexo">
                                                <option value="0">Seleccione Sexo</option>
                                                <option *ngFor="let Sexos of SexoModel" value="{{Sexos.pra_val}}">
                                                    {{Sexos.pra_des}}
                                                </option>
                                            </select>
                                        </div>

                                    </div>

                                    <div class="form-row">
                                        <div class="form-group col-md-4">
                                            <label for="lblFecIniPub">Region</label>
                                            <select name="regiones" id="region" class="form-control my-1"
                                            (change)="onChangeComuna($event.target.value)"
                                            ng-model="regiones" [(ngModel)]="busquedaPerfil.idregion">
                                                <option value="0">Seleccione Región</option>
                                                <option *ngFor="let regiones of regionesModel"
                                                    value="{{regiones.REGION_ID}}">{{regiones.REGION_NOMBRE}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="form-group col-md-4">
                                            <label for="lblJornada">Comuna</label>
                                            <select name="comuna" id="comuna" class="form-control my-1"
                                                ng-model="comuna" [(ngModel)]="busquedaPerfil.idcomuna">
                                                <option value="0">Seleccione Comuna</option>
                                                <option *ngFor="let comuna of comunaModel" value="{{comuna.COMUNA_ID}}">
                                                    {{comuna.COMUNA_NOMBRE}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="form-group col-md-4">
                                            <label for="lblFecIniPub">Turno </label>
                                            <select name="turno" id="turno" class="form-control my-1" ng-model="turno"
                                                [(ngModel)]="busquedaPerfil.idturno">
                                                <option value="0">Seleccione Turno</option>
                                                <option *ngFor="let turnos of turnoModel" value="{{turnos.pra_val}}">
                                                    {{turnos.pra_des}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-md-6 text-center">
                                            <div class="col-md-6">
                                                <button type="submit" class="btn btn-blue text-center mb-1 py-2"
                                                    (click)="onSubmit()">Buscar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>

    </div>
</section>
<!-- </form> -->


<div class="container-fluid py-2 mx-auto">
    <div class="card card0 border-1">
        <div class="col-lg-12 col-md-12 col-sm-12 mx-auto mb-4">
            <div class="card-body">
                <div class="wrapper-editor">
                    <h2 class="mb-1">Listado de Perfiles</h2>


                    <p class="mb-4 text-sm"></p>
                    <div id="htmlData">
                        <table mat-table [dataSource]="dataSource" matSort matSortStart="desc"
                            *ngIf="buscarperfil.length > 0" class="mat-elevation-z8" style="width:
                    100%;">
                            <ng-container matColumnDef="ids">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
                                <td mat-cell *matCellDef="let consultarempleado; let i = index;"> {{
                                    (paginatorRef.pageIndex *
                                    paginatorRef.pageSize) + (i + 1) }} </td>
                            </ng-container>

                            <ng-container matColumnDef="rut">
                                <th mat-header-cell *matHeaderCellDef>RUT</th>
                                <td mat-cell *matCellDef="let perfil"> {{perfil.rut}} </td>
                            </ng-container>
                            <ng-container matColumnDef="nombreCompleto">
                                <th mat-header-cell *matHeaderCellDef>Nombre Completo</th>
                                <td mat-cell *matCellDef="let perfil"> {{perfil.nombreCompleto}} </td>
                            </ng-container>
                            <ng-container matColumnDef="fechaNacimiento">
                                <th mat-header-cell *matHeaderCellDef>Fecha Nacimiento</th>
                                <td mat-cell *matCellDef="let perfil"> {{perfil.fechaNacimiento}} </td>
                            </ng-container>
                            <ng-container matColumnDef="nombreSexo">
                                <th mat-header-cell *matHeaderCellDef>Sexo</th>
                                <td mat-cell *matCellDef="let perfil"> {{perfil.nombreSexo}} </td>
                            </ng-container>

                            <ng-container matColumnDef="nombrePuesto">
                                <th mat-header-cell *matHeaderCellDef>Puesto</th>
                                <td mat-cell *matCellDef="let perfil"> {{perfil.nombrePuesto}} </td>
                            </ng-container>
                            <ng-container matColumnDef="nombreCurso">
                                <th mat-header-cell *matHeaderCellDef>Curso</th>
                                <td mat-cell *matCellDef="let perfil"> {{perfil.nombreCurso}} </td>
                            </ng-container>

                            <ng-container matColumnDef="Detalle">
                                <th mat-header-cell *matHeaderCellDef>Detalle</th>
                                <td mat-cell *matCellDef="let perfil">
                                    <button mat-raised-button color="warn" (click)="verDetalleEvaluacion(perfil)"
                                        mat-icon-button>
                                        <mat-icon>launch</mat-icon>
                                    </button>
                                </td>
                            </ng-container>
                            

                            <ng-container matColumnDef="evaluacion">
                                <th mat-header-cell *matHeaderCellDef>Evaluación</th>
                                <td mat-cell *matCellDef="let perfil">
                                   <button mat-raised-button color="primary" *ngIf="perfil.evaluacion > 0"
                                        (click)="verEvaluacionEmpleado(perfil)"
                                    mat-icon-button>
                                    <mat-icon color="red">attribution</mat-icon>
                                </button>
                                </td>
                            </ng-container>

                            <!-- 
                               -->


                            <tr mat-header-row *matHeaderRowDef="[
                    'rut',
                    'nombreCompleto',
                    'fechaNacimiento',
                    'nombreSexo',
                    'nombrePuesto',
                    'nombreCurso',
                    'Detalle',
                    'evaluacion']">
                            </tr>
                            <tr mat-row *matRowDef="let fila; columns: [
                    'rut',
                    'nombreCompleto',
                    'fechaNacimiento',
                    'nombreSexo',
                    'nombrePuesto',
                    'nombreCurso',
                    'Detalle'
                    ,'evaluacion'];">
                            </tr>
                        </table>
                    </div>
                    <div *ngIf="buscarperfil.length === 0">NO SE ENCONTRARON REGISTROS</div>

                    <mat-paginator #paginatorRef [pageSizeOptions]="[10, 15]" showFirstLastButtons>
                    </mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>


<div>
<!-- <button class="btn col col-md-4 col-lg-4 btn-danger btn-block ml-4" (click)="imprimirLista()">Descargar PDF</button>`-->
</div>


<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>