export class PostulantesModel {
   constructor(
      public rut: string,
      public sexo: string,
      public nombreCompleto: string,
      public fechaNacimiento: string,
      public calle: string,
      public numero: string,
      public depto: string,
      public region: string,
      public id_region: string,
      public comuna: string,
      public id_comuna: string,
      public telefono: string,
      public celular: string,
      public educacion: string,
      public cursoAcreditacion: string,
      public fechaAcreditacion: string,
      public experiencia: string,
      public especialidad: string,
      public nivelComputacion: string,
      public turnos: string,
      public passEmpresa: string,
      public nacionalidad: string,
      public estadoCivil: string,
      public email: string,
      public correoEmpresa: string
   ) {}
}

export class ExisteRutPostulante{
   constructor(
       public existeRut: string,
       public rutPostulante: string,
   ){}
}

export class PostulantesLaboralModel {
   constructor(
      public rut: string,
      public educacion: string,
      public cursoAcreditacion: string,
      public fechaAcreditacion: string,
      public experiencia: string,
      public nivelComputacion: string,
      public turnos: string,
      public puestotrabajo: string
   ) {}
}

export class ValidaRutPuestoModel {
   constructor(
      public rut: string,
      public puestotrabajo: string
   ) {}
}


export class PostulanteEmpleoModel{
   constructor(
      public rutPostulante: string,
      public idEmpleo: string,
      public correoEmpresa: string,
      public correoPostulante: string,
      public mensajeCorreo: string
   ) {}
}