import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PaginatorEspanol } from './../../core/paginatorespanol/paginator-espanol';

import { AuthguardGuard } from './../../core/authguard.guard';
import { RutModule } from 'rut-chileno';
import { EditRegistroComponent } from '../administrador/edit-registro/edit-registro.component';
import { EditEmpleoComponent } from '../administrador/edit-empleo/edit-empleo.component';
import { EditPublicidadComponent } from '../administrador/edit-publicidad/edit-publicidad.component';
import { RegistroComponent } from './../administrador/registro/registro.component';
import { GestionarEmpleoComponent } from '../administrador/gestionar-empleo/gestionar-empleo.component';
import { GestionarPublicidadComponent } from '../administrador/gestionar-publicidad/gestionar-publicidad.component';
import { AutorizarFunaComponent } from '../administrador/autorizar-funa/autorizar-funa.component';
import { AccesoAdminComponent } from './acceso-admin.component';
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatSelectModule } from '@angular/material/select';



const routes: Routes = [
  {

    path: 'accesoAdmin', component: AccesoAdminComponent, canActivate: [AuthguardGuard],
    children: [
      { path: 'RegistrarEmpresa/:id', component: RegistroComponent, canActivate: [AuthguardGuard] },
      { path: 'AutorizarFuna', component: AutorizarFunaComponent, canActivate: [AuthguardGuard] },
      { path: 'GestionarPublicidad/:id', component: GestionarPublicidadComponent, canActivate: [AuthguardGuard] },
      { path: 'GestionarEmpleo/:id', component: GestionarEmpleoComponent, canActivate: [AuthguardGuard] },
      { path: 'EditPublicidad/:id', component: EditPublicidadComponent, canActivate: [AuthguardGuard] },
      { path: 'EditRegistro/:idRegistro', component: EditRegistroComponent, canActivate: [AuthguardGuard] },
      { path: 'EditEmpleo/:idEmpleo', component: EditEmpleoComponent, canActivate: [AuthguardGuard] }
    ]
  }
];

@NgModule({
  declarations: [
    AccesoAdminComponent,
    RegistroComponent,
    AutorizarFunaComponent,
    GestionarPublicidadComponent,
    GestionarEmpleoComponent,
    EditPublicidadComponent,
    EditRegistroComponent,
    EditEmpleoComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes),
    FontAwesomeModule,
    RutModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    HttpClientModule,
    MatTableModule,
    MatDialogModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatNativeDateModule,
    MatInputModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatGridListModule,
    BrowserAnimationsModule,
    MatSortModule,
    MatCheckboxModule,
    MatSelectModule
  ],
  exports: [
    RouterModule
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: PaginatorEspanol
    }
  ]
  // ,
  // bootstrap: [AppComponent]
})
export class AccesoAdminModule {

}

