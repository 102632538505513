import { busquedaPerfil } from './../../../core/empresa/perfil';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { buscarperfil } from '../../../core/empresa/buscarperfil';

import { environment } from '../../../../environments/environment';
import { from, observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BuscarempleadoService {

  baseUrl = environment.baseUrl
  constructor(private http: HttpClient) { }


  getVerDetalleEmpleado(rut: string | number, idcargo: string | number) {
    return this.http.get(`${this.baseUrl}/Empresa/buscarperfil/getDetalleperfil.php?rut=${rut}&idcargo=${idcargo}`);
  }


  getBuscarEmpleado(parametros: busquedaPerfil) {
    return this.http.post(`${this.baseUrl}/Empresa/buscarperfil/getListarPerfil.php`, parametros);
  }

}





