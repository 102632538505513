<form [formGroup]="formPostulantes" (ngSubmit)="agregaPostulantes()">
   <section id="guardiaPostula">
      <div class="container-fluid py-2 mx-auto">
         <div class="card card0 border-0">
            <div class="row">
               <div class="container-fluid">
                  <div class="row mx-3">
                     <div class="col-md-4 mt-2 ml-2 mb-2">
                        <div class="form-group card mt-2">
                           <div class="card-body">
                              <div class="d-flex flex-column align-items-center text-center">
                                 <img src="{{imagen}}" alt="Admin" class="rounded-circle" width="180" />
                                 <div class="mt-3">
                                    <h4>{{titulo}}</h4>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div class="form-group card mt-4">
                           <div class="form-group mt-2">
                              <mat-form-field appearance="outline" class="example-full-width-1">
                                 <mat-label>Educación</mat-label>
                                 <mat-select formControlName="educacion" [(ngModel)]="postulanteLaboral.educacion">
                                    <mat-option value="" selected>Seleccione Nivel Educacional</mat-option>
                                    <mat-option *ngFor="let nivelEdu of parametroModel" value="{{nivelEdu.pra_val}}">
                                       {{nivelEdu.pra_des}}
                                    </mat-option>
                                 </mat-select>
                              </mat-form-field>
                           </div>

                           <div class="form-group ">
                              <mat-form-field appearance="outline" class="example-full-width-1">
                                 <mat-label>Nivel Computación</mat-label>
                                 <mat-select formControlName="nivelComputacion"
                                    [(ngModel)]="postulanteLaboral.nivelComputacion">
                                    <mat-option value="" selected>Seleccione Nivel Computacional</mat-option>
                                    <mat-option *ngFor="let nivelCompu of parametroTipoComputacion"
                                       value="{{nivelCompu.pra_val}}">
                                       {{nivelCompu.pra_des}}
                                    </mat-option>
                                 </mat-select>
                              </mat-form-field>
                           </div>

                           <div class="form-group">
                              <mat-form-field appearance="outline" class="example-full-width-1">
                                 <mat-label>Turnos</mat-label>
                                 <mat-select formControlName="turnos" [(ngModel)]="postulanteLaboral.turnos">
                                    <mat-option value="" selected>Seleccione Turno</mat-option>
                                    <mat-option *ngFor="let pTurnos of parametroTurnos" value="{{pTurnos.pra_val}}">
                                       {{pTurnos.pra_des}}
                                    </mat-option>
                                 </mat-select>
                              </mat-form-field>
                           </div>


                           <div class="form-group card mt-4" *ngIf="!ocultarFecha">
                              <div class="card-header"><strong>{{curso}}</strong>
                              </div>
                              <div class="row">
                                 <div class="col-md-5 ml-1 mt-2" align="center">
                                    <div class="custom-control custom-radio custom-control-inline ">
                                       <input type="radio" id="cursoSi" name="cursoAcreditacion"
                                          class="custom-control-input" formControlName="cursoAcreditacion" value="SI"
                                          [(ngModel)]="postulanteLaboral.cursoAcreditacion"
                                          (change)="changeGender($event)">
                                       <label class="custom-control-label" for="cursoSi">
                                          SI
                                       </label>
                                    </div>

                                    <div class="custom-control custom-radio custom-control-inline">
                                       <input type="radio" id="cursoNo" name="cursoAcreditacion"
                                          class="custom-control-input" formControlName="cursoAcreditacion" value="NO"
                                          [(ngModel)]="postulanteLaboral.cursoAcreditacion"
                                          (change)="changeGender($event)">
                                       <label class="custom-control-label" for="cursoNo">
                                          NO
                                       </label>
                                    </div>
                                 </div>
                                 <div class="col-md-6 mt-2" *ngIf="!habilitarFecha">
                                    <mat-form-field appearance="outline" class="example-full-width-1">
                                       <mat-label>Fecha Acreditación</mat-label>
                                       {{chosenYearDate}}
                                       <input matInput [matDatepicker]="picker2" id="fechaAcre" name="fechaAcreditacion"
                                       formControlName="fechaAcreditacion"
                                          [(ngModel)]="postulanteLaboral.fechaAcreditacion"
                                          [disabled]='disableTextbox' />
                                       <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                       <mat-datepicker #picker2></mat-datepicker>
                                    </mat-form-field>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div class="col-md-7 mt-3 ml-3 mr-1 mb-2">
                        <div class="card mb-1">
                           <div class="card-body">
                              <div class="form-row">
                                 <div class="form-group col-md-6 my-1">
                                    <label for="lbltRut">RUT</label>
                                    <br>
                                    <div class="detalle my-1">
                                       <label>{{postulanteModel.rut}}</label>
                                    </div>
                                    <p class="rutError" id="validacion-live"></p>

                                 </div>
                                 <div class="form-group col-md-6 my-1">
                                    <label for="lblNombre">Nombre Completo</label>
                                    <br>
                                    <div class="detalle my-1">
                                       <label>{{postulanteModel.nombreCompleto}}</label>
                                    </div>
                                 </div>

                                 <div class="form-group col-md-6 my-1">
                                    <label for="lblFechaNacimiento">Fecha de Nacimiento</label>
                                    <div class="detalle my-1">
                                       <label>{{postulanteModel.fechaNacimiento}}</label>
                                    </div>
                                 </div>


                                 <div class="form-group col-md-6 my-1">
                                    <label for="lblSexo">Sexo</label>
                                    <div class="detalle my-1">
                                       <label>{{postulanteModel.sexo}}</label>
                                    </div>

                                 </div>
                              </div>
                              <hr />
                              <div class="form-row">
                                 <div class="form-group col-md-6 my-1">
                                    <label for="lblDireccion">Región</label>
                                    <div class="detalle my-1">
                                       <label>{{postulanteModel.region}}</label>
                                    </div>
                                 </div>
                                 <div class="form-group col-md-6 my-1">
                                    <label for="lblDireccion">Comuna</label>
                                    <div class="detalle my-1">
                                       <label>{{postulanteModel.comuna}}</label>
                                    </div>
                                 </div>
                                 <div class="form-group col-md-6 my-1">
                                    <label for="lblDireccion">Dirección</label>
                                    <div class="detalle my-1">
                                       <label>{{postulanteModel.calle}}</label>
                                    </div>
                                 </div>
                                 <div class="form-group col-md-6 my-1">
                                    <label for="lblComuna">numero</label>
                                    <div class="detalle my-1">
                                       <label>{{postulanteModel.numero}}</label>
                                    </div>
                                 </div>
                                 <hr />
                                 <div class="form-group col-md-6 my-1">
                                    <label for="lblTelefono">Telefono</label>
                                    <div class="detalle mt-1">
                                       <label>{{postulanteModel.telefono}}</label>
                                    </div>
                                 </div>
                                 <div class="form-group col-md-6 my-1">
                                    <label for="lblCelular">Celular</label>
                                    <div class="detalle mt-1">
                                       <label>{{postulanteModel.celular }}</label>
                                    </div>
                                 </div>
                                 <div class="form-group col-md-12 my-1">
                                    <label for="lblCelular">Correo Electronico</label>
                                    <div class="detalle mt-1">
                                       <label>{{postulanteModel.email}}</label>
                                    </div>
                                 </div>
                              </div>
                              <hr />
                              <div class="form-row">
                                 <div class="form-group col-md-6">
                                    <label for="inputPassword4">Experiencia Laboral</label>
                                    <div class="custom-control custom-radio">
                                       <input type="radio" class="custom-control-input" id="customSwitch1"
                                          name="experiencia" formControlName="experiencia" value="1"
                                          [(ngModel)]="postulanteLaboral.experiencia" />
                                       <label class="custom-control-label" for="customSwitch1">
                                          0 a 1 años
                                       </label>
                                       <br />
                                    </div>
                                    <div class="custom-control custom-radio">
                                       <input type="radio" class="custom-control-input" id="customSwitch2"
                                          name="experiencia" formControlName="experiencia" value="3"
                                          [(ngModel)]="postulanteLaboral.experiencia" />
                                       <label class="custom-control-label" for="customSwitch2">
                                          1 a 3 años
                                       </label>
                                       <br />
                                    </div>

                                    <div class="custom-control custom-radio">
                                       <input type="radio" class="custom-control-input" id="customSwitch3"
                                          name="experiencia" formControlName="experiencia" value="3+"
                                          [(ngModel)]="postulanteLaboral.experiencia" />
                                       <label class="custom-control-label" for="customSwitch3">
                                          más de 3 años
                                       </label>
                                       <br />
                                    </div>
                                 </div>

                                 <hr />

                                 <!-- <div class="form-group col-md-6">
                                    <label for="inputPassword4">Locación</label>
                                    <div class="custom-control custom-checkbox">
                                       <input type="checkbox" class="custom-control-input" id="locCondominio"
                                          name="locacion" formControlName="locacion"
                                          [(ngModel)]="postulanteLaboral.locacion" />
                                       <label class="custom-control-label" for="locCondominio">
                                          Condominio
                                       </label>
                                       <br />
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                       <input type="checkbox" class="custom-control-input" id="locEmpresa"
                                       name="locacion" formControlName="locacion"
                                          [(ngModel)]="postulanteLaboral.locacion" />
                                       <label class="custom-control-label" for="locEmpresa">
                                          Empresa
                                       </label>
                                       <br />
                                    </div>

                                    <div class="custom-control custom-checkbox">
                                       <input type="checkbox" class="custom-control-input" id="locRetail"
                                       name="locacion" formControlName="locacion"
                                          [(ngModel)]="postulanteLaboral.locacion" />
                                       <label class="custom-control-label" for="locRetail">
                                          Retail
                                       </label>
                                       <br />
                                    </div>
                                 </div> -->
                              </div>

                              <hr />
                              <div class="form-row">
                                 <div class="form-group col-md-6">
                                    <button type="submit" class="btn btn-primary">Postular</button>
                                 </div>
                                 <div class="form-group col-md-6">
                                    <button class="btn btn-outline-primary">Limpiar</button>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
</form>