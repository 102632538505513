<span class="fill-remaining-space"></span>
<button mat-icon-button class="close-button" (click)="onClose()">
   <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>

<!--  -->
<form (ngSubmit)="onSubmit()" [formGroup]="formListar">
   <section id="EvaluarEmpleado">
      <div class="container py-2 mx-auto">
         <div class="card card0 border-0">
            <div class="row d-flex">
               <div class="container">
                  <div class="main-body">
                     <div class="row gutters-sm">
                        <div class="col-md-12 mt-3 ml-3 mb-2 mr-2">
                           <div class="card mb-1">
                              <div class="card-body">
                                 <div id="imprimir_{{empleadoModel.idEvaluacion}}">
                                    <div class="form-row">
                                       <div class="form-group col-md-6">
                                          <label for="lblCargoEmplei">Rut Empleado </label>
                                          <input type="text" class="form-control my-1"
                                             placeholder="Ingresa Rut Empleado" formControlName="rutempleado"
                                             id="rutempleado" [(ngModel)]="empleadoModel.rutempleado" readonly />
                                          <p class="rutErrorEmpl" id="validacion-live"></p>
                                       </div>

                                       <div class="form-group col-md-6">
                                          <label for="lblProfesion">Nombre Completo Empleado</label>
                                          <input type="text" class="form-control my-1"
                                             placeholder="Ingresa Nombre Empleado" formControlName="nombreempleado"
                                             id="nombreEmpleado" [(ngModel)]="empleadoModel.nombreempleado" #name=""
                                             readonly />
                                       </div>
                                    </div>

                                    <div class="form-row">
                                       <div class="form-group col-md-6">
                                          <label for="lblRegion">Fecha Ingreso Empresa</label>
                                          <input type="date" class="form-control my-1" id="fechaingreso"
                                             formControlName="fechaingreso" [(ngModel)]="empleadoModel.fechaingreso"
                                             readonly />
                                       </div>
                                       <div class="form-group col-md-6">
                                          <label for="lblComuna">Fecha Termino Empresa</label>
                                          <input type="date" class="form-control my-1" id="fechatermino"
                                             formControlName="fechatermino" [(ngModel)]="empleadoModel.fechatermino"
                                             readonly />
                                       </div>
                                    </div>

                                    <div class="form-row">
                                       <div class="form-group col-md-6">
                                          <label for="lblFecIniPub">Articulo</label>
                                          <select name="idarticulo" id="idarticulo my-1" class="form-control my-1"
                                             readonly (change)="onChangeinciso($event.target.value)"
                                             formControlName="idarticulo" [(ngModel)]="empleadoModel.idarticulo">
                                             <option value="">Seleccione Articulo</option>
                                             <option *ngFor="let articulos of articulomodel"
                                                value="{{articulos.idarticulo}}">{{articulos.nombrearticulo}}
                                             </option>
                                          </select>
                                       </div>
                                       <div class="form-group col-md-6">
                                          <label for="lblJornada">Inciso</label>
                                          <select name="idinciso" id="idinciso my-1" class="form-control my-1"
                                             [(ngModel)]="empleadoModel.idinciso" readonly formControlName="idinciso">
                                             <option value="">Seleccione Inciso</option>
                                             <option *ngFor="let incisos of incisomodel" value
                                                value="{{incisos.idinciso}}">{{incisos.nombreincisocorto}}
                                             </option>
                                          </select>
                                       </div>
                                    </div>


                                    <div class="form-row">
                                       <div class="form-group col-md-12">
                                          <label for="lblDireccion">Observación</label>
                                          <input #message [readonly]="habilitar" class="form-control my-1"
                                             formControlName="Observacion" placeholder="Ingresar Observación" rows="2"
                                             id="Observacion" [(ngModel)]="empleadoModel.Observacion" maxlength="50">
                                             <mat-hint align="end">{{message.value.length}} / 50</mat-hint>
                                       </div>
                                    </div>


                                    <div class="form-row">
                                       <div class="form-group col-md-6">
                                          <label for="lblNivelEduacional">Autorización Información</label>
                                          <label class="checkbox">
                                             <input id="autorizacion" type="checkbox" name="autorizacion" readonly
                                                class="form-control my-1" formControlName="autorizacion"
                                                placeholder="Autorizar" [(ngModel)]="empleadoModel.autorizacion" />
                                             Autorizo a Empresa InfoReed, a utilizar la información del despido, para
                                             fines correspondientes.
                                          </label>
                                       </div>
                                       <div class="form-group col-md-6">
                                          <label for="lblJornada">¿Recomendaría al empleado?</label>
                                          <div>
                                             <label for="lblNo" ngClass="labelBeforeSlide">NO</label>

                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 
                                 <br>
                                 <div class="form-row">
                                    <div class="form-group col-md-6 text-center">
                                       <div class="col-md-6"><button type="submit"
                                             class="btn btn-blue text-center mb-1 py-2"
                                             *ngIf="habilitarboton">{{btnEjecutar}}</button></div>
                                    </div>
                                    <div class="form-group col-md-6 text-center">
                                       <button class="btn btn-outline-primary" (click)="Cancelar()">Cancelar</button>
                                    </div>
                                 </div>

                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

   </section>
</form>