export class Lregiones {
    constructor(
        public REGION_ID: number, 
        public REGION_NOMBRE: string, 
    ){}
}

export class Lcomuna {
    constructor(
        public COMUNA_ID: number, 
        public COMUNA_NOMBRE: string, 
    ){}
}