<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet">

<br>
<br>
<div class="container">
  <div class="row justify-content-center">
    <div class="col-md-9 mx-auto">
      <div class="wrapper fadeInDown">
        <div id="formContent2" class="card-group mb-1">
          <div class="card p-4">
            <form [formGroup]="angForm" (ngSubmit)="postdata(angForm)" autocomplete="off">
              <div class="card-body">
                <h1>Login</h1>
                <p class="text-muted">Inicia sesión con tu cuenta</p>
                <div class="fadeIn first">
                  <img width="100" height="100" src="https://image.flaticon.com/icons/svg/2943/2943270.svg"
                    class="loaded">
                </div>

                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-user"></i></span>
                  </div>
                  <input type="text" class="form-control" id="email" name="email" formControlName="email"
                    placeholder="Usuario" aria-label="Username" aria-describedby="basic-addon1">
                </div>

                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-lock"></i></span>
                  </div>
                  <input type="password" class="form-control" id="password" name="password" formControlName="password"
                    placeholder="Password" aria-label="Username" aria-describedby="basic-addon1">
                </div>

                <div class="row">
                  <div class="col-12">
                    <button type="button" (click)="postdata(angForm)" class="btn btn-primary px-4">Login</button>
                  </div>
                  <div class="col-6 text-right">
                    <button type="button" class="btn btn-link px-0" [routerLink]="['/recuperarPassword']">Recuperar password</button>
                  </div>
                </div>
              </div>
            </form>
          </div>


          <div *ngIf="!labelRegistrar" class="card text-white bg-primary py-5 d-md-down-none" style="width:44%">
            <div class="card-body text-center">
              <div>
                <h2>Registrese</h2>
                <p>Registre para que tenga acceso a postular a ofertas de empleos y dejar su curriculum</p>
                <button type="submit" class="btn btn-primary active mt-3"
                  [routerLink]="['/registroPostulante']">Registrese Ahora!</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>