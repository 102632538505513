<form [formGroup]="formGestionPublicidad" (ngSubmit)="onSubmit()" #formMascota="ngForm">
    <section id="guardiaPostula">
        <div class="container py-2 mx-auto">
            <div class="card card0 border-0">
                <div class="row d-flex">
                    <div class="container">
                        <div class="main-body">
                            <div class="row gutters-sm">
                                <div class="col-md-3 mt-2 ml-2 mr-2 mb-2">
                                    <div class="form-group card mt-1">
                                        <div class="card-body">
                                            <div class="d-flex flex-column align-items-center text-center">
                                                <img src="{{this.imagenTitulo}}" alt="Admin" class="rounded-circle"
                                                    width="180" />
                                                <div class="mt-2">
                                                    <h4>{{this.titulo}}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group card mt-4 mx-auto">
                                        <div class="card-header">Descripción
                                            <span class="float-right" ngbTooltip="Ayuda">
                                                <svg width="1em" height="1em" viewBox="0 0 16 16"
                                                    class="bi bi-info-circle" fill="currentColor"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd"
                                                        d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                    <path
                                                        d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z" />
                                                    <circle cx="8" cy="4.5" r="1" />
                                                </svg>
                                            </span>
                                        </div>
                                        <p class="text-justify mx-auto" style="width: 90%;">Lorem ipsum dolor sit
                                            amet
                                            consectetur adipisicing elit. Facilis perspiciatis nam quod soluta odit
                                            voluptatibus dicta a ipsam voluptates, eos at iure sunt tempora debitis
                                            dignissimos! Quidem, non. Beatae doloribus aperiam ex at eius obcaecati
                                            soluta voluptas culpa quidem dolor, dolorem sed voluptatum in magnam!
                                            Sequi
                                            autem animi non laudantium.</p>
                                    </div>
                                </div>

                                <div class="col-md-8 mt-3 ml-3 mb-2 mr-2">
                                    <div class="card mb-1">
                                        <div class="card-body">

                                            <div class="form-row">
                                                <div class="form-group col-md-6">
                                                    <label for="lblRutEmpresa">Rut Empresa </label>
                                                    <input type="text" class="form-control my-1"
                                                        formControlName="rutEmpresa" id="rutEmpresa"
                                                        [(ngModel)]="publicidadModel.rutEmpresa" />
                                                    <p class="rutErrorEmp" id="validacion-live"></p>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label for="lblNombreEmpresa">Nombre Empresa</label>
                                                    <input type="text" class="form-control my-1"
                                                        formControlName="nomEmpresa" id="nomEmpresa"
                                                        [(ngModel)]="publicidadModel.nomEmpresa" id="nomEmpresa" />
                                                </div>
                                            </div>

                                            <div class="form-row">
                                                <div class="form-group col-md-6">
                                                    <label for="lblnomContacto">Nombre Contacto</label>
                                                    <input type="text" class="form-control my-1"
                                                        formControlName="nomContacto" id="nomContacto"
                                                        [(ngModel)]="publicidadModel.nomContacto" />
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label for="lblCelular">Teléfono Contacto</label>
                                                    <input type="number" class="form-control my-1"
                                                        formControlName="telefonoContacto" id="telefonoContacto"
                                                        [(ngModel)]="publicidadModel.telefonoContacto" />
                                                </div>
                                            </div>

                                            <div class="form-row">
                                                <div class="form-group col-md-6">
                                                    <label for="lblFecIniPub">Fecha Inicio</label>
                                                    <input type="date" class="form-control my-1" id="fecIniPub"
                                                        [(ngModel)]="publicidadModel.fecIniPub"
                                                        formControlName="fecIniPub" />
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label for="lblFecTerPub">Fecha Termino</label>
                                                    <input type="date" class="form-control my-1" id="fecTerPub"
                                                        [(ngModel)]="publicidadModel.fecTerPub"
                                                        formControlName="fecTerPub" />
                                                </div>
                                            </div>


                                            <div class="form-row">
                                                <div class="form-group col-md-12">
                                                    <label for="lblFechaNacimiento">Email</label>
                                                    <input type="email" class="form-control my-1" id="email"
                                                        [(ngModel)]="publicidadModel.email" formControlName="email" />
                                                </div>

                                                
                                                <div class="form-group col-md-12">
                                                    <label for="lblUrl">Url Sitio</label>
                                                    <input type="text" class="form-control my-1" id="url"
                                                        [(ngModel)]="publicidadModel.url" formControlName="url" />
        
                                                </div>

                                            </div>
                                            <div class="alert alert-danger"
                                                *ngIf="formGestionPublicidad.get('email').errors && formGestionPublicidad.get('email').touched">
                                                <p *ngIf="formGestionPublicidad.get('email').hasError('email')"> Correo
                                                    invalido</p>
                                                <p *ngIf="formGestionPublicidad.get('email').hasError('required')">
                                                    Campo Requerido</p>
                                            </div>

                                            <div class="form-row">
                                                <div class="form-group col-md-12">
                                                    <label for="lblSubirArchivo">Subir Archivo</label>
                                                    <input type="file" (change)="fileChange($event)"
                                                        formControlName="subirArchivo" class="form-control my-1"
                                                        id="subirArchivo" />


                                                    <img [src]="imageSrc" *ngIf="imageSrc"
                                                        style="height: 300px; width:300px">

                                                    <img [src]="'assets/upload/' + publicidadModel.imagenUrl"
                                                        *ngIf="!imageSrc" style="height: 200px; width:200px">


                                                    <div>
                                                        <button type="button" class="btn btn-outline-primary"
                                                            (click)="uploadFile()">SUBIR </button>
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <input type="hidden" class="form-control my-1" id="imagenUrl"
                                                        [(ngModel)]="publicidadModel.imagenUrl"
                                                        formControlName="imagenUrl"
                                                        [ngClass]="{ 'is-invalid': submitted && f.imagenUrl.errors }" />


                                                    <div *ngIf="submitted && f.imagenUrl.errors">
                                                        <div class="alert alert-danger"
                                                            *ngIf="f.imagenUrl.errors.required">Debe subir archivo antes
                                                            de enviar formulario
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <label for="lbldescPublicidad">Descripción de su
                                                    publicidad</label>
                                                <textarea class="form-control my-1" id="descPublicidad" rows="2"
                                                    formControlName="descPublicidad"
                                                    [(ngModel)]="publicidadModel.descPublicidad"></textarea>
                                            </div>


                                            <hr />

                                        </div>

                                        <div class="form-row">
                                            <div class="form-group col-md-6 text-center">
                                                <button type="submit"
                                                    class="btn btn-outline-primary">Actualizar</button>
                                            </div>
                                            <div class="form-group col-md-6 text-center">
                                                <button class="btn btn-outline-primary" (click)="volver()">
                                                    Volver</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</form>