import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncriptarService {

  textoEncriptado: string;
  password: string;

  constructor() { }


  encriptarDatos(texto: string) {
    this.password = "funa";
    return CryptoJS.AES.encrypt(texto.trim(), this.password.trim()).toString();
  }

  
  desencriptarDatos(textoEnc: string) {
    this.password = "funa";
    return CryptoJS.AES.decrypt(textoEnc.trim(), this.password.trim()).toString(CryptoJS.enc.Utf8);
  }
}
