import { TitulosService } from './../../../services/parametros/titulos.service';
import { Component, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FaConfig, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faBellSlash, faHandPaper, faUser } from '@fortawesome/free-regular-svg-icons';
import { faCoffee, faTrash, faTrashAlt, faPencilAlt, faTh, faCalendar, faCalendarAlt, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { debounceTime } from 'rxjs/operators';

//Modelo
import { buscarperfil } from '../../../core/empresa/buscarperfil';
import { consultarperfil } from '../../../core/empresa/consultarperfil';
import { Lregiones, Lcomuna } from '../../../core/parametros/regiones.model';
import { Lturnos, Lcargo, Lcursos, LSexo } from '../../../core/parametros/filtros.model';
import { perfil, busquedaPerfil } from '../../../core/empresa/perfil';
import { DetalleempleadoComponent } from '../../empresa/detalleempleado/detalleempleado.component';

//Servicio
import { BuscarempleadoService } from "../../../services/empresa/buscarempleado/buscarempleado.service";
import { LisregionesService } from '../../../services/parametros/lisregiones.service';
import { FiltrosService } from '../../../services/parametros/filtros.service';
import { consultarempleado } from './../../../core/empresa/consultarempleado';
import { DetallemisevaluacionesComponent } from './../detallemisevaluaciones/detallemisevaluaciones.component';
//Material

import { DialogoconfirmacionComponent } from "../../../dialogoconfirmacion/dialogoconfirmacion.component";
import { MatSnackBar } from '@angular/material/snack-bar';
import { AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { importType } from '@angular/compiler/src/output/output_ast';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import * as $ from 'jquery';
import { ListaevaluacionesempleadoComponent } from '../listaevaluacionesempleado/listaevaluacionesempleado.component';

@Component({
    selector: 'app-buscarempleado',
    templateUrl: './buscarempleado.component.html',
    styleUrls: ['./buscarempleado.component.css']
})


export class BuscarempleadoComponent implements OnInit {
    formBuscar: FormGroup;
    titulo: string;
    imagenTitulo: string;
    curso: string;
    btnEjecutar: string = '';
    //Valores Seleccionados
    public idCargo: string;
    public idCurso: string;
    public idSexo: string;
    public idRegion: string;
    public idComuna: string;
    public idTurno: string;
    dataSource = null;
    public regionesModel: Lregiones[] = [new Lregiones(0, "")];
    public comunaModel: Lcomuna[] = [new Lcomuna(0, "")];
    public cursoModel: Lcursos[] = [new Lcursos(0, "prueba", "")];
    public cargoModel: Lcargo[] = [new Lcargo(0, "prueba", "")];
    public turnoModel: Lturnos[] = [new Lturnos(0, "prueba", "")];
    public SexoModel: LSexo[] = [new LSexo(0, "prueba", "")];
    public busquedaPerfil: busquedaPerfil = new busquedaPerfil("", "", "", "", "", "");

    public empleadosModel: consultarempleado[] = [];

    name = "JS PDF EXAMPLE";

    public buscarperfil: perfil[] = [];
    // new perfil('','','','','','','','','','','','','','','','','','','','','','','','','','')];

    displayedColumns: string[] = [
        'idEvaluacion',
        'rutempleado',
        'nombreempleado',
        'cargo',
        'cursos',
        'sexo',
        'educacion',
        'nivelcomputacional',
        'comuna',
        'turno',
        'experiencia',
        'email',
        'telefono'
    ];

    constructor(
        private fb: FormBuilder,
        private http: HttpClient,
        private regionService: LisregionesService,
        private buscarPerfilServices: BuscarempleadoService,
        private comboService: FiltrosService,
        private dialogo: MatDialog,
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
        private nombreTituloService: TitulosService
    ) {
        this.creaFormBuscarPerfil();
    }
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    @ViewChild('htmlData', { static: false }) htmlData: ElementRef;

    ngOnInit(): void {
        this.nombreTituloService.nombreTitulos = "Buscar Empleado";
        this.CargaCombos();
        this.btnEjecutar = "Cancelar";
    }

    CargaCombos() {
        this.obtenerRegiones();
        this.obtenerCargo();
        this.obtenerCursos();
        this.obtenerSexo();
        this.obtenerTurno();
    }

    obtenerRegiones() {
        return this.regionService.getRegiones().subscribe((regionesModel: Lregiones[]) => this.regionesModel = regionesModel);
    }

    obtenerCargo() {
        return this.regionService.getParametros("4").subscribe((cargoModel: Lcargo[]) => this.cargoModel = cargoModel);
    }

    obtenerCursos() {
        return this.regionService.getParametros("5").subscribe((cursoModel: Lcursos[]) => this.cursoModel = cursoModel);
    }

    obtenerSexo() {
        return this.regionService.getParametros("6").subscribe((SexoModel: LSexo[]) => this.SexoModel = SexoModel);
    }

    obtenerTurno() {
        return this.regionService.getParametros("3").subscribe((turnoModel: Lturnos[]) => this.turnoModel = turnoModel);
    }

    onChangeComuna(value) {
        this.idRegion = value;
        // console.log("valor region" + this.idRegion);
        return this.
            regionService.getComunas(value).subscribe((comunaModel: Lcomuna[]) => this.comunaModel = comunaModel);
    }

    creaFormBuscarPerfil() {
        this.formBuscar = this.fb.group({
            idcargo: [''],
            idcurso: [''],
            idsexo: [''],
            idregion: [''],
            idcomuna: [''],
            idturno: [''],
        })
    }

    onSubmit() {

        console.log(this.busquedaPerfil);

        if (this.busquedaPerfil.idcargo == "") {
            this.busquedaPerfil.idcargo = "0";
        }
        if (this.busquedaPerfil.idcurso == "") {
            this.busquedaPerfil.idcurso = "0";
        }
        if (this.busquedaPerfil.idsexo == "") {
            this.busquedaPerfil.idsexo = "0";
        }
        if (this.busquedaPerfil.idregion == "") {
            this.busquedaPerfil.idregion = "0";
        }
        if (this.busquedaPerfil.idcomuna == "") {
            this.busquedaPerfil.idcomuna = "0";
        }

        if (this.busquedaPerfil.idturno == "") {
            this.busquedaPerfil.idturno = "0";
        }

        return this.buscarPerfilServices.getBuscarEmpleado(this.busquedaPerfil).
            subscribe((buscarperfiles: perfil[]) => {
                this.buscarperfil = buscarperfiles;
                this.dataSource = new MatTableDataSource<perfil>(this.buscarperfil);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;

                console.log(this.buscarperfil);
            });
    }

    verDetalleEvaluacion(detallePerfil: perfil) {
        // console.log("valorPerfil:" + detallePerfil);
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        this.dialog.open(DetalleempleadoComponent, {
            height: '600px',
            width: '800px',
            data: {
                rutPerfil: detallePerfil.rut,
                idcargo: detallePerfil.puestoTrabajo
            }
        });
    }

    verEvaluacionEmpleado(detallePerfil: perfil) {
        // console.log("valorPerfil:" + detallePerfil);
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        this.dialog.open(ListaevaluacionesempleadoComponent, {
            height: '800px',
            width: '1200px',
            data: {
                rutPerfil: detallePerfil.rut,
                idcargo: detallePerfil.puestoTrabajo
            }
        });
    }


    verDetalle(detallePerfil: perfil) {
        console.log("Desde mis eval:" + detallePerfil);
        console.log(detallePerfil);

    }



    limpiar() {
        this.formBuscar.reset();
    }

    imprimirLista() {

        const DATA = document.getElementById('htmlData');
        const doc = new jsPDF('p', 'pt', 'a4');
        const options = {
            background: 'white',
            scale: 3,
            fontSize: 15
        };
        html2canvas(DATA, options).then((canvas) => {

            const img = canvas.toDataURL('image/PNG');

            // Add image Canvas to PDF
            const bufferX = 15;
            const bufferY = 15;
            const imgProps = (doc as any).getImageProperties(img);
            const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');

            console.log(imgProps);
            console.log(pdfWidth);
            console.log(pdfHeight);



            return doc;
        }).then((docResult) => {
            docResult.save(`${new Date().toISOString()}_tutorial.pdf`);
        });
    }

}
