import { AuthPostulanteguardGuard } from './../../core/authpostulanteguard.guard';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSortModule } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';


import { AuthguardGuard } from 'src/app/core/authguard.guard';
import { AccesoPostulanteComponent } from './acceso-postulante.component';
import { GuardiasComponent } from '../postulantes/guardias/guardias.component';
import { PostularComponent } from '../postulantes/postular/postular.component';
import { DetalleEmpleoComponent } from './../../publica/detalle-empleo/detalle-empleo.component';
import { PostulaComponent } from './../../publica/postula/postula.component';
import { PostulanteEmpleoModel } from './../../core/publico/postulantes.model';
import { PortalEmpleoComponent } from './../../publica/portal-empleo/portal-empleo.component';
import { EditarPerfilComponent } from '../postulantes/editar-perfil/editar-perfil.component';

const routes: Routes = [
  {
    path: 'accesoPostulante', component: AccesoPostulanteComponent, canActivate: [AuthguardGuard],
    children: [
      { path: 'guardias/:id', component: GuardiasComponent },
      { path: 'postular', component: PostularComponent },
      { path: 'detalleEmpleo/:id', component: DetalleEmpleoComponent },
      { path: 'postularEmpleo', component: PostulaComponent },
      { path: 'portalEmpleo', component: PortalEmpleoComponent },
      { path: 'editarPerfil', component: EditarPerfilComponent },
    ]
  }
];

@NgModule({
  declarations: [
    AccesoPostulanteComponent,
    GuardiasComponent,
    PostularComponent,
    DetalleEmpleoComponent,
    EditarPerfilComponent,
    
  ],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes),
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,

    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    HttpClientModule,
    MatTableModule,
    MatDialogModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatNativeDateModule,
    MatInputModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatGridListModule,
    MatSelectModule,
    MatSortModule
  ]
})
export class AccesoPostulanteModule { 
  PortalEmpleoComponent
  PostulaComponent
}
