<!-- <form (ngSubmit)="onSubmit()" [formGroup]="formConsultaEmp"> -->

    <span class="fill-remaining-space"></span>
    <button mat-icon-button class="close-button" (click)="onClose()">
       <mat-icon class="close-icon" color="warn">close</mat-icon>
    </button>
      
      <div class="container-fluid py-2 mx-auto">
        <div class="card card0 border-1">
          <div class="col-lg-12 col-md-12 col-sm-12 mx-auto mb-4">
            <div class="card-body">
              <div class="wrapper-editor">
                <h2>Listado de Evaluados en el sistema</h2>
                <table mat-table [dataSource]="dataSource" matSort matSortStart="desc" class="mat-elevation-z8"
                  style="width: 100%;">
      
                  <ng-container matColumnDef="ids">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
                    <td mat-cell *matCellDef="let consultarempleado; let i = index;"> {{ (paginatorRef.pageIndex *
                      paginatorRef.pageSize) + (i + 1) }} </td>
                  </ng-container>
      
      
                  <ng-container matColumnDef="idEvaluacion">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>ID Evaluacion</th>
                    <td mat-cell *matCellDef="let consultarempleado"> {{consultarempleado.idEvaluacion}} </td>
                  </ng-container>
      
                  <!-- <ng-container matColumnDef="rutempleado">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Rut Empleado</th>
                    <td mat-cell *matCellDef="let consultarempleado"> {{consultarempleado.rutempleado}} </td>
                  </ng-container> -->
      
      
                  <ng-container matColumnDef="rutempresa">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Rut Empresa</th>
                    <td mat-cell *matCellDef="let consultarempleado"> {{consultarempleado.rutempresa}} </td>
                  </ng-container>
      
                  <ng-container matColumnDef="nombreempresa">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre Empresa</th>
                    <td mat-cell *matCellDef="let consultarempleado"> {{consultarempleado.nombreempresa}} </td>
                  </ng-container>
      
                  <ng-container matColumnDef="nombreempleado">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre Empleado</th>
                    <td mat-cell *matCellDef="let consultarempleado"> {{consultarempleado.nombreempleado}} </td>
                  </ng-container>
      
      
      
      
                  <ng-container matColumnDef="idarticulo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Articulo</th>
                    <td mat-cell *matCellDef="let consultarempleado"> {{consultarempleado.idarticulo}} </td>
                  </ng-container>
      
      
      
                  <ng-container matColumnDef="idinciso">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Inciso</th>
                    <td mat-cell *matCellDef="let consultarempleado"> {{consultarempleado.descripcioninciso}} </td>
                  </ng-container>
      
      
                  <ng-container matColumnDef="fechaingreso">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha Ingreso</th>
                    <td mat-cell *matCellDef="let consultarempleado"> {{consultarempleado.fechaingreso}} </td>
                  </ng-container>
      
                  <ng-container matColumnDef="fechatermino">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha Termino</th>
                    <td mat-cell *matCellDef="let consultarempleado"> {{consultarempleado.fechatermino}} </td>
                  </ng-container>
      
                  <ng-container matColumnDef="observacion">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Observación</th>
                    <td mat-cell *matCellDef="let consultarempleado"> {{consultarempleado.Observacion}} </td>
                  </ng-container>
      
                  <ng-container matColumnDef="Detalle">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Detalle</th>
                    <td mat-cell *matCellDef="let consultarempleado">
                      <button (click)="verDetalle(consultarempleado)" mat-icon-button>
                        {{btnEjecutar}}
                        <mat-icon>launch</mat-icon>
                      </button>
                    </td>
                  </ng-container>
      
      
      
                  <tr mat-header-row *matHeaderRowDef="['ids', 'rutempresa', 'nombreempresa', 'nombreempleado', 'idarticulo','idinciso', 'fechaingreso','fechatermino',
                        'observacion','Detalle']"></tr>
                  <tr mat-row *matRowDef="let fila; columns: ['ids', 'rutempresa', 'nombreempresa', 'nombreempleado', 'idarticulo','idinciso','fechaingreso','fechatermino',
                         'observacion','Detalle'];"></tr>
                </table>
      
                <div *ngIf="empleadosModel.length === 0">NO SE ENCONTRARON REGISTROS</div>
      
                <mat-paginator #paginatorRef [pageSizeOptions]="[5, 10, 15, 20]" showFirstLastButtons></mat-paginator>
      
              </div>
      
                
      
            </div>
          </div>
        </div>
      </div>
      
      
      <!-- </form> -->