import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TitulosService {

  nombreTitulos: string = "Sin Titulos";
  NombreUser: string = "Usuari@";

  constructor() { }
}
