import { EncriptarService } from './../services/seguridad/encriptar.service';
import { Globals } from './../globals';
import { ApiService } from '../services/login/api.service';
import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faUser, faLock, faTrashAlt, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { BoundElementProperty } from '@angular/compiler';

@Component({
  selector: 'app-login-persona',
  templateUrl: './login-persona.component.html',
  styleUrls: ['./login-persona.component.css']
})
export class LoginPersonaComponent implements OnInit {

  faUser = faUser;
  faLock = faLock;


  angForm: FormGroup;
  errorMessage: string = '';
  password_enc: string;

  labelRegistrar: boolean = true;

  public href: string = "";

  constructor(
    private fb: FormBuilder,
    private dataService: ApiService,
    private router: Router,
    public globals: Globals,
    private ruta: ActivatedRoute,
    public encriptar: EncriptarService
  ) {
    this.angForm = this.fb.group({
      email: ['', [Validators.required, Validators.minLength(1), Validators.email]],
      password: ['', Validators.required]
    });
  }

  ngOnInit() {

    this.ruta.queryParams.subscribe(params => {
      console.log(params['returnUrl']);
      let valorEntrada = params['returnUrl'];
      let tag = valorEntrada.indexOf("/");
      var str = valorEntrada.substr(tag + 1, valorEntrada.length);
      var splitted = str.split("/", 5);
      console.log("PRUEBA");
      console.log(splitted[0]);

      if (splitted[0] == "accesoPostulante") {
        console.log("Postulante");
        this.labelRegistrar = false;
      }


    })

    // this.href = this.router.url;
    //     console.log(this.router.url);
  }

  postdata(angForm1) {

    this.dataService.userlogin(angForm1.value.email, angForm1.value.password)
      .pipe(first())
      .subscribe(
        data => {

          console.log(data);

          if (data[0].error) {
            alert(data[0].error)
          } else {

            this.globals.perfil = this.dataService.getPerfil();

            if (this.globals.perfil == "1") {
              const redirect = this.dataService.redirectUrl ? this.dataService.redirectUrl : '/accesoAdmin';
              this.router.navigate([redirect]);
            }

            if (this.globals.perfil == "2") {
              const redirect = this.dataService.redirectUrl ? this.dataService.redirectUrl : '/accesoEmpresa';
              this.router.navigate([redirect]);
            }

            if (this.globals.perfil == "3") {
              const redirect = this.dataService.redirectUrl ? this.dataService.redirectUrl : '/accesoPostulante';
              this.router.navigate([redirect]);
            }
          }
        }
        // ,
        // error => {
        //   console.log(data)
        //   alert("NOMBRE DE USUARIO O CONTRASEÑA INCORRECTOS")
        // }
      )
  }

  get email() { return this.angForm.get('email'); }
  get password() { return this.angForm.get('password'); }
}
