<div class="formPub" *ngIf="mostrarFormEmpleo">
    <form [formGroup]="formRegEmpresa" (ngSubmit)="onSubmit()">
        <section id="guardiaPostula">
            <div class="container py-2 mx-auto">
                <div class="card card0 border-0">
                    <div class="row d-flex">
                        <div class="container">
                            <div class="main-body">
                                <div class="row gutters-sm">
                                    <div class="col-md-4 mt-2 ml-2 mr-1 mb-2">
                                        <div class="form-group card mt-1">
                                            <div class="card-body">
                                                <div class="d-flex flex-column align-items-center text-center">
                                                    <img src="{{imagen}}" alt="Admin" class="rounded-circle"
                                                        width="180" />
                                                    <div class="mt-2">
                                                        <h4>{{titulo}}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group card mt-4 mx-auto">
                                            <div class="card-header">Descripción
                                                <span class="float-right" ngbTooltip="Ayuda">
                                                    <svg width="1em" height="1em" viewBox="0 0 16 16"
                                                        class="bi bi-info-circle" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd"
                                                            d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                        <path
                                                            d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z" />
                                                        <circle cx="8" cy="4.5" r="1" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <p class="text-justify mx-auto" style="width: 90%;">Lorem ipsum dolor sit
                                                amet
                                                consectetur adipisicing elit. Facilis perspiciatis nam quod soluta odit
                                                voluptatibus dicta a ipsam voluptates, eos at iure sunt tempora debitis
                                                dignissimos! Quidem, non. Beatae doloribus aperiam ex at eius obcaecati
                                                soluta voluptas culpa quidem dolor, dolorem sed voluptatum in magnam!
                                                Sequi
                                                autem animi non laudantium.</p>
                                        </div>
                                    </div>
                                    <div class="col-md-7 mt-3 ml-3 mb-2 mr-2">
                                        <div class="card mb-1">
                                            <div class="card-body">
                                                <div class="form-row">
                                                    <div class="form-group col-md-6">
                                                        <mat-form-field appearance="outline"
                                                            class="example-full-width-1">
                                                            <mat-label>Rut Empresa</mat-label>

                                                            <input matInput placeholder="Ingrese Rut Empresa"
                                                                formControlName="rutEmpresa" id="rutEmpresa"
                                                                name="rutEmpresa"
                                                                (blur)="validaRut(perfilempresaModel.rutEmpresa)"
                                                                [(ngModel)]="perfilempresaModel.rutEmpresa" />

                                                        </mat-form-field>
                                                        <p class="rutErrorEmp" id="validacion-live"></p>
                                                    </div>

                                                    <div class="form-group col-md-6">
                                                        <mat-form-field appearance="outline"
                                                            class="example-full-width-1">
                                                            <mat-label>Nombre Empresa</mat-label>
                                                            <input matInput id="nomEmpresa" name="nomEmpresa"
                                                                formControlName="nomEmpresa"
                                                                placeholder="Ingrese Nombre Empresa"
                                                                [(ngModel)]="perfilempresaModel.nomEmpresa">
                                                        </mat-form-field>

                                                    </div>
                                                </div>
                                                <div class="form-row">
                                                    <div class="form-group col-md-6">
                                                        <mat-form-field appearance="outline"
                                                            class="example-full-width-1">
                                                            <mat-label>Rut Representante</mat-label>
                                                            <input matInput id="rutRepresentante"
                                                                name="rutRepresentante"
                                                                placeholder="Ingrese Rut Representante"
                                                                formControlName="rutRepresentante"
                                                                [(ngModel)]="perfilempresaModel.rutRepresentante">
                                                        </mat-form-field>
                                                        <p class="rutErrorRep" id="validacion-live"></p>
                                                    </div>

                                                    <div class="form-group col-md-6">

                                                        <mat-form-field appearance="outline"
                                                            class="example-full-width-1">
                                                            <mat-label>Nombre Representante</mat-label>
                                                            <input matInput id="nomRepresentante"
                                                                name="nomRepresentante"
                                                                placeholder="Ingrese Nombre Representante"
                                                                formControlName="nomRepresentante"
                                                                [(ngModel)]="perfilempresaModel.nomRepresentante">
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="form-row">

                                                    <div class="form-group col-md-6">
                                                        <label for="lbltRut">
                                                            <h6>Ingrese Contraseña</h6>
                                                        </label>
                                                        <input type="password" class="form-control my-1"
                                                            id="passEmpresa" name="Contrasenia"
                                                            formControlName="passEmpresa"
                                                            [(ngModel)]="perfilempresaModel.passEmpresa"
                                                            placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;">
                                                        <div *ngIf="formRegEmpresa.get('passEmpresa').touched && formRegEmpresa.get('passEmpresa').invalid"
                                                            class="alert alert-danger">
                                                            <div
                                                                *ngIf="formRegEmpresa.get('passEmpresa').errors.required">
                                                                Contraseña es requerida.
                                                            </div>

                                                        </div>
                                                        <span class="mdi mdi-eye" id="mostrar"> <span class="pwdtxt"
                                                                style="cursor:pointer;">Mostrar contraseña</span></span>
                                                    </div>


                                                    <div class="form-group col-md-6">
                                                        <label for="lbltRut">
                                                            <h6>Repita Contraseña</h6>
                                                        </label>
                                                        <input type="password" id="RepetirContrasenia"
                                                            name="RepetirContrasenia" class="form-control my-1"
                                                            formControlName="passEmpresa1"
                                                            placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;">
                                                        <div *ngIf="formRegEmpresa.get('passEmpresa1').touched && formRegEmpresa.get('passEmpresa1').invalid"
                                                            class="alert alert-danger">
                                                            <div
                                                                *ngIf="formRegEmpresa.get('passEmpresa1').errors.required">
                                                                Contraseña es requerida.</div>
                                                            <div
                                                                *ngIf="formRegEmpresa.get('passEmpresa1').errors.confirmedValidator">
                                                                Las contraseñas no coinciden. Favor vuelva a ingresarla.
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="form-row">
                                                    <div class="form-group col-md-6">
                                                        <mat-form-field appearance="outline"
                                                            class="example-full-width-1">
                                                            <mat-label>Dirección Empresa</mat-label>
                                                            <input matInput formControlName="dirEmpresa"
                                                                id="direccionEmp" name="dirEmpresa"
                                                                placeholder="Ingrese Dirección"
                                                                [(ngModel)]="perfilempresaModel.dirEmpresa">
                                                        </mat-form-field>
                                                    </div>

                                                    <div class="form-group col-md-6">
                                                        <mat-form-field appearance="outline"
                                                            class="example-full-width-1">
                                                            <mat-label>Telefono Empresa</mat-label>
                                                            <input matInput formControlName="telEmpresa" id="telEmpresa"
                                                                name="telefonoEmp" placeholder="Ingrese Telefono"
                                                                [(ngModel)]="perfilempresaModel.telEmpresa">

                                                            <mat-error *ngIf="checkError('telEmpresa', 'pattern')">Debe
                                                                ingresar solo números</mat-error>
                                                        </mat-form-field>
                                                    </div>

                                                    <div class="form-group col-md-12">

                                                        <mat-form-field appearance="outline"
                                                            class="example-full-width-1">
                                                            <mat-label>Email Empresa</mat-label>
                                                            <input matInput id="email" name="email"
                                                                formControlName="emailEmpresa"
                                                                placeholder="EmailEmpresa@email.com"
                                                                [(ngModel)]="perfilempresaModel.emailEmpresa">
                                                            <!-- <mat-error *ngIf="checkError('emailEmpresa', 'required')">Correo invalido</mat-error> -->
                                                            <mat-error *ngIf="checkError('emailEmpresa', 'pattern')">
                                                                Formato de correo invalido</mat-error>
                                                            <mat-error *ngIf="checkError('emailEmpresa', 'maxLength')">
                                                                Correo debe tener más de 5 caracteres</mat-error>
                                                        </mat-form-field>


                                                    </div>
                                                </div>
                                                <!-- <div class="form-row">
                                                    <div class="alert alert-danger"
                                                        *ngIf="formRegEmpresa.get('emailEmpresa').invalid && (formRegEmpresa.get('emailEmpresa').dirty || formRegEmpresa.get('emailEmpresa').touched)">
                                                        <div class="container-error-message"
                                                            *ngIf="formRegEmpresa.get('emailEmpresa').errors.required">
                                                            Email es requerido
                                                        </div>

                                                        <div class="container-error-message"
                                                            *ngIf="(formRegEmpresa.get('emailEmpresa').dirty || formRegEmpresa.get('emailEmpresa').touched) && formRegEmpresa.get('emailEmpresa').invalid && formRegEmpresa.get('emailEmpresa').errors.pattern">
                                                            Correo invalido
                                                        </div>
                                                    </div>
                                                </div> -->

                                                <div class="form-row">
                                                    <div class="form-group col-md-6 text-center">
                                                        <button type="submit"
                                                            class="btn btn-outline-primary">Ingresar</button>
                                                    </div>
                                                    <div class="form-group col-md-6 text-center">
                                                        <button class="btn btn-outline-primary">Limpiar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </form>
</div>




<!-- <div *ngIf="!mostrarFormEmpleo"> -->

<div [ngStyle]="{'display': muestraTabla }">

    <div class="container-fluid py-2 mx-auto">
        <div class="card card0 border-1">
            <div class="col-lg-11 col-md-11 col-sm-11 mx-auto mb-4">
                <div class="card-body">
                    <div class="wrapper-editor">
                        <h2 class="mb-1">Listado de Empresa</h2>
                        <p class="mb-4 text-sm"></p>
                        <table mat-table [dataSource]="dataSource" matSort matSortStart="desc" class="mat-elevation-z8">

                            <ng-container matColumnDef="index">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
                                <td mat-cell *matCellDef="let listadoEmpresa; let i = index;">{{
                                    (paginatorRef.pageIndex *
                                    paginatorRef.pageSize) + (i + 1) }} </td>
                            </ng-container>

                            <ng-container matColumnDef="idEmpresa">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>ID Empresa</th>
                                <td class="change_on_over" mat-cell *matCellDef="let listadoEmpresa">
                                    {{listadoEmpresa.idEmpresa}} </td>
                            </ng-container>

                            <ng-container matColumnDef="rutEmpresa">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Rut Empresa</th>
                                <td class="change_on_over" mat-cell *matCellDef="let listadoEmpresa">
                                    {{listadoEmpresa.rutEmpresa}} </td>
                            </ng-container>

                            <ng-container matColumnDef="nomEmpresa">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre Empresa</th>
                                <td class="change_on_over" mat-cell *matCellDef="let listadoEmpresa">
                                    {{listadoEmpresa.nomEmpresa}} </td>
                            </ng-container>

                            <ng-container matColumnDef="nomRepresentante">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre Representante</th>
                                <td class="change_on_over" mat-cell *matCellDef="let listadoEmpresa">
                                    {{listadoEmpresa.nomRepresentante}} </td>
                            </ng-container>

                            <ng-container matColumnDef="emailEmpresa">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Email Empresa</th>
                                <td class="change_on_over" mat-cell *matCellDef="let listadoEmpresa">
                                    {{listadoEmpresa.emailEmpresa}} </td>
                            </ng-container>

                            <ng-container matColumnDef="telEmpresa">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Telefono Empresa</th>
                                <td class="change_on_over" mat-cell *matCellDef="let listadoEmpresa">
                                    {{listadoEmpresa.telEmpresa}} </td>
                            </ng-container>

                            <ng-container matColumnDef="Editar">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Editar</th>
                                <td mat-cell *matCellDef="let listadoEmpresa">
                                    <a mat-flat-button color="primary" mat-icon-button
                                        [routerLink]="['/accesoAdmin/EditRegistro', listadoEmpresa.idEmpresa]">
                                        <mat-icon>edit</mat-icon>
                                    </a>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Eliminar">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Eliminar</th>
                                <td mat-cell *matCellDef="let listadoEmpresa">
                                    <button mat-raised-button color="warn"
                                        (click)="deleteEmpresa(listadoEmpresa.idEmpresa)" mat-icon-button>
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </td>
                            </ng-container>

                            <tr mat-header-row
                                *matHeaderRowDef="['idEmpresa','rutEmpresa', 'nomEmpresa','nomRepresentante','emailEmpresa','telEmpresa','Editar','Eliminar']">
                            </tr>
                            <tr mat-row
                                *matRowDef="let fila; columns: ['idEmpresa','rutEmpresa', 'nomEmpresa','nomRepresentante','emailEmpresa','telEmpresa','Editar','Eliminar'];">
                            </tr>
                        </table>
                        <mat-paginator #paginatorRef [pageSizeOptions]="[5, 10, 15, 20]" showFirstLastButtons>
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- </ng-template> -->



<div id="deleteEmpresaModal" class="modal fade">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <form>
                <div class="modal-header">
                    <h4 class="modal-title">Eliminar Empresa N° : {{eliminaEmpresa.idEmpresa}}</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                </div>
                <div class="modal-body">
                    <p>¿Está seguro de que desea eliminar estos registros?</p>
                    <p class="text-warning"><small>Esta acción no se puede deshacer.</small></p>
                </div>
                <div class="modal-footer">

                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                        (click)="deleteEmpresa(eliminaEmpresa.idEmpresa)">Eliminar</button>
                </div>
            </form>
        </div>
    </div>
</div>