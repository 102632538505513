<div class="contenedor-padre">
    <mat-toolbar class="barra" color="warn">
      <!-- <mat-toolbar-row> -->
      <span>
        Gestión Postulante &nbsp; - {{ nombreTitulo.nombreTitulos }}
      </span>
      <!-- </mat-toolbar-row> -->
    </mat-toolbar>
  
    <mat-sidenav-container class="contenido">
      <mat-sidenav style="min-width: 300px;" #cajon mode="side" opened position="start">
        <div class="sidebar-heading mt-3 ml-2">
          <div class="profile-card">
            <img src="https://www.myspacelogic.com/wp-content/uploads/2016/01/avatar-372-456324.png" alt="user" class="profile-photo">
            <h5><a href="#" class="text-white">{{globals.role}}</a></h5>
            <h5><a href="#" class="text-white">{{globals.email}}</a></h5>
          </div>
          <a routerLink="['/accesoPostulante']">  Menu Postulante </a>
  
        </div>
        <mat-nav-list>
          <mat-accordion class="app-nav-accordion">
            <a mat-list-item [routerLink]="['/accesoPostulante/editarPerfil']" >
              <mat-icon>done_all</mat-icon>
              Actualizar Perfil
            </a>

            <a mat-list-item [routerLink]="['/accesoPostulante/portalEmpleo']" >
              <mat-icon>done_all</mat-icon>
              Bolsa Empelo
            </a>

            <a mat-list-item [routerLink]="['/accesoPostulante/postularEmpleo']" >
              <mat-icon>done_all</mat-icon>
              Ingreso de Curriculum
            </a>
  
           </mat-accordion>
        </mat-nav-list>
      </mat-sidenav>
  
      <mat-sidenav-content fullscreen>
        <button (click)="cajon.toggle()" mat-icon-button>
          <mat-icon>menu</mat-icon>
        </button>
        <router-outlet></router-outlet>
  
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>

