import { TitulosService } from './../../services/parametros/titulos.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-postula',
  templateUrl: './postula.component.html',
  styleUrls: ['./postula.component.css']
})
export class PostulaComponent implements OnInit {

  constructor(
    private nombreTituloService: TitulosService
  ) { }

  ngOnInit(): void {
    this.nombreTituloService.nombreTitulos = "Ingreso Curriculum";
  }

}
