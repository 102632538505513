<form [formGroup]="formPostulantes" (ngSubmit)="actualizaPostulantes()">
    <section id="guardiaPostula">
        <div class="container py-2 mx-auto">
            <div class="card card0 border-1">
                <div class="row">
                    <div class="container-fluid">
                        <div class="row mx-3">
                            <div class="col-md-12 mt-3 ml-3 mr-1 mb-2">
                                <div class="card mb-1">
                                    <div class="card-body">
                                        <div class="form-row">
                                            <div class="form-group col-md-4 my-1">
                                                <label for="lbltRut">RUT</label>
                                                <br>
                                                <div class="detalle my-1">
                                                    <label>{{postulanteModel.rut}}</label>
                                                </div>
                                                <p class="rutError" id="validacion-live"></p>

                                            </div>
                                            <div class="form-group col-md-6 my-1">
                                                <label for="lblNombre">Nombre Completo</label>
                                                <br>
                                                <div class="detalle my-1">
                                                    <label>{{postulanteModel.nombreCompleto}}</label>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-4 my-1">
                                                <label for="lblFechaNacimiento">Fecha de Nacimiento</label>
                                                <div class="detalle my-1">
                                                    <label>{{postulanteModel.fechaNacimiento}}</label>
                                                </div>
                                            </div>


                                            <div class="form-group col-md-4 my-1">
                                                <label for="lblSexo">Sexo</label>
                                                <div class="detalle my-1">
                                                    <label>{{postulanteModel.sexo}}</label>
                                                </div>

                                            </div>
                                            <div class="form-group col-md-4 my-1">
                                                <label for="lblCelular">Correo Electronico</label>
                                                <div class="detalle mt-1">
                                                    <label>{{postulanteModel.email}}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />



                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <mat-form-field appearance="outline" class="example-full-width-1">
                                                    <mat-label>Región</mat-label>
                                                    <mat-select name="regiones" id="region" ng-model="regiones"
                                                        (valueChange)="onChangeComuna($event)" formControlName="region"
                                                        [(ngModel)]="postulanteModel.id_region">
                                                        <mat-option value="">Seleccione Región</mat-option>
                                                        <mat-option *ngFor="let regiones of regionesModel"
                                                            value="{{regiones.REGION_ID}}">
                                                            {{regiones.REGION_NOMBRE
                                                            | uppercase}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>

                                            </div>
                                            <div class="form-group col-md-6">
                                                <mat-form-field appearance="outline" class="example-full-width-1">
                                                    <mat-label>Comuna</mat-label>
                                                    <mat-select name="comuna" id="comuna" ng-model="comuna"
                                                        formControlName="comuna"
                                                        [(ngModel)]="postulanteModel.id_comuna">
                                                        <mat-option value="">Seleccione Comuna</mat-option>
                                                        <mat-option *ngFor="let comuna of comunaModel"
                                                            value="{{comuna.COMUNA_ID}}">{{comuna.COMUNA_NOMBRE
                                                            |
                                                            uppercase}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>

                                            </div>
                                        </div>


                                        <div class="form-row">
                                            <div class="form-group col-md-4">
                                                <mat-form-field appearance="outline" class="example-full-width-1">
                                                    <mat-label>Dirección</mat-label>
                                                    <input matInput id="calle" formControlName="calle"
                                                        [(ngModel)]="postulanteModel.calle" />
                                                </mat-form-field>
                                            </div>

                                            <div class="form-group col-md-4">
                                                <mat-form-field appearance="outline" class="example-full-width-1">
                                                    <mat-label>Número</mat-label>
                                                    <input matInput id="numero" formControlName="numero"
                                                        [(ngModel)]="postulanteModel.numero" />
                                                </mat-form-field>
                                            </div>

                                            <div class="form-group col-md-4">
                                                <mat-form-field appearance="outline" class="example-full-width-1">
                                                    <mat-label>Departamento</mat-label>
                                                    <input matInput id="depto" formControlName="depto"
                                                        [(ngModel)]="postulanteModel.depto" />
                                                </mat-form-field>
                                            </div>


                                            <hr />

                                            <div class="form-group col-md-6">
                                                <mat-form-field appearance="outline" class="example-full-width-1">
                                                    <mat-label>Telefono</mat-label>
                                                    <input matInput id="telefono" formControlName="telefono"
                                                        [(ngModel)]="postulanteModel.telefono" />
                                                </mat-form-field>
                                            </div>

                                            <div class="form-group col-md-6">
                                                <mat-form-field appearance="outline" class="example-full-width-1">
                                                    <mat-label>Celular</mat-label>
                                                    <input matInput id="celular" formControlName="celular"
                                                        [(ngModel)]="postulanteModel.celular" />
                                                </mat-form-field>
                                            </div>

                                        </div>

                                        <hr />
                                        <div class="form-row">
                                            <div class="form-group col-md-6 text-center">
                                                <button type="submit" class="btn btn-primary">Editar</button>
                                            </div>
                                            <div class="form-group col-md-6 text-center">
                                                <button class="btn btn-outline-primary" (click)="cerrar()">
                                                    Cerrar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</form>