import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Globals {
  role: string = 'ffffff';
  email: string = '';
  perfil: string;
  rutEmpresa: string;
  nombre: string;

}