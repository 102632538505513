import { TitulosService } from './../../../services/parametros/titulos.service';
import { environment } from './../../../../environments/environment';
import { PublicidadService } from '../../../services/publicidad/publicidad.service';
import { PostPublicidad, ListPublicidad } from '../../../core/admin/empleos';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { faCoffee, faTrash, faTrashAlt, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { RutService } from 'rut-chileno';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogoconfirmacionComponent } from "../../../dialogoconfirmacion/dialogoconfirmacion.component";
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';


import * as $ from 'jquery';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-gestionar-publicidad',
  templateUrl: './gestionar-publicidad.component.html',
  styleUrls: ['./gestionar-publicidad.component.css']
})
export class GestionarPublicidadComponent implements OnInit {

  dataSource = null;
  submitted = false;

  faTrashAlt = faTrashAlt;
  faPencilAlt = faPencilAlt;
  muestraTabla: string;

  minDate = new Date;

  formGestionPublicidad: FormGroup;

  file = new FormControl('');
  file_data: any = ''
  imageSrc: string;
  titulo: string;
  imagenTitulo: string;
  subirArchivo: string = null;
  public nombreArchivo: string;
  mostrarPublicidad: boolean;
  mostrarFormPublicidad: boolean;
  baseUrl = environment.baseUrl + '/Upload/';


  public publicidades: ListPublicidad[] = [new ListPublicidad('', '', '', '', '', '', '', '', '', 0)];
  public publicidadModel: PostPublicidad = new PostPublicidad('', '', '', '', '', '', '', '', '','', 0);

  editarItem: any = {
    idpublicidad: ''
  };

  constructor(
    private http: HttpClient,
    private ruta: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private publicidadService: PublicidadService,
    private rutService: RutService,
    private snackBar: MatSnackBar,
    private dialogo: MatDialog,
    private nombreTituloService: TitulosService,
    private datePipe: DatePipe
  ) {
    this.seteaBloques();
    this.createForm();
  }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  ngOnInit(): void {
    this.minDate.setFullYear(this.minDate.getFullYear());

    this.titulo = "Publicar Anuncio";
    this.imagenTitulo = "https://www.sgtpropiedades.cl/wp-content/uploads/2018/09/publicagratis.jpg";



    $(document).ready(function () {
      $("input#rutEmpresa").rut({ validateOn: 'blur' }).on('rutInvalido', function () {
        $(".rutErrorEmp").addClass("alert alert-danger")
        $(".rutErrorEmp").text("Rut inválido");
        $('input#rutEmpresa').val('');

      }).on('rutValido', function () {
        $(".rutErrorEmp").removeClass("alert alert-danger ");
        $(".rutErrorEmp").empty();
      });
    })

  }

  private emailPattern: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


  createForm() {
    this.formGestionPublicidad = this.fb.group({
      rutEmpresa: ['', Validators.required],
      NombreEmpresa: ['', Validators.required],
      nomContacto: ['', Validators.required],
      telefonoContacto: ['', Validators.required],
      fecIniPub: ['', Validators.required],
      fecTerPub: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern), Validators.minLength(7)]],
      url: ['', [Validators.required, Validators.minLength(15)]],
      subirArchivo: ['', Validators.required],
      imagenUrl: ['', Validators.required],
      descPublicidad: ['', Validators.required]
    })
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.formGestionPublicidad.controls[controlName].hasError(errorName);
  }


  uploadFile() {

    console.log(this.file_data);
    this.http.post(this.baseUrl + 'upload.php', this.file_data, {
      reportProgress: true,
      observe: 'events'
    })
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          console.log("Progreso :" + (event.loaded / event.total) * 100 + "%");

        }
        else if (event.type === HttpEventType.Response) {
          console.log(event);
          console.log("With Parsed JSON :", event.body);
          console.log(event.body['message']);

          this.nombreArchivo = event.body['archivo'];
          this.publicidadModel.imagenUrl = event.body['archivo'];

          if (event.body['status'] == 0) {
            this.imageSrc = '';
            this.subirArchivo = '';
          }
        }
      });
  }

  get f() { return this.formGestionPublicidad.controls; }


  onSubmit() {
    console.log(this.publicidadModel);

    this.submitted = true;

    if (this.formGestionPublicidad.valid) {
      console.log(this.formGestionPublicidad.value);

      this.publicidadModel.rutEmpresa = String(this.rutService.getRutChile(2, this.publicidadModel.rutEmpresa));
      this.publicidadModel.fecIniPub =  this.datePipe.transform(this.publicidadModel.fecIniPub,"yyyy-MM-dd");
      this.publicidadModel.fecTerPub =  this.datePipe.transform(this.publicidadModel.fecTerPub,"yyyy-MM-dd");

      this.publicidadService.addEmpleo(this.publicidadModel).subscribe(() => {
        this.router.navigate(['/accesoAdmin/GestionarPublicidad/2']);
      })
    } else {
      this.dialogo.open(DialogoconfirmacionComponent, {
        data: `Por favor complete todos los campos`,
      });
    }
  }


  obtenerPublicidad() {
    return this.publicidadService.getPublicidad().subscribe((publicidades: ListPublicidad[]) => {
      this.publicidades = publicidades
      this.dataSource = new MatTableDataSource<ListPublicidad>(this.publicidades);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    });
  }

  eliminarPublicidad(Item) {
    this.dialogo
      .open(DialogoconfirmacionComponent, {
        data: `¿Realmente quieres eliminar la Publicidad N° ` + Item + `?`
      })
      .afterClosed()
      .subscribe((confirmado: Boolean) => {
        if (!confirmado) return;
        this.publicidadService
          .deletePublicidad(Item)
          .subscribe(() => {
            this.snackBar.open('Publicidad eliminada ', Item, {
              duration: 3000,
            });
            this.obtenerPublicidad();
          });
      });
  }

  editarPublicidad(editPubli) {
    this.editarItem = editPubli;
    // console.log(this.editarItem);
  }

  seteaBloques() {
    this.ruta.params.subscribe(params => {
      switch (params['id']) {
        case '1': {
          this.nombreTituloService.nombreTitulos = "Gestionar Publicidad";
          this.mostrarPublicidad = false;
          this.mostrarFormPublicidad = true;
          this.muestraTabla = 'none';
          break;
        }
        case '2': {
          this.nombreTituloService.nombreTitulos = "Listar Publicidad";
          this.mostrarPublicidad = true;
          this.mostrarFormPublicidad = false;
          this.obtenerPublicidad();
          this.muestraTabla = 'block';
          break;
        }
        default: {
          this.router.navigate(['/accesoAdmin']);
          break;
        }
      }
    })
  }

  fileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.imageSrc = reader.result as string;
      }
    }

    const fileList: FileList = event.target.files;
    //check whether file is selected or not
    if (fileList.length > 0) {

      const file = fileList[0];
      //get file information such as name, size and type
      console.log('finfo', file.name, file.size, file.type);
      //max file size is 4 mb
      if ((file.size / 1048576) <= 4) {
        let formData = new FormData();
        let info = { id: 2, name: 'raja' };
        formData.append('file', file, file.name);
        formData.append('id', '2');
        formData.append('tz', new Date().toISOString())
        formData.append('update', '2')
        formData.append('info', JSON.stringify(info))
        this.file_data = formData;

      } else {
        //this.snackBar.open('File size exceeds 4 MB. Please choose less than 4 MB','',{duration: 2000});
      }

    }
  }
}
