import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { faCoffee, faTrash, faTrashAlt, faPencilAlt, faTh, faCalendar, faCalendarAlt, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

//Material
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

import { MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
//Servicios
import { consultarempleado } from '../../../core/empresa/consultarempleado';
import { ConsultarempleadoService } from '../../../services/empresa/consultarempleado/consultarempleado.service';
import { DetallemisevaluacionesComponent } from '../../empresa/detallemisevaluaciones/detallemisevaluaciones.component';
import { ComboempresaService } from '../../../services/parametros/comboempresa.service';
//Modelo
import { Lparametros } from '../../../core/parametros/parametros';
import { Larticulos, Linciso, Lcausal } from './../../../core/empresa/comboempresa.model';
import { TitulosService } from './../../../services/parametros/titulos.service';

import * as $ from 'jquery';
@Component({
  selector: 'app-autorizar-funa',
  templateUrl: './autorizar-funa.component.html',
  styleUrls: ['./autorizar-funa.component.css']
})
export class AutorizarFunaComponent implements OnInit {
  dataSource = null;
  public disableTextbox;
  public rutConsultado;
  public showSpinner = false;
  public nombreEtapa = "No Autorizado";
  grupoParametros: number;

  public parametroEstado: Lparametros[] = [new Lparametros(0, '', 0)];
  public empleados: consultarempleado[] = [
    new consultarempleado("11111", "pruea", "11111", "empresa x", "2021-02-02", "2021-02-02", "", "articulo", "", "inciso", "observacion", "si", "si", "")
  ];
  public articulomodel: Larticulos[] = [new Larticulos(0, "prueba", "aaiiiii articulo")];
  public incisomodel: Linciso[] = [new Linciso(0, "Debe Seleccione Articulo", "", 0)];



  faTrashAlt = faTrashAlt;
  faPencilAlt = faPencilAlt;


  constructor(
    private ruta: ActivatedRoute,
    private router: Router,
    private servicioConsulta: ConsultarempleadoService,
    private dialogo: MatDialog,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private FiltrosEvaluados: ComboempresaService,
    private articuloService: ComboempresaService,
    private nombreTituloService: TitulosService

  ) { }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit(): void {
    this.nombreTituloService.nombreTitulos = "Autorizar Evaluación";
    this.CargaInicial();
  }

  CargaInicial() {
    //Corresponde al combo que lista(autorizado, por autorizar y todos)
    this.ListarFiltro();
    //Carga Grilla Inicial
    this.CargaGrillaInicial();
    //Carga valores y eventos
    this.ListarArticulos();
  }
  //Carga la grilla al iniciar la pagina, por defecto
  //Trae todas las evaluaciones sin autorizar
  CargaGrillaInicial() {
    //1 Indica no autorizadas
    this.grupoParametros = 1;
    return this.servicioConsulta.getListarEvaluadosPorEstado(this.grupoParametros).
      subscribe((empleados: consultarempleado[]) => {
        this.empleados = empleados;
        this.dataSource = new MatTableDataSource<consultarempleado>(this.empleados);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }

  //Obtiene los valores utilizados para filtrar, que se muestran el combo
  ListarFiltro() {
    this.grupoParametros = 11;
    return this.FiltrosEvaluados.getListarCombo(this.grupoParametros).subscribe((parametroEstado: Lparametros[]) => this.parametroEstado = parametroEstado);
  }

  //Obtiene la lista de articulo
  ListarArticulos() {
    return this.articuloService.getListaArticulos().subscribe((articulomodel: Larticulos[]) => this.articulomodel = articulomodel);
  }
  //Obtiene la lista de Incisos
  onChangeinciso(value) {
    return this.articuloService.getListaIncisos(value).subscribe((incisomodel: Linciso[]) => this.incisomodel = incisomodel);
  }

  //Este evento se ejecuta, al ver detalle o al autorizar.
  //Se muestra en popup
  AutorizarEvaluacion(empleado: consultarempleado) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.dialog.open(DetallemisevaluacionesComponent, {
      height: '700px',
      width: '900px',
      disableClose: true,
      data: {
        idEvaluacion: empleado.idEvaluacion,
        dataorigen: "autorizarfuna",
        estado: empleado.estado,
        observacion: empleado.Observacion
      }
    });
  }



  //Se ejecuta, cuando se cambia un valor del combo de filtros
  // y trae las evaluaciones autorizadas o no autorizadas.
  onChangeFiltro(value) {

    if (value == 1) {
      this.nombreEtapa = "No Autorizado";
    } else {
      this.nombreEtapa = "Autorizado";
    }


    console.log(value);
    return this.servicioConsulta.getListarEvaluadosPorEstado(value).
      subscribe((empleados: consultarempleado[]) => {
        this.empleados = empleados;
        this.dataSource = new MatTableDataSource<consultarempleado>(this.empleados);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
        error => {
          let snackBarRef = this.snackBar.open('OCURRIO UN ERROR EN LA CONEXIÓN', undefined, {
            duration: 2000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center',
            panelClass: ["custom-style"]
          });
        });
  }



  obtenerConsultado() {
    console.log("Ejecuto" + this.rutConsultado);
    return this.servicioConsulta.ConsultarEmpleado(this.rutConsultado).
      subscribe((empleados: consultarempleado[]) => this.empleados = empleados);
  }

  obtenerNoAutorizados() {
  }

  obtenerAutorizados() {
  }

}