// CCURIN
import { TitulosService } from './../../../services/parametros/titulos.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { PostulantesService } from './../../../services/admin/postulantes/postulantes.service';
import { ApiService } from './../../../services/login/api.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { PostulantesModel, PostulantesLaboralModel, ValidaRutPuestoModel } from './../../../core/publico/postulantes.model';
import { Lregiones, Lcomuna } from '../../../core/parametros/regiones.model';
import { LisregionesService } from './../../../services/parametros/lisregiones.service';


@Component({
  selector: 'app-editar-perfil',
  templateUrl: './editar-perfil.component.html',
  styleUrls: ['./editar-perfil.component.css']
})
export class EditarPerfilComponent implements OnInit {

  submitted = false;
  public regionesModel: Lregiones[] = [new Lregiones(0, "prueba")];
  public comunaModel: Lcomuna[] = [new Lcomuna(0, '')];

  public postulanteModel: PostulantesModel = new PostulantesModel('', '', '', '', '', '', '', '', '', '', '', '', '',
    '', '', '', '', '', '', '', '', '', '', '', '');

  formPostulantes: FormGroup


  constructor(
    public apiService: ApiService,
    public postulanteService: PostulantesService,
    private router: Router,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private regionService: LisregionesService,
    private nombreTituloService: TitulosService 

  ) { }

  ngOnInit(): void {
    this.nombreTituloService.nombreTitulos = "Actualizar Perfil";
    this.obtienePostulante();
    this.creaFormPostulantes();
    this.obtenerRegiones();
  }

  creaFormPostulantes() {
    this.formPostulantes = this.fb.group({
      // rut: ['', [Validators.required]],
      calle: ['', [Validators.required]],
      numero: ['', [Validators.required]],
      depto: [''],
      region: ['', Validators.required],
      comuna: ['', Validators.required],
      telefono: ['', [Validators.required]],
      celular: ['', [Validators.required]]

    }
    );
  }

  obtenerRegiones() {
    return this.regionService.getRegiones().subscribe((regionesModel: Lregiones[]) => this.regionesModel = regionesModel);
  }

  onChangeComuna(value) {
    return this.regionService.getComunas(value).subscribe((comunaModel: Lcomuna[]) => this.comunaModel = comunaModel);
  }


  actualizaPostulantes() {
    console.log(this.postulanteModel);
    console.log(this.formPostulantes);

    this.submitted = true;

    this.postulanteModel.rut = this.apiService.getRutEmpresa();

    if (this.formPostulantes.valid) {
      this.postulanteService.updatePostulantes(this.postulanteModel).subscribe(() => {

        // this.disableButton = true;
        let snackBarRef = this.snackBar.open('Postulante actualizado correctamente', undefined, {
          duration: 3000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center',
          panelClass: ["custom-style"]
        });

        snackBarRef.afterDismissed().subscribe(() => {
          this.router.navigate(['/accesoPostulante']);
          this.formPostulantes.reset();
        });
      })
    } else {
      alert("FAVOR COMPLETAR TODOS LOS CAMPOS ")
    }

  }



  obtienePostulante() {
    console.log(this.apiService.getRutEmpresa());

    this.postulanteService.getOnePostulantes(this.apiService.getRutEmpresa())
      .subscribe((postulanteModel: PostulantesModel) => {
        this.postulanteModel = postulanteModel

        this.onChangeComuna(this.postulanteModel.id_region);
        console.log(this.postulanteModel);
      })
  }




  cerrar() {
    this.router.navigate(['/accesoPostulante']);
  }
}
