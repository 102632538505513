export class consultarempleado {

    constructor(

        public idEvaluacion: string,
        public rutempleado: string,
        public nombreempleado: string,
        public rutempresa: string,
        public nombreempresa: string,
        public fechaingreso: string,
        public fechatermino: string,
        public idarticulo: string,
        public nombrearticulo: string,
        public nombreincisocorto: string,
        public Observacion: string,
        public autorizacion: string,
        public recomienda: string,
        public estado: string,

    ) {
    }
}


export class consultarRutEmpleado {

    constructor(
        public rutConsultado: string
    ) {
    }
}