<!-- <section  id="home">
  <div class='container mt-5 col-md-10 col-11'>
    <div class="hd">Nuestros Servicios</div>
    <p>Somos una empresa <br /></p>
   
 
    <div class="row" style="justify-content: center">
        <div class="card col-md-3 col-11">
            <div class="card-content">
                <div class="card-body p-0">
                    <div class="profile mb-4 mt-3">  <img src="../../assets/img/Trabajo.png"  alt="Trabajo" /> </div>
                    <div class="card-subtitle">
                        <p><fa-icon [icon]="faquoteleft"></fa-icon> Portal laboral <fa-icon [icon]="faquoteright"></fa-icon></p>
                    </div>
 
                </div>
            </div>
        </div>
        <div class="card col-md-3 col-11 second">
            <div class="card-content">
                <div class="card-body p-0">
                    <div class="profile mb-4 mt-3">
                      <img src="../../assets/img/limpieza.jpg"  alt="Personal" /> </div>
                    <div class="card-subtitle">
                        <p>  <fa-icon [icon]="faquoteleft"></fa-icon> Contamos, con el personal mejor calificado <fa-icon [icon]="faquoteright"></fa-icon> </p>
                    </div>

                </div>
            </div>
        </div>
        <div class="card col-md-3 col-11 third">
            <div class="card-content">
                <div class="card-body p-0">
                  <div class="profile mb-4 mt-3">
                    <img src="../../assets/img/Informacio.jpg"  alt="informacion" /> </div>
                    <div class="card-subtitle">
                        <p> <fa-icon [icon]="faquoteleft"></fa-icon> Contamos, con el historial de cada profesional <fa-icon [icon]="faquoteright"></fa-icon></p>
                    </div>
   
                </div>
            </div>
        </div>
    </div>
</div>
</section>
-->

<!--Carousel Wrapper-->
<div id="carousel-example-2" class="carousel slide carousel-fade" data-ride="carousel">
    <!--Indicators-->
    <ol class="carousel-indicators">
      <li data-target="#carousel-example-2" data-slide-to="0" class="active"></li>
      <li data-target="#carousel-example-2" data-slide-to="1"></li>
      <li data-target="#carousel-example-2" data-slide-to="2"></li>
    </ol>
    <!--/.Indicators-->
    <!--Slides-->
    <div class="carousel-inner" role="listbox">
      <div class="carousel-item active">
        <div class="view">
          <img class="d-block w-100" src="https://mdbootstrap.com/img/Photos/Slides/img%20(68).jpg"
            alt="First slide">
          <div class="mask rgba-black-light"></div>
        </div>
        <div class="carousel-caption">
          <h3 class="h3-responsive">Light mask</h3>
          <p>First text</p>
        </div>
      </div>
      <div class="carousel-item">
        <!--Mask color-->
        <div class="view">
          <img class="d-block w-100" src="https://mdbootstrap.com/img/Photos/Slides/img%20(6).jpg"
            alt="Second slide">
          <div class="mask rgba-black-strong"></div>
        </div>
        <div class="carousel-caption">
          <h3 class="h3-responsive">Strong mask</h3>
          <p>Secondary text</p>
        </div>
      </div>
      <div class="carousel-item">
        <!--Mask color-->
        <div class="view">
          <img class="d-block w-100" src="https://mdbootstrap.com/img/Photos/Slides/img%20(9).jpg"
            alt="Third slide">
          <div class="mask rgba-black-slight"></div>
        </div>
        <div class="carousel-caption">
          <h3 class="h3-responsive">Slight mask</h3>
          <p>Third text</p>
        </div>
      </div>
    </div> -->
    <!--/.Slides-->
    <!--Controls-->
    <a class="carousel-control-prev" href="#carousel-example-2" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carousel-example-2" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
    <!--/.Controls-->
  </div>
  <!--/.Carousel Wrapper-->