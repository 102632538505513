<div class="contenedor-padre">
  <mat-toolbar class="barra" color="warn">
    <!-- <mat-toolbar-row> -->
    <span>
      Gestión Empresa&nbsp; - {{ nombreTitulo.nombreTitulos }}
    </span>
    <!-- </mat-toolbar-row> -->
  </mat-toolbar>

  <mat-sidenav-container class="contenido">
    <mat-sidenav style="min-width: 300px;" #cajon mode="side" opened position="start">
      <div class="sidebar-heading ml-2 mt-3">
        <div class="profile-card">
          <img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="user" class="profile-photo">
          <h5><a href="#" class="text-white">{{globals.role}}</a></h5>
          <h5><a href="#" class="text-white">{{globals.email}}</a></h5>
        </div>
        <a routerLink="['/accesoEmpresa']"> Menu Empresa </a>

      </div>
      <mat-nav-list>
        <mat-accordion class="app-nav-accordion">

          <a mat-list-item routerLink="/accesoEmpresa/ReferirEmpleado/0">
            <mat-icon>group_add</mat-icon>
            Agregar Evaluación Empresa
          </a>

            <mat-nav-list>
              <a mat-list-item routerLink="/accesoEmpresa/ConsultarEmpleado">
                <mat-icon>view_list</mat-icon> Consultar Empleado
              </a>
              <a mat-list-item routerLink="/accesoEmpresa/MisEvaluaciones/0">
                <mat-icon>view_list</mat-icon> Mis Evaluaciones
              </a>
              <a mat-list-item routerLink="/accesoEmpresa/BuscarEmpleado">
                <mat-icon>search icon</mat-icon>
                BuscarEmpleado Nuevo Empleado
              </a>
            </mat-nav-list>

            <mat-expansion-panel [class.mat-elevation-z0]="true">
              <mat-expansion-panel-header>
                Gestión Empleo
              </mat-expansion-panel-header>
              <mat-nav-list>
                <a mat-list-item routerLink="/accesoEmpresa/GestionarEmpleo/1">
                  <mat-icon>people_outline</mat-icon> Ingresar Empleo
                </a>
                <a mat-list-item routerLink="/accesoEmpresa/GestionarEmpleo/2">
                  <mat-icon>view_list</mat-icon> Listar Empleo
                </a>
              </mat-nav-list>
            </mat-expansion-panel>

        </mat-accordion>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content fullscreen>
      <button (click)="cajon.toggle()" mat-icon-button>
        <mat-icon>menu</mat-icon>
      </button>
      <router-outlet></router-outlet>

    </mat-sidenav-content>
  </mat-sidenav-container>
</div>



<!-- 
<div class="contenedor-padre">
  <mat-toolbar class="barra" color="warn">
    <mat-toolbar-row>
      <button (click)="cajon.toggle()" mat-icon-button>
        <mat-icon>menu</mat-icon>
      </button>


      <span>
        Gestión Empresas&nbsp;
      </span>

    </mat-toolbar-row>
  </mat-toolbar>
  <mat-sidenav-container class="contenido">
    <mat-sidenav style="min-width: 300px;" #cajon mode="side">
      <mat-nav-list>

        <a mat-list-item routerLink="/accesoEmpresa/ReferirEmpleado/0">

          <mat-icon>group_add</mat-icon>
          Agregar Evaluación Empresa
        </a>

        <a mat-list-item routerLink="/accesoEmpresa/ConsultarEmpleado">
          <mat-icon>view_list</mat-icon> Consultar Empleado
        </a>



        <a mat-list-item routerLink="/accesoEmpresa/MisEvaluaciones/0">
          <mat-icon>view_list</mat-icon> Mis Evaluaciones
        </a>
        <a mat-list-item routerLink="/accesoEmpresa/BuscarEmpleado">
          <mat-icon>search icon</mat-icon>
          BuscarEmpleado Nuevo Empleado
        </a>
      </mat-nav-list>

    </mat-sidenav>
    <mat-sidenav-content class="padding-10">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div> -->