import { ApiService } from './../services/login/api.service';
import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faUser, faLock, faTrashAlt, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-recuperar-password',
  templateUrl: './recuperar-password.component.html',
  styleUrls: ['./recuperar-password.component.css']
})
export class RecuperarPasswordComponent implements OnInit {

  faUser = faUser;
  faLock = faLock;

  angForm: FormGroup;
  labelRegistrar: boolean = false;

  private emailPattern: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private dataService: ApiService
  ) {

    this.angForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(this.emailPattern), Validators.maxLength(100)]],
    });
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.angForm.controls[controlName].hasError(errorName);
  }

  ngOnInit(): void {
    this.labelRegistrar = false;
  }

  postdata(angForm1) {

    if (this.angForm.valid) {

      // alert(angForm1.value.email);

      this.dataService.recoveryPassword(angForm1.value.email)
      .pipe(first())
      .subscribe(
        data => {

          console.log(data);

          // 'Hemos enviado un correo electronico a la direccion '+ angForm1.value.email +' para restablecer tu password.'

          if (data[0].error) {
            let snackBarRef = this.snackBar.open(data[0].error, undefined, {
              duration: 4000,
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
              panelClass: ["custom-style"]
            });
          } 
        }
      )


    }
  }


}
