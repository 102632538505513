import { MatSnackBar } from '@angular/material/snack-bar';
import { PostulantesModel, PostulantesLaboralModel, ValidaRutPuestoModel } from './../../../core/publico/postulantes.model';
import { PostulantesService } from './../../../services/admin/postulantes/postulantes.service';
import { ApiService } from '../../../services/login/api.service';
import { Router } from '@angular/router';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import { FaConfig, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faBellSlash, faHandPaper, faUser } from '@fortawesome/free-regular-svg-icons';
import { faCoffee, faTrash, faTrashAlt, faPencilAlt, faTh, faCalendar, faCalendarAlt, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Lparametros } from '../../../core/parametros/parametros';
import { Lregiones, Lcomuna } from '../../../core/parametros/regiones.model';
import { LisregionesService } from '../../../services/parametros/lisregiones.service';
import { DialogoconfirmacionComponent } from "../../../dialogoconfirmacion/dialogoconfirmacion.component";
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { debounceTime } from 'rxjs/operators';

import * as $ from 'jquery';

export const validarQueSeanIguales: ValidatorFn = (
  control: FormGroup
): ValidationErrors | null => {
  const rut = control.get('rut');

  return rut.value.rut()
    ? null
    : { noSonIguales: true };
};

declare var $: any;
declare var jQuery: any;


@Component({
  selector: 'app-guardias',
  templateUrl: './guardias.component.html',
  styleUrls: ['./guardias.component.css'],
  providers: [FaConfig],
})
export class GuardiasComponent implements OnInit {


  date = new Date();
  chosenYearDate: Date;
  chosenMonthDate: Date = new Date(2020, 0, 1);
  chosenSemesterDate: Date;
  chosenWeekDate: Date;
  chosenDate: Date;
  monthInputCtrl: FormControl = new FormControl(new Date(2020, 0, 1));

  faTrashAlt = faTrashAlt;
  faPencilAlt = faPencilAlt;
  faTrash = faTrash;
  faCofee = faCoffee;
  faTh = faTh;
  faCalendar = faCalendar;
  faCalendarAlt = faCalendarAlt;

  titulo: string;
  imagen: string;
  curso: string;
  grupoParametros: number;
  habilitarFecha: boolean;
  ocultarFecha: boolean;

  public disableTextbox;

  formPostulantes: FormGroup;
  postulantes: any = {};

  public postulanteModel: PostulantesModel = new PostulantesModel('', '', '', '', '', '', '', '', '', '', '', '', '',
    '', '', '', '', '', '', '', '', '', '', '', '');
  public postulanteLaboral: PostulantesLaboralModel = new PostulantesLaboralModel('', '', '', '', '', '', '', '');
  public validaRutPuesto: ValidaRutPuestoModel = new ValidaRutPuestoModel('', '');

  public regionesModel: Lregiones[] = [new Lregiones(0, 'prueba')];
  public comunaModel: Lcomuna[] = [new Lcomuna(0, '')];
  public parametroModel: Lparametros[] = [new Lparametros(0, '', 0)];
  public parametroTipoComputacion: Lparametros[] = [new Lparametros(0, '', 0)];
  public parametroTurnos: Lparametros[] = [new Lparametros(0, '', 0)];

  color = 'accent';
  checked = false;

  constructor(
    faConfig: FaConfig,
    library: FaIconLibrary,
    private http: HttpClient,
    private ruta: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    public apiService: ApiService,
    public postulanteService: PostulantesService,
    private regionService: LisregionesService,
    private dialogo: MatDialog,
    private snackBar: MatSnackBar
  ) {
    faConfig.defaultPrefix = 'far';
    library.addIcons(faUser, faHandPaper, faBellSlash);

    this.seteaPostulante();
    this.creaFormPostulantes();
  }

  ngOnInit(): void {


    this.obtenerEduacacion();
    this.obtenerTipoComputacion();
    this.obtenerTurnos();
    this.obtienePostulante();

    $(document).ready(function () {
      $("input#rut").rut({ formatOn: 'keyup', validateOn: 'keyup' }).on('rutInvalido', function () {
        $(".rutError").addClass("alert alert-danger")
        $(".rutError").text("Rut inválido");
      }).on('rutValido', function () {
        $(".rutError").removeClass("alert alert-danger ")
        $(".rutError").empty();
      });
    })


    this.ruta.params.subscribe(params => {
      this.validaRutPuesto.puestotrabajo = params['id']
    })
    this.validaRutPuesto.rut = this.apiService.getRutEmpresa();

    this.postulanteService.validaPuestoPostula(this.validaRutPuesto).subscribe(event => {
      if (event.type === HttpEventType.Response) {
        console.log("With Parsed JSON :", event.body);
        if (event.body['existeRut'] > 0) {
          alert("YA ESTA INGRESADO")
          this.router.navigate(['/postula/']);
        }

        if (event.body['resultado'] === false) {
          alert("ERROR INGRESO");
        }
      }
    })
  }


  obtienePostulante() {
    console.log(this.apiService.getRutEmpresa());

    this.postulanteService.getOnePostulantes(this.apiService.getRutEmpresa())
      .subscribe((postulanteModel: PostulantesModel) => {
        this.postulanteModel = postulanteModel
        console.log(this.postulanteModel);
      })
  }

  seteaPostulante() {
    this.ruta.params.subscribe(params => {
      console.log(params['id']);

      switch (params['id']) {
        case '1': {
          this.titulo = "Guardia de Seguridad";
          this.imagen = "http://www.diarioeldia.cl/sites/default/files/styles/flexslider_full/public/092019/ayer_a_nivel_nacional.jpg?itok=3eUNFSuZ";
          this.curso = "Cursos Acreditación OS-10";
          break;
        }
        case '2': {
          this.titulo = "Supervisor de Seguridad";
          this.imagen = "https://curso-os10.cl/wp-content/uploads/2019/01/supervisor-de-seguridad.png";
          this.curso = "Posee Acreditación?";
          this.habilitarFecha = true;
          this.postulanteLaboral.fechaAcreditacion = "01/01/1901";
          break;
        }
        case '3': {
          this.titulo = "Conserje";
          this.imagen = "https://www.infogate.cl/wp-content/uploads/2019/04/empresas090401.jpg";
          this.curso = "Cursos Acreditación OS-10";
          break;
        }
        case '4': {
          this.titulo = "Personal de Aseo";
          this.imagen = "https://www.infogate.cl/wp-content/uploads/2019/04/empresas090401.jpg";
          this.curso = "Aseo";
          this.ocultarFecha = true;
          break;
        }
        default: {
          this.router.navigate(['/inicio']);
          this.titulo = "Guardia de Seguridad";
          break;
        }
      }
    })
  }


  obtenerEduacacion() {
    this.grupoParametros = 1;
    return this.regionService.getParametros(this.grupoParametros).subscribe((parametroModel: Lparametros[]) => this.parametroModel = parametroModel);
  }

  obtenerTipoComputacion() {
    this.grupoParametros = 10;
    return this.regionService.getParametros(this.grupoParametros).subscribe((parametroTipoComputacion: Lparametros[]) => this.parametroTipoComputacion = parametroTipoComputacion);
  }

  obtenerTurnos() {
    this.grupoParametros = 3;
    return this.regionService.getParametros(this.grupoParametros).subscribe((parametroTurnos: Lparametros[]) => this.parametroTurnos = parametroTurnos);
  }

  creaFormPostulantes() {
    this.formPostulantes = this.fb.group({
      educacion: ['', [Validators.required]],
      cursoAcreditacion: ['', [Validators.required]],
      experiencia: ['', [Validators.required]],
      fechaAcreditacion: ['', null],
      nivelComputacion: ['', [Validators.required]],
      turnos: ['', [Validators.required]]
      // locacion: ['', [Validators.required]]
      // rut: ['', [Validators.required]],
      // nombre: ['', [Validators.required]],
      // sexo: ['', [Validators.required]],
      // fechaNacimiento: ['', [Validators.required]],
      // direccion: ['', [Validators.required]],
      // comuna: ['', [Validators.required]],
      // telefono: ['', [Validators.required]],
      // celular: ['', [Validators.required]],
      // email: ['', [Validators.required, Validators.email]]
    }
    );

    // this.formPostulantes.valueChanges
    //   .pipe(
    //     debounceTime(500)
    //   )
    //   .subscribe(value => {onSubmit
    //     console.log(value);
    //   });
  }

  agregaPostulantes() {
    console.log(this.formPostulantes);
    this.postulanteLaboral.rut = this.apiService.getRutEmpresa()

    this.ruta.params.subscribe(params => {
      console.log(params['id']);
      this.postulanteLaboral.puestotrabajo = params['id'];

      if (this.postulanteLaboral.cursoAcreditacion == "SI") {
        this.postulanteLaboral.cursoAcreditacion = params['id'];
      } else {
        this.postulanteLaboral.cursoAcreditacion = "0";
      }

      if (params['id'] == 2) {
        this.postulanteLaboral.fechaAcreditacion = '01/01/1901';
      }

      if (params['id'] == 4) {
        this.setValidatorRequired('cursoAcreditacion', null);
        this.postulanteLaboral.cursoAcreditacion == 'NO';
      }
    })



    if (this.formPostulantes.valid) {
      console.log(this.postulanteLaboral);
      this.postulanteService.addPostLaboral(this.postulanteLaboral).subscribe(event => {

        if (event.type === HttpEventType.Response) {
          console.log("With Parsed JSONs :", event.body);


          let snackBarRef = this.snackBar.open('Curriculum enviado correctamente', undefined, {
            duration: 6000,
            verticalPosition: 'top',
            horizontalPosition: 'right',
            panelClass: ["custom-style"]
          });

          this.router.navigate(['/accesoPostulante/postularEmpleo']);

          // setTimeout(() => {

          // }, 4000);  //5s


          if (event.body['resultado'] === false) {
            alert("ERROR INGRESO");
          }
        }
      })
    }
    else {
      this.dialogo.open(DialogoconfirmacionComponent, {
        data: `Por favor complete todos los campos`,
      });
    }
  }



  changeGender(e) {
    console.log(e.target.value);

    if (e.target.value == "NO") {
      this.disableTextbox = true;
      this.postulanteLaboral.fechaAcreditacion = "";
    } else {

      if (this.validaRutPuesto.puestotrabajo == "2") {
        this.postulanteLaboral.fechaAcreditacion = "";
      } else {

        this.disableTextbox = false;
        this.setValidatorRequired('fechaAcreditacion', Validators.required);

      }
    }
  }



  setValidatorRequired(campo, requerido) {
    this.formPostulantes.controls[campo].setValidators(requerido);
    this.formPostulantes.controls[campo].updateValueAndValidity();
  }
}
