<span class="fill-remaining-space"></span>
<button mat-icon-button class="close-button" (click)="onClose()">
   <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>



<form (ngSubmit)="onSubmit()" [formGroup]="formDetallePostulante">
   <div class="container py-1 mx-auto">
      <div class="card card0 border-0">
         <div class="row d-flex">
            <div class="container-fluid">
               <div class="main-body">
                  <div class="row gutters-sm">

                     <div class="col-md-12 ml-1 mb-1 mr-1">
                        <div id="dataImprime">
                           <div class="card mb-1">
                              <div class="card-header">
                                 <h6>DATOS POSTULANTE</h6>
                              </div>

                              <div class="card-body">
                                 <div class="form-row">
                                    <div class="form-group col-md-6 mt-1 my-1">
                                       <strong>RUT :</strong>
                                       <br>
                                       <span>{{perfilModel.rut}}</span>
                                       <!-- <input type="text" class="form-control my-1" placeholder="Ingresa Rut"
                                       formControlName="rut" id="rut" [(ngModel)]="perfilModel.rut" /> -->
                                    </div>
                                    <div class="form-group col-md-6 mt-1 my-1">
                                       <strong>Nombre Completo :</strong>
                                       <br>
                                       <span>{{perfilModel.nombreCompleto}}</span>
                                    </div>

                                    <div class="form-group col-md-6 mt-1 my-1">
                                       <strong>Fecha de Nacimiento:</strong>
                                       <br>
                                       <span>{{perfilModel.fechaNacimiento}}</span>
                                    </div>

                                    <div class="form-group col-md-6 mt-1 my-1">
                                       <strong>Sexo:</strong>
                                       <br>
                                       <span>{{perfilModel.nombreSexo}}</span>
                                    </div>

                                    <div class="form-group col-md-6 mt-1 my-1">
                                       <strong>Nacionalidad:</strong>
                                       <br>
                                       <span>{{perfilModel.nacionalidad}}</span>
                                    </div>
                                    <div class="form-group col-md-6 mt-1 my-1">
                                       <strong>Estado Civil:</strong>
                                       <br>
                                       <span>{{perfilModel.estadoCivil}}</span>
                                    </div>
                                 </div>
                              </div>
                           </div>


                           <div class="card mb-1">
                              <div class="card-header">
                                 <h6>DATOS CONTACTO</h6>
                              </div>
                              <div class="card-body">
                                 <div class="form-row">
                                    <div class="form-group col-md-4 mt-1 my-1">
                                       <strong>Celular:</strong>
                                       <br>
                                       <span>{{perfilModel.celular}}</span>
                                    </div>

                                    <div class="form-group col-md-4 mt-1 my-1">
                                       <strong>Teléfono:</strong>
                                       <br>
                                       <span>{{perfilModel.telefono}}</span>
                                    </div>

                                    <div class="form-group col-md-4 mt-1 my-1">
                                       <strong>Teléfono:</strong>
                                       <br>
                                       <span>{{perfilModel.email}}</span>
                                    </div>
                                 </div>
                              </div>
                           </div>

                           <div class="card mb-1">
                              <div class="card-header">
                                 <h6>DIRECCIÓN</h6>
                              </div>
                              <div class="card-body">

                                 <div class="form-row">
                                    <div class="form-group col-md-6 mt-1 my-1">
                                       <strong>Región:</strong>
                                       <br>
                                       <span>{{perfilModel.REGION_NOMBRE}}</span>
                                    </div>
                                    <div class="form-group col-md-6 mt-1 my-1">
                                       <strong>Comuna:</strong>
                                       <br>
                                       <span>{{perfilModel.COMUNA_NOMBRE}}</span>
                                    </div>

                                    <div class="form-group col-md-4 mt-1 my-1">
                                       <strong>Calle:</strong>
                                       <br>
                                       <span>{{perfilModel.calle}}</span>
                                    </div>

                                    <div class="form-group col-md-4 mt-1 my-1">
                                       <strong>Número:</strong>
                                       <br>
                                       <span>{{perfilModel.numero}}</span>
                                    </div>

                                    <div class="form-group col-md-4 mt-1 my-1">
                                       <strong>Departamento / casa / Parcela:</strong>
                                       <br>
                                       <span>{{perfilModel.depto}}</span>
                                    </div>
                                 </div>
                              </div>
                           </div>


                           <div class="card mb-1">
                              <div class="card-header">
                                 <h6>PERFIL POSTULANTE</h6>
                              </div>
                              <div class="card-body">

                                 <div class="form-row">
                                    <div class="form-group col-md-6 mt-1 my-1">
                                       <strong>Puesto Trabajo:</strong>
                                       <br>
                                       <span>{{perfilModel.nombrePuesto}}</span>
                                    </div>
                                    <div class="form-group col-md-6 mt-1 my-1">
                                       <strong>Turno:</strong>
                                       <br>
                                       <span>{{perfilModel.nombreTurno}}</span>
                                    </div>

                                    <div class="form-group col-md-6 mt-1 my-1">
                                       <strong>Curso:</strong>
                                       <br>
                                       <span>{{perfilModel.nombreCurso}}</span>
                                    </div>

                                    <div class="form-group col-md-6 mt-1 my-1">
                                       <strong>Nivel Computación:</strong>
                                       <br>
                                       <span>{{perfilModel.nivelComputacion}}</span>
                                    </div>

                                 </div>
                              </div>
                           </div>


                           <div class="card mb-1">
                              <div class="card-header">
                                 <h6>DIRECCIÓN</h6>
                              </div>
                              <div class="card-body">

                                 <div class="form-row">
                                    <div class="form-group col-md-6 mt-1 my-1">
                                       <strong>Región:</strong>
                                       <br>
                                       <span>{{perfilModel.REGION_NOMBRE}}</span>
                                    </div>
                                    <div class="form-group col-md-6 mt-1 my-1">
                                       <strong>Comuna:</strong>
                                       <br>
                                       <span>{{perfilModel.COMUNA_NOMBRE}}</span>
                                    </div>

                                    <div class="form-group col-md-4 mt-1 my-1">
                                       <strong>Calle:</strong>
                                       <br>
                                       <span>{{perfilModel.calle}}</span>
                                    </div>

                                    <div class="form-group col-md-4 mt-1 my-1">
                                       <strong>Número:</strong>
                                       <br>
                                       <span>{{perfilModel.numero}}</span>
                                    </div>

                                    <div class="form-group col-md-4 mt-1 my-1">
                                       <strong>Departamento / casa / Parcela:</strong>
                                       <br>
                                       <span>{{perfilModel.depto}}</span>
                                    </div>
                                 </div>
                              </div>
                           </div>


                           <div class="card mb-1">
                              <div class="card-header">
                                 <h6>DIRECCIÓN</h6>
                              </div>
                              <div class="card-body">

                                 <div class="form-row">
                                    <div class="form-group col-md-6 mt-1 my-1">
                                       <strong>Región:</strong>
                                       <br>
                                       <span>{{perfilModel.REGION_NOMBRE}}</span>
                                    </div>
                                    <div class="form-group col-md-6 mt-1 my-1">
                                       <strong>Comuna:</strong>
                                       <br>
                                       <span>{{perfilModel.COMUNA_NOMBRE}}</span>
                                    </div>

                                    <div class="form-group col-md-4 mt-1 my-1">
                                       <strong>Calle:</strong>
                                       <br>
                                       <span>{{perfilModel.calle}}</span>
                                    </div>

                                    <div class="form-group col-md-4 mt-1 my-1">
                                       <strong>Número:</strong>
                                       <br>
                                       <span>{{perfilModel.numero}}</span>
                                    </div>

                                    <div class="form-group col-md-4 mt-1 my-1">
                                       <strong>Departamento / casa / Parcela:</strong>
                                       <br>
                                       <span>{{perfilModel.depto}}</span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <br>
                        <div class="form-row">
                           <div class="form-group col-md-6 text-center">
                              <button class="btn btn-outline-primary">CERRAR</button>
                           </div>
                           <div class="form-group col-md-6 text-center">
                              <button class="btn btn-danger " (click)="imprimirLista()">Descargar PDF</button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

</form>