<form [formGroup]="formRegPostulantes" (ngSubmit)="onSubmit()">
    <section id="guardiaPostula">
        <div class="container py-2 mx-auto">
            <div class="card card0 border-0">
                <div class="row d-flex">
                    <div class="container">
                        <div class="main-body">
                            <div class="row gutters-sm">
                                <div class="col-md-3 mt-2 ml-2 mr-2 mb-2">
                                    <div class="form-group card mt-1">
                                        <div class="card-body">
                                            <div class="d-flex flex-column align-items-center text-center">
                                                <img src="{{imagen}}" alt="Admin" class="rounded-circle" width="180" />
                                                <div class="mt-2">
                                                    <h4>{{titulo}}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group card mt-4 mx-auto">
                                        <div class="card-header">Descripción
                                            <span class="float-right" ngbTooltip="Ayuda">
                                                <svg width="1em" height="1em" viewBox="0 0 16 16"
                                                    class="bi bi-info-circle" fill="currentColor"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd"
                                                        d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                    <path
                                                        d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z" />
                                                    <circle cx="8" cy="4.5" r="1" />
                                                </svg>
                                            </span>
                                        </div>
                                        <p class="text-justify mx-auto" style="width: 90%;">Lorem ipsum dolor sit amet
                                            consectetur adipisicing elit. Facilis perspiciatis nam quod soluta odit
                                            voluptatibus dicta a ipsam voluptates, eos at iure sunt tempora debitis
                                            dignissimos! Quidem, non. Beatae doloribus aperiam ex at eius obcaecati
                                            soluta voluptas culpa quidem dolor, dolorem sed voluptatum in magnam! Sequi
                                            autem animi non laudantium.</p>
                                    </div>
                                </div>

                                <!-- <div id="preloader"></div> -->

                                <!-- <div class="loader-container">
                                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                                </div> -->

                                <div class="col-md-8 mt-3 ml-1 mb-1">
                                    
                                    <div id="infoi" *ngIf="loading">
                                        <img src="../../assets/img/cargando.gif" />
                                    </div>

                                    <div class="card mb-1">
                                        <div class="card-header">
                                            <h6>DATOS POSTULANTE</h6>
                                        </div>
                                        <div class="card-body">
                                            <div class="form-row">
                                                <div class="form-group col-md-6">
                                                    <mat-form-field appearance="outline" class="example-full-width-1">
                                                        <mat-label>Rut Postulante</mat-label>
                                                        <input matInput placeholder="Ingrese Rut" formControlName="rut"
                                                            id="rut" name="rut" [(ngModel)]="postulanteModel.rut"
                                                            (blur)="validaRut(postulanteModel.rut)" />
                                                        <mat-error
                                                            *ngIf="formRegPostulantes.get('rut').errors && formRegPostulantes.get('rut').touched && formRegPostulantes.get('rut').hasError('minlength')">
                                                            Minimo 7
                                                            caracteres</mat-error>
                                                    </mat-form-field>

                                                    <!-- <div class="alert alert-danger"
                                                        *ngIf="formRegPostulantes.get('rut').errors && formRegPostulantes.get('rut').touched">

                                                        <p *ngIf="formRegPostulantes.get('rut').hasError('minlength')">
                                                            Maximo 5
                                                            caracteres</p>
                                                        <p *ngIf="formRegPostulantes.get('rut').hasError('required')">
                                                            Campo Requerido
                                                        </p>
                                                    </div> -->
                                                    <p class="rutError" id="validacion-live"></p>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <mat-form-field appearance="outline" class="example-full-width-1">
                                                        <mat-label>Nombre Completo</mat-label>
                                                        <input matInput id="Inputnombre" formControlName="nombre"
                                                            [(ngModel)]="postulanteModel.nombreCompleto" />
                                                    </mat-form-field>
                                                    <!-- <div class="alert alert-danger"
                                                        *ngIf="formRegPostulantes.get('nombre').errors && formRegPostulantes.get('nombre').touched">
                                                        <p
                                                            *ngIf="formRegPostulantes.get('nombre').hasError('required')">
                                                            Campo Requerido
                                                        </p>
                                                    </div> -->
                                                </div>

                                                <div class="form-group col-md-6">
                                                    <mat-form-field appearance="outline" class="example-full-width-1">
                                                        <mat-label>Fecha de Nacimiento</mat-label>
                                                        <input matInput [max]="maxDate" [matDatepicker]="picker1" id="fechaNacimiento"
                                                            formControlName="fechaNacimiento"
                                                            [(ngModel)]="postulanteModel.fechaNacimiento" />
                                                        <mat-datepicker-toggle matSuffix [for]="picker1">
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker1></mat-datepicker>
                                                    </mat-form-field>
                                                </div>

                                                <div class="form-group col-md-6">
                                                    <label for="lblSexo">Sexo</label>


                                                    <br>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="sexoMasculino" name="sexo"
                                                            class="custom-control-input my-1" formControlName="sexo"
                                                            value="2" [(ngModel)]="postulanteModel.sexo">
                                                        <label class="custom-control-label" for="sexoMasculino">
                                                            Masculino
                                                        </label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="sexoFemenino" name="sexo"
                                                            class="custom-control-input" formControlName="sexo"
                                                            value="1" [(ngModel)]="postulanteModel.sexo">
                                                        <label class="custom-control-label" for="sexoFemenino">
                                                            Femenino
                                                        </label>
                                                    </div>
                                                </div>

                                                <div class="form-group col-md-6">
                                                    <mat-form-field appearance="outline" class="example-full-width-1">
                                                        <mat-label>Nacionalidad</mat-label>
                                                        <mat-select formControlName="nacionalidad"
                                                            (change)="onChangeComuna($event.target.value)"
                                                            [(ngModel)]="postulanteModel.nacionalidad">
                                                            <mat-option value="">Seleccione Nacionalidad</mat-option>
                                                            <mat-option
                                                                *ngFor="let nacionalidades of parametroNacionalidad"
                                                                value="{{nacionalidades.pra_val}}">
                                                                {{nacionalidades.pra_des
                                                                | uppercase}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <mat-form-field appearance="outline" class="example-full-width-1">
                                                        <mat-label>Estado Civil</mat-label>
                                                        <mat-select formControlName="estadoCivil"
                                                            [(ngModel)]="postulanteModel.estadoCivil">
                                                            <mat-option value="">Seleccione Estado civil</mat-option>
                                                            <mat-option *ngFor="let estadoCivl of parametroEstadoCivil"
                                                                value="{{estadoCivl.pra_val}}">{{estadoCivl.pra_des |
                                                                uppercase}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card mb-1">
                                        <div class="card-header">
                                            <h6>DATOS CONTACTO</h6>
                                        </div>
                                        <div class="card-body">

                                            <div class="form-row">
                                                <div class="form-group col-md-6">
                                                    <mat-form-field appearance="outline" class="example-full-width-1">
                                                        <mat-label>Celular</mat-label>
                                                        <input matInput id="celular" formControlName="celular"
                                                            [(ngModel)]="postulanteModel.celular" />
                                                        <mat-error
                                                            *ngIf="(formRegPostulantes.get('celular').dirty || formRegPostulantes.get('celular').touched) && formRegPostulantes.get('celular').invalid && formRegPostulantes.get('celular').errors.pattern">
                                                            Ingrese solo números</mat-error>
                                                    </mat-form-field>
                                                </div>

                                                <div class="form-group col-md-6">
                                                    <mat-form-field appearance="outline" class="example-full-width-1">
                                                        <mat-label>Teléfono</mat-label>
                                                        <input matInput id="telefono" placeholder="2200000000"
                                                            formControlName="telefono"
                                                            [(ngModel)]="postulanteModel.telefono" />
                                                        <!-- <mat-error *ngIf="(formRegPostulantes.get('telefono').dirty || formRegPostulantes.get('telefono').touched) && formRegPostulantes.get('telefono').invalid && formRegPostulantes.get('telefono').errors.pattern">Ingrese solo números</mat-error> -->
                                                        <mat-error
                                                            *ngIf="formRegPostulantes.get('telefono').invalid && formRegPostulantes.get('telefono').errors.pattern">
                                                            Ingrese solo números</mat-error>
                                                    </mat-form-field>
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <mat-form-field appearance="outline" class="example-full-width-1">
                                                        <mat-label>Correo Electrónico</mat-label>
                                                        <input matInput id="inputemail" ngModel [email]="true"
                                                            formControlName="email" required
                                                            [(ngModel)]="postulanteModel.email" />

                                                    </mat-form-field>
                                                    <mat-error
                                                        *ngIf="formRegPostulantes.get('email').invalid && (formRegPostulantes.get('email').dirty || formRegPostulantes.get('email').touched)">
                                                        Email es requerido</mat-error>
                                                    <mat-error
                                                        *ngIf="(formRegPostulantes.get('email').dirty || formRegPostulantes.get('email').touched) && formRegPostulantes.get('email').invalid && formRegPostulantes.get('email').errors.pattern">
                                                        Email es invalido</mat-error>
                                                </div>

                                                <!-- <div class="alert alert-danger"
                                                    *ngIf="formRegPostulantes.get('email').invalid && (formRegPostulantes.get('email').dirty || formRegPostulantes.get('email').touched)">
                                                    <div class="container-error-message"
                                                        *ngIf="formRegPostulantes.get('email').errors.required">
                                                        Email es requerido                                                    
                                                    </div>
                                                    <div class="container-error-message"
                                                        *ngIf="(formRegPostulantes.get('email').dirty || formRegPostulantes.get('email').touched) && formRegPostulantes.get('email').invalid && formRegPostulantes.get('email').errors.pattern">
                                                        Correo invalido
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card mb-1">
                                        <div class="card-header">
                                            <h6>DIRECCIÓN</h6>
                                        </div>
                                        <div class="card-body">

                                            <div class="form-row">
                                                <div class="form-group col-md-6">
                                                    <mat-form-field appearance="outline" class="example-full-width-1">
                                                        <mat-label>Región</mat-label>
                                                        <mat-select formControlName="region"
                                                            (valueChange)="onChangeComuna($event)"
                                                            [(ngModel)]="postulanteModel.region">
                                                            <mat-option value="">Seleccione Región</mat-option>
                                                            <mat-option *ngFor="let regiones of regionesModel"
                                                                value="{{regiones.REGION_ID}}">
                                                                {{regiones.REGION_NOMBRE
                                                                | uppercase}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <mat-form-field appearance="outline" class="example-full-width-1">
                                                        <mat-label>Comuna</mat-label>
                                                        <mat-select formControlName="comuna"
                                                            [(ngModel)]="postulanteModel.comuna">
                                                            <mat-option value="">Seleccione Comuna</mat-option>
                                                            <mat-option *ngFor="let comuna of comunaModel"
                                                                value="{{comuna.COMUNA_ID}}">{{comuna.COMUNA_NOMBRE
                                                                | uppercase}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>

                                                <div class="form-group col-md-12">

                                                    <mat-form-field appearance="outline" class="example-full-width-1">
                                                        <mat-label>Calle</mat-label>
                                                        <input matInput id="inputDireccion" formControlName="calle"
                                                            [(ngModel)]="postulanteModel.calle" />
                                                    </mat-form-field>
                                                </div>

                                                <div class="form-group col-md-6">
                                                    <mat-form-field appearance="outline" class="example-full-width-1">
                                                        <mat-label>Número</mat-label>
                                                        <input matInput id="numero" formControlName="numero"
                                                            [(ngModel)]="postulanteModel.numero" />
                                                    </mat-form-field>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <mat-form-field appearance="outline" class="example-full-width-1">
                                                        <mat-label>Departamento / casa /
                                                            parcela</mat-label>
                                                        <input matInput id="depto" formControlName="depto"
                                                            [(ngModel)]="postulanteModel.depto" />
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card mb-1">
                                        <div class="card-header">
                                            <h6>SEGURIDAD</h6>
                                        </div>
                                        <div class="card-body">
                                            <div class="form-row">
                                                <div class="form-group col-md-6">
                                                    <label for="lbltRut">
                                                        <h6>Ingrese Contraseña</h6>
                                                    </label>
                                                    <div class="input-group">
                                                        <input matInput id="passEmpresa" name="Contrasenia"
                                                            [type]="hide ? 'password' : 'text'"
                                                            formControlName="passEmpresa"
                                                            placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                                                            [(ngModel)]="postulanteModel.passEmpresa">
                                                        <mat-icon matSuffix (click)="hide = !hide">{{hide ?
                                                            'visibility_off' : 'visibility'}}</mat-icon>
                                                        <div *ngIf="formRegPostulantes.get('passEmpresa').touched && formRegPostulantes.get('passEmpresa').invalid"
                                                            class="alert alert-danger">
                                                            <div
                                                                *ngIf="formRegPostulantes.get('passEmpresa').errors.required">
                                                                Contraseña es requerida.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <span class="mdi mdi-eye" id="mostrar"> <span class="pwdtxt"
                                                            style="cursor:pointer;">Mostrar contraseña</span></span> -->
                                                </div>

                                                <div class="form-group col-md-6">
                                                    <label for="lbltRut">
                                                        <h6>Repita Contraseña</h6>
                                                    </label>
                                                    <input id="RepetirContrasenia" name="RepetirContrasenia"
                                                        [type]="hide ? 'password' : 'text'"
                                                        formControlName="passEmpresa1"
                                                        placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;">

                                                    <div *ngIf="formRegPostulantes.get('passEmpresa1').touched && formRegPostulantes.get('passEmpresa1').invalid"
                                                        class="alert alert-danger">
                                                        <div
                                                            *ngIf="formRegPostulantes.get('passEmpresa1').errors.required">
                                                            Contraseña es requerida.</div>
                                                        <div
                                                            *ngIf="formRegPostulantes.get('passEmpresa1').errors.confirmedValidator">
                                                            Las contraseñas no coinciden. Favor vuelva a ingresarla.
                                                            .</div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-row">
                                        <div class="form-group col-md-6 text-center">
                                            <button type="submit" class="btn btn-outline-primary"
                                                [disabled]="!formRegPostulantes.valid">Enviar</button>
                                        </div>
                                        <div class="form-group col-md-6 text-center">
                                            <button class="btn btn-outline-primary">Limpiar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</form>