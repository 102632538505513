<form (ngSubmit)="onSubmit()" [formGroup]="formReferido" #formReferir="ngForm">
   <section id="EvaluarEmpleado">
      <div class="container py-2 mx-auto">
         <div class="card card0 border-0">
            <div class="row d-flex">
               <div class="container">
                  <div class="main-body">
                     <div class="row gutters-sm">
                        <div class="col-md-3 mt-2 ml-2 mr-2 mb-2">                        
                           <div class="form-group card mt-1">
                              <div class="card-body">
                                 <div class="d-flex flex-column align-items-center text-center">
                                    <img
                                       src="http://www.managementjournal.net/images/joomlart/article/f21bb913d01c75ee248de294e9ed844a.jpg"
                                       alt="Admin" class="rounded-circle" width="180" />
                                    <div class="mt-2">
                                       <h4>Evaluar Empleado</h4>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="form-group card mt-4 mx-auto">
                              <div class="card-header">
                                 Evaluación del Empleado
                                 <span class="float-right" ngbTooltip="Ayuda">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-info-circle"
                                       fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                       <path fill-rule="evenodd"
                                          d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                       <path
                                          d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z" />
                                       <circle cx="8" cy="4.5" r="1" />
                                    </svg>
                                 </span>
                              </div>
                              <p class="text-justify mx-auto" style="width: 90%;">Desde este Formulario,
                                 Podrá realizar la evaluación del empleado, teniendo la posibilidad, de
                                 recomendarlo o no, además se podrá dejar un registro, de las razones,
                                 por las cuales se dejo de presindir de sus servicios, para de esta forma
                                 contar con un registro de datos, que resalte a los buenos
                                 elementos y advierta, cuando ocurre lo contrario.
                              </p>
                           </div>
                        </div>
                        <div class="col-md-8 mt-3 ml-3 mb-2 mr-2">
                           <div class="card mb-1">
                              <div class="card-body">
                                 <div class="form-row">
                                    <div class="form-group col-md-6">
                                       <mat-form-field appearance="outline" class="example-full-width-1">
                                          <mat-label>Rut Empleado</mat-label>
                                          <input matInput placeholder="Ingresa Rut Empleado"
                                             formControlName="rutempleado" id="rutempleado"
                                             [(ngModel)]="empleadoModel.rutempleado" />
                                       </mat-form-field>
                                       <p class="rutErrorEmpl" id="validacion-live"></p>
                                    </div>

                                    <div class="form-group col-md-6">
                                       <mat-form-field appearance="outline" class="example-full-width-2">
                                          <mat-label>Nombre Completo Empleado</mat-label>
                                          <input matInput placeholder="Ingresa Nombre Empleado"
                                             formControlName="nombreempleado" id="nombreEmpleado"
                                             [(ngModel)]="empleadoModel.nombreempleado" #name=""
                                             [ngClass]="{ 'is-invalid': formReferido && name.invalid }" required />
                                       </mat-form-field>
                                    </div>

                                 </div>
                                 <div class="form-row">
                                    <div class="form-group col-md-6">
                                       <mat-form-field appearance="outline" class="example-full-width-1">
                                          <mat-label>Rut Empresa</mat-label>
                                          <input matInput class="my-1" placeholder="Ingresa Rut Empresa"
                                             formControlName="rutempresa" id="rutempresa"
                                             [(ngModel)]="empleadoModel.rutempresa" readonly />
                                       </mat-form-field>
                                       <p class="rutErrorEmp" id="validacion-live"></p>
                                    </div>
                                    <div class="form-group col-md-6">
                                       <mat-form-field appearance="outline" class="example-full-width-2">
                                          <mat-label>Nombre Empresa</mat-label>
                                          <input matInput class="my-1" id="nombreempresa" name="nombreempresa"
                                             formControlName="nombreempresa" placeholder="Ingresa Nombre Empresa"
                                             [(ngModel)]="empleadoModel.nombreempresa" minlength="2" maxlength="100"
                                             readonly />
                                       </mat-form-field>
                                    </div>

                                 </div>
                                 <div class="form-row">
                                    <div class="form-group col-md-6">
                                       <mat-form-field appearance="outline" class="example-full-width-1">
                                          <mat-label>Fecha Ingreso Empresa</mat-label>
                                          <input matInput [max]="maxDate"  [matDatepicker]="picker" id="fechaingreso"
                                             formControlName="fechaingreso" [(ngModel)]="empleadoModel.fechaingreso" />
                                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                          <mat-datepicker #picker></mat-datepicker>
                                       </mat-form-field>
                                    </div>
                                    <div class="form-group col-md-6">
                                       <mat-form-field appearance="outline" class="example-full-width-1">
                                          <mat-label>Fecha Termino Empresa</mat-label>
                                          <input matInput [matDatepicker]="picker2" id="fechatermino"
                                             formControlName="fechatermino" [(ngModel)]="empleadoModel.fechatermino" />
                                          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                          <mat-datepicker #picker2></mat-datepicker>
                                       </mat-form-field>
                                    </div>
                                 </div>
                                 <div class="form-row">
                                    <div class="form-group col-md-6">
                                       <mat-form-field appearance="outline" class="example-full-width-1">
                                          <mat-label>Articulo</mat-label>
                                          <mat-select name="idarticulo" id="idarticulo"
                                             (valueChange)="onChangeinciso($event)" formControlName="idarticulo"
                                             [(ngModel)]="empleadoModel.idarticulo">
                                             <mat-option value="">Seleccione Articulo</mat-option>
                                             <mat-option *ngFor="let articulos of articulomodel"
                                                value="{{articulos.idarticulo}}">{{articulos.nombrearticulo}}
                                             </mat-option>
                                          </mat-select>
                                       </mat-form-field>
                                    </div>
                                    <div class="form-group col-md-6">
                                       <mat-form-field appearance="outline" class="example-full-width-1">
                                          <mat-label>Inciso</mat-label>
                                          <mat-select name="idinciso" id="idinciso" [(ngModel)]="empleadoModel.idinciso"
                                             formControlName="idinciso">
                                             <mat-option value="">Seleccione Inciso</mat-option>
                                             <mat-option *ngFor="let incisos of incisomodel" value
                                                value="{{incisos.idinciso}}">{{incisos.nombreincisocorto}}
                                             </mat-option>
                                          </mat-select>
                                       </mat-form-field>
                                    </div>
                                 </div>
                                 <div class="form-row">
                                    <div class="form-group col-md-12">
                                       <mat-form-field appearance="outline" class="example-full-width-1">
                                          <mat-label>Observación</mat-label>
                                          <input matInput #message formControlName="Observacion"
                                             placeholder="Ingresar Observación" id="Observacion"
                                             [(ngModel)]="empleadoModel.Observacion" maxlength="50">
                                             <mat-hint align="end">{{message.value.length}} / 50</mat-hint>
                                       </mat-form-field>
                                    </div>
                                 </div>
                                 <div class="form-row">
                                    <div class="form-group col-md-6">
                                       <label for="lblNivelEduacional">Autorización Información</label>
                                       <div class="justifyText">
                                          <!-- <mat-checkbox name="autorizacion" class="form-control"
                                          formControlName="autorizacion" placeholder="Autorizar"
                                          [(ngModel)]="empleadoModel.autorizacion" />
                                       Autorizo a Empresa InfoReed, a utilizar la información del despido, para
                                       fines correspondientes.
                                       </mat-checkbox> -->

                                          <mat-checkbox name="autorizacion" formControlName="autorizacion"
                                             placeholder="Autorizar" [(ngModel)]="empleadoModel.autorizacion">

                                          </mat-checkbox>
                                          Autorizo a Empresa InfoReed,
                                          a utilizar la información del despido, para
                                          fines correspondientes.
                                       </div>
                                    </div>

                                    <div class="form-group col-md-6">
                                       <label for="lblJornada">¿Recomendaría al empleado?</label>
                                       <div>
                                          <label for="lblNo" ngClass="labelBeforeSlide">NO</label>
                                          <mat-slide-toggle formControlName="recomienda" [checked]="true"
                                             [(ngModel)]="empleadoModel.recomienda">SI</mat-slide-toggle>
                                       </div>
                                    </div>
                                 </div>
                                 <br>
                                 <div class="form-row">
                                    <div class="form-group col-md-6 text-center">
                                       <div class="col-md-6"><button type="submit"
                                             class="btn btn-blue text-center mb-1 py-2">Evaluar</button></div>
                                    </div>
                                    <div class="form-group col-md-6 text-center">
                                       <button class="btn btn-outline-primary">Limpiar</button>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
</form>


<!-- 
<form class="example-form" [formGroup]="formReferido">
   <div class="">
      <div class="container py-2 mx-auto">
         <div class="card card0 border-1">
            <div class="container-fluid">
               <div class="card-body">

                  <div class="example-container">
                     <mat-form-field appearance="outline" class="example-full-width-1">
                        <mat-label>Rut Empleado</mat-label>
                        <input matInput placeholder="Ej. 12345678-9" class="right">
                     </mat-form-field>

                     <mat-form-field appearance="outline" class="example-full-width-2">
                        <mat-label>Nombre Completo Empleado</mat-label>
                        <input matInput placeholder="Ej. Juan Perez" class="right">
                     </mat-form-field>
                  </div>

                  <div class="example-container">
                     <mat-form-field appearance="outline" class="example-full-width-1">
                        <mat-label>Rut Empresa</mat-label>
                        <input matInput placeholder="Ej. 12345678-9" class="right" formControlName="rutempresa"
                           id="rutempresa" [(ngModel)]="empleadoModel.rutempresa">
                     </mat-form-field>

                     <mat-form-field appearance="outline" class="example-full-width-2">
                        <mat-label>Nombre Empresa</mat-label>
                        <input matInput placeholder="Ej. Guardias Scl" class="right">
                     </mat-form-field>
                  </div>

                  <mat-form-field class="example-full-width">
                     <mat-label>Leave a comment</mat-label>
                     <textarea matInput placeholder="Ex. It makes me feel..."></textarea>
                  </mat-form-field>



                  <mat-checkbox class="example-margin">Checked</mat-checkbox>
                  <mat-checkbox class="example-margin">Indeterminate</mat-checkbox>

               </div>
            </div>
         </div>
      </div>
   </div>
</form> -->