import { AuthguardGuard } from './../core/authguard.guard';
import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
//publicidad
import { OwlOptions } from 'ngx-owl-carousel-o';
// servicio
import { Publicidad } from '../core/admin/empleos';
import { PublicidadService } from '../services/publicidad/publicidad.service';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {

  public publicidades: Publicidad[] = [
    new Publicidad("1", "", "")
  ];

  items: Observable<any[]>;
  loginbtn: boolean;
  public salidaPassword: string = "";
  nombreUsuario: string;


  constructor(
    private empleoService: PublicidadService,
    protected authAcceso: AuthguardGuard
  ) { }

  ngOnInit(): void {

    if (this.authAcceso.isAuth()) {
      this.loginbtn = true;
    }
    
    this.obtenerPublicidad();
  }

  obtenerPublicidad() {
    return this.empleoService
      .getPublicidad()
      .subscribe((publicidad: Publicidad[]) => this.publicidades = publicidad);
  }


  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 2000,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['<', '>'],
    margin: 10,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }
}
