<div class="card mb-1">
    <div class="card-header card1">
        <h6>POSTULACIÓN</h6>
    </div>
    <div class="card-body">
        <div class="form-row">
            <div class="form-group col-md-12 mt-1 my-1">
                <strong>Envio de información a: {{nombreEmpresa}}</strong>
                <strong>Correo Empresa: {{correoEmpresa}}</strong>
            </div>
            <div class="form-group col-md-12 mt-1 my-1 justificado">
                <strong>Cargo : {{cargo}}</strong>
                <br>
                <p>Estimado(a) :<b> {{nombrePostulante}} </b> enviaremos a la empresa que usted postulo su información
                    para que ingrese en el proceso de seleccion, se enviara su datos personales y se enviara una copia a
                    su correo <b>{{ correoPostulante}} </b>

                <p>Agradecemos su postulacion.</p>
                <p>Si no desea realizar la postulacion, favor presione el boton Cancelar</p>
            </div>

            <div class="form-group col-md-6 text-center mt-3">
                <button class="btn btn-outline-success"(click)="envio()">Enviar</button>
            </div>
            <div class="form-group col-md-6 text-center mt-3">
                <button class="btn btn-outline-warning" (click)="onClose()">Cancelar</button>
            </div>
        </div>
    </div>
</div>