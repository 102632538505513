import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { consultarempleado } from '../../../core/empresa/consultarempleado';
import { environment } from '../../../../environments/environment';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConsultarempleadoService {
  rutConsultado;

  baseUrl = environment.baseUrl
  constructor(private http: HttpClient) { }

  ConsultarEmpleado(rutEmpleado: string | number) {
    return this.http.get(`${this.baseUrl}/Empresa/consultarempleado/getListarEvaluacionEmpleado.php?rutEmpleado=${rutEmpleado}`);
  }

  // verDetallePostulantes


  //Lista todos los evaluados
  ListarTodosLosEvaluados() {
    return this.http.get(`${this.baseUrl}/Empresa/consultarempleado/getListasTodosLosEvaluados.php?`);
  }
  //Lista todos los evaluados

  getListarEvaluadosPorEstado(estado: string | number) {
    return this.http.get(`${this.baseUrl}/Empresa/consultarempleado/getListarTodosPorEstado.php?estado=${estado}`);
  }

  getEvaluacionEmpresaEmpleado(rutEmpleado: string | number, rutEmpresa: string | number,) {
    return this.http.get(`${this.baseUrl}/Empresa/misevaluaciones/getListarMisEvaluacionesEmpleado.php?rutEmpleado=${rutEmpleado}&rutEmpresa=${rutEmpresa}`);
  }

  getMisEvaluacionEmpresa(rutEmpresa: string | number,) {
    return this.http.get(`${this.baseUrl}/Empresa/misevaluaciones/getListarMisEvaluacioneEmpresa.php?rutEmpresa=${rutEmpresa}`);
  }

  getListaEvaluacionEmpleado(rutEmpleado: string | number) {
    return this.http.get(`${this.baseUrl}/Empresa/consultarempleado/getListarEvaluacionEmpleado.php?empleado=${rutEmpleado}`);
  }

  deleteEvaluacion(idEvaluacion: string | number) {
    return this.http.delete(`${this.baseUrl}/Empresa/evaluarEmpleado/deleteEvaluacion.php?IDevaluacion=${idEvaluacion}`);
  }


}
