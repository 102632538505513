<div class="contenedor-padre">
  <mat-toolbar class="barra" color="warn">
    <!-- <mat-toolbar-row> -->
    <span>
      Gestión Administrador&nbsp; - {{ nombreTitulo.nombreTitulos }}
    </span>
    <!-- </mat-toolbar-row> -->
  </mat-toolbar>

  <mat-sidenav-container class="contenido">
    <mat-sidenav style="min-width: 300px;" #cajon mode="side" opened position="start">
      <div class="sidebar-heading mt-3">
        <div class="profile-card">
          <img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="user" class="profile-photo">
          <h5><a href="#" class="text-white">{{globals.role}}</a></h5>
          <h5><a href="#" class="text-white">{{globals.email}}</a></h5>
        </div>
        <a routerLink="['/accesoAdmin']">  Menu Administrador </a>

      </div>
      <mat-nav-list>
        <mat-accordion class="app-nav-accordion">
          <a mat-list-item routerLink="/accesoAdmin/AutorizarFuna">
            <mat-icon>done_all</mat-icon>
            Autorizar Funa
          </a>

          <mat-expansion-panel [class.mat-elevation-z0]="true">
            <mat-expansion-panel-header>
              Gestión Perfil Empresa
            </mat-expansion-panel-header>
            <mat-nav-list>
              <a mat-list-item routerLink="/accesoAdmin/RegistrarEmpresa/1">
                <mat-icon>business</mat-icon> Ingresar Empresa
              </a>
              <a mat-list-item routerLink="/accesoAdmin/RegistrarEmpresa/2">
                <mat-icon>view_list</mat-icon> Listar Empresa
              </a>
            </mat-nav-list>
          </mat-expansion-panel>

          <mat-expansion-panel [class.mat-elevation-z0]="true">
            <mat-expansion-panel-header>
              Gestión Publicidad
            </mat-expansion-panel-header>
            <mat-nav-list>
              <a mat-list-item routerLink="/accesoAdmin/GestionarPublicidad/1">
                <mat-icon>production_quantity_limits</mat-icon> Ingresar Publicidad
              </a>
              <a mat-list-item routerLink="/accesoAdmin/GestionarPublicidad/2">
                <mat-icon>view_list</mat-icon> Listar Publicidad
              </a>
            </mat-nav-list>
          </mat-expansion-panel>

          <mat-expansion-panel [class.mat-elevation-z0]="true">
            <mat-expansion-panel-header>
              Gestión Empleo
            </mat-expansion-panel-header>
            <mat-nav-list>
              <a mat-list-item routerLink="/accesoAdmin/GestionarEmpleo/1">
                <mat-icon>people_outline</mat-icon> Ingresar Empleo
              </a>
              <a mat-list-item routerLink="/accesoAdmin/GestionarEmpleo/2">
                <mat-icon>view_list</mat-icon> Listar Empleo
              </a>
            </mat-nav-list>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content fullscreen>
      <button (click)="cajon.toggle()" mat-icon-button>
        <mat-icon>menu</mat-icon>
      </button>
      <router-outlet></router-outlet>

    </mat-sidenav-content>
  </mat-sidenav-container>
</div>