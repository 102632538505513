import { TitulosService } from './../../../services/parametros/titulos.service';
import { Component, OnInit } from '@angular/core';
import { CountryI, CityI } from '../../../core/empresa/model.interface';
import { HttpClient, HttpEventType, HttpErrorResponse } from '@angular/common/http';

import { DataService } from '../../../services/empresa/articulo/data.service.service';

import { Router, Params } from '@angular/router';
import { FaConfig, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faBellSlash, faHandPaper, faUser } from '@fortawesome/free-regular-svg-icons';
import { faCoffee, faTrash, faTrashAlt, faPencilAlt, faTh, faCalendar, faCalendarAlt, faInfoCircle, faTrashRestore } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import * as $ from 'jquery';
import { FormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
//Modeo
import { Larticulos, Linciso, Lcausal } from './../../../core/empresa/comboempresa.model';
import { registroempleado } from '../../../core/empresa/registroempleado';
//Servicio
import { RegistroempleadoService } from '../../../services/empresa/registroempleado/registroempleado.service';
import { ComboempresaService } from '../../../services/parametros/comboempresa.service';
//Material
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogoconfirmacionComponent } from '../../../dialogoconfirmacion/dialogoconfirmacion.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Inject } from '@angular/core';
import { faKickstarter } from '@fortawesome/free-brands-svg-icons';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

declare var $: any;
declare var jQuery: any;

@Component({
    selector: 'app-detallemisevaluaciones',
    templateUrl: './detallemisevaluaciones.component.html',
    styleUrls: ['./detallemisevaluaciones.component.css']
})
export class DetallemisevaluacionesComponent implements OnInit {

    // Seleccionamos o iniciamos el valor '0' del <select>
    opcionSeleccionado: string = '0';
    verSeleccion: string = '';
    btnEjecutar: string = '';
    opcion1 = true;
    valor1 = null;
    idEvaluacion: string;
    origen: string;
    estado: string;
    observacion: string;
    generarPdf: boolean
    //Variable utilizada, par apersonalizar los mensajes
    mensaje: string;
    //Se utiliza para manejar la operación que se ejecuta
    operacion: string;
    //Articulos e Incisos
    habilitar: boolean;
    habilitarboton: boolean;

    public articulomodel: Larticulos[] = [new Larticulos(0, "prueba", "aaiiiii articulo")];
    public incisomodel: Linciso[] = [new Linciso(0, "Debe Seleccione Articulo", "", 0)];
    //Modelo Evaluacion Empleados
    public empleadoModel: registroempleado = new registroempleado('', '', '', '', '', '', '', '', '', '', '', '', '');

    formListar: FormGroup;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private http: HttpClient,
        //Servicio
        private service: RegistroempleadoService,
        private articuloService: ComboempresaService,

        //Material
        private snackBar: MatSnackBar,
        private dialogo: MatDialog,
        private router: Router,
        public dialogRef: MatDialogRef<DetallemisevaluacionesComponent>,
        //Paso Parametro URL
        private ruta: ActivatedRoute,
        private nombreTituloService: TitulosService
    ) {
        this.crearForm();

    }


    ngOnInit(): void {

        this.nombreTituloService.nombreTitulos = "Detalle Evaluaciones";

        //Establece operación con cero
        this.operacion = "0";
        this.habilitar = true;
        this.habilitarboton = true;
        //Determina la logica de carga que debe seguir, en base al estado y al origen
        this.ValidaCargaInicial();
        //
        this.CargaInicial();
        //

        //Crea campos requeridos formulario
        this.crearForm();


    }

    //Se encarga de Realizar la carga dependiendo, de el estado o el origen
    ValidaCargaInicial() {
        this.idEvaluacion = this.data['idEvaluacion'];
        // console.log("id:" + this.idEvaluacion);
        this.origen = this.data['dataorigen'];
        // console.log("origen:" + this.origen);
        this.estado = this.data['estado'];
        console.log("estado:" + this.estado);
        this.observacion = this.data['observacion'];
        // console.log("observacion:" + this.observacion);
        this.generarPdf = this.data['generaPdf'];
        console.log("Genera PDF : " + this.generarPdf)


        if (this.generarPdf) {
           this.generaPDF(this.idEvaluacion);
        }


        //Consulta si ya esta autorizado
        if (this.estado === "3") {
            this.btnEjecutar = "Aceptar";
            this.habilitarboton = false;
            //Indica que ya esta autorizado, para que al presionar Cancelar
            //pueda saber y no libere el registro
            this.operacion = "3";
            this.habilitar = true;
        } else {

            //Valida si origen es desde autorizar o solo visualizar
            if (this.origen === "autorizarfuna") {
                //Se valida que el estado sea Realmente, ingresado sin autorizar
                if (this.estado == "1") {
                    this.habilitarboton = true;
                    this.btnEjecutar = "Autorizar";
                    //Debe tomar Registro y marcar con 2
                    this.operacion = "2"
                    this.habilitar = false;
                    this.BloquearRegistro();
                }
            } else {
                this.habilitarboton = false;
                this.btnEjecutar = "Aceptar";
                this.operacion = "1"
            }
        }
    }


    CargaInicial() {
        if (this.idEvaluacion === "0") {

        } else {
            // console.log("llamado servicioSSSSSs " + this.idEvaluacion);
            //Consulta Servicio, para recuperar Evaluacion
            this.service.getEditarEvaluacion(this.idEvaluacion).subscribe((empleadoModel: registroempleado) => {
                this.empleadoModel = empleadoModel
                this.onChangeinciso(this.empleadoModel.idarticulo);
            })

            //Carga valores y eventos
            this.ListarArticulos();
            //Valida ingreso desde mi Evaluaciones para editar
            this.EditarEvaluacion();
        }
    }


    //1)Recibe Id Evaluacion desde mis evaluaciones, donde
    //Si biene ID, indica que debe rescatar evaluación y cargarla en pantalla
    EditarEvaluacion() {
        console.log("Lo fue a buscar denuevo");
        //let valorEntrada = this.ruta.snapshot.paramMap.get("data");
        // console.log("valor id evaluacion" + this.idEvaluacion);
        if (this.idEvaluacion === "0") {
            //TomaRegistro
            // this.BloquearRegistro();
        } else {
            // console.log("llamado servicio " + this.idEvaluacion);
            //Consulta Servicio, para recuperar Evaluacion
            this.service.getEditarEvaluacion(this.idEvaluacion).subscribe((empleadoModel: registroempleado) => {
                this.empleadoModel = empleadoModel
                // this.onChangeinciso('this.empleadoModel.idarticulo');
                // console.log("------>");
                // console.log("ID ARTICULO " + this.empleadoModel.idarticulo);
                // console.log(this.empleadoModel);
            })
        }
    }


    generaPDF(idEval: string) {
        console.log("GENERANDO PDF");

        setTimeout(() => {
            console.log("TIEMPO TRANSCURIDO");
            this.imprimirLista(idEval);  // prueba se debe descomentar para imprimir al abrir pagina
            this.onClose();
        }, 900);


    }



    //2) Carga valores de Combo Articulos
    ListarArticulos() {
        // console.log("Lista articulo");
        return this.articuloService.getListaArticulos().subscribe((articulomodel: Larticulos[]) => this.articulomodel = articulomodel);
    }

    //3) Crea campos requeridos formulario
    crearForm() {
        this.formListar = this.fb.group({
            rutempleado: ['', [Validators.required]],
            nombreempleado: ['', [Validators.required]],
            rutempresa: ['', [Validators.required]],
            nombreempresa: ['', [Validators.required]],
            fechaingreso: ['', [Validators.required]],
            fechatermino: ['', [Validators.required]],
            idarticulo: ['', [Validators.required]],
            idinciso: ['', [Validators.required]],
            Observacion: ['', [Validators.required]],
            autorizacion: ['', [Validators.required]],
            recomienda: ['', [Validators.required]],
        })
    }

    //Otros Eventos
    onChangeinciso(value) {
        return this.articuloService.getListaIncisos(value).subscribe((incisomodel: Linciso[]) => this.incisomodel = incisomodel);
    }

    //Autorizar


    onSubmit() {

        // console.log("DEsde onsubmit");
        if (this.origen === "autorizarfuna") {
            // console.log("operacion desde aca:" + this.operacion);
            // console.log("Nombre del boton:" + this.btnEjecutar);
            if (this.btnEjecutar === "Autorizar") {
                //establesca operación en 3    
                //this.operacion = "3";
                //LLama a autorizar
                this.AutorizarEvaluacion();
            }
            else {
                if (this.operacion === "2") {
                    this.mensaje = "Operación Cancelada, Registro Liberado";
                    this.Cancelar();
                }
                else {
                    if (this.btnEjecutar === "Aceptar") {
                        this.dialogRef.close();
                    }
                }
            }
        } else {
            //this.onClose();
            //this.Cerrar();
            //console.log("paso por cancelar 2222");
            //this.dialogRef.close();
            //this.Cancelar();
        }
    }


    //Autoriza Observación.
    AutorizarEvaluacion() {
        // console.log("Esta Ejecutando Autorizaaa registro"); 
        // console.log(this.empleadoModel.Observacion);
        // console.log("Obj empleado");
        // console.log(this.empleadoModel);

        if (this.empleadoModel.Observacion !== "") {
            this.empleadoModel.estado = "3";
            this.service.ActualizarEstadoEvaluacion(this.empleadoModel).subscribe(event => {
                if (event.type === HttpEventType.Response) {
                    console.log("With Parsed JSON :", event.body);
                    if (event.body['resultado'] === false) {
                        this.dialogo.open(DialogoconfirmacionComponent, {
                            data: `Ocurrio al Autorizar `,
                        });
                        console.log("error");
                    } else {
                        this.snackBar.open('Autorización Exitoza', undefined, {
                            duration: 1500,
                        });
                        this.dialogRef.close();

                        window.location.reload();
                        //Envia a mis evaluaciones
                        //this.router.navigate(['/accesoAdmin/AutorizarFuna/']);
                        //    this.formListar.reset();
                    }
                }
            })
        } else {
            this.dialogo.open(DialogoconfirmacionComponent, {
                data: `Autorización, requiere observación`,
            });
        }
    }

    onClose() {
        if (this.operacion === "2") {
            console.log("Cerrando2");
            this.DesbloquearRegistro();
        } else {
        }
        this.dialogRef.close();
    }

    //Permite tomar o soltar registro
    BloquearRegistro() {
        // console.log("Esta Ejecutando Bloqueo bloqueo registro");

        this.empleadoModel.estado = "2";
        this.empleadoModel.Observacion = this.observacion;
        this.empleadoModel.idEvaluacion = this.idEvaluacion;
        // console.log("valor tomado para bloqueo");
        // console.log("obj:" + this.empleadoModel);
        // console.log("estado:" + this.empleadoModel.estado);
        // console.log("observacion:" + this.empleadoModel.Observacion);
        // console.log("id" + this.empleadoModel.idEvaluacion);

        if (this.empleadoModel.estado === "2") {
            this.service.ActualizarEstadoEvaluacion(this.empleadoModel).subscribe(event => {
                if (event.type === HttpEventType.Response) {
                    console.log("With Parsed JSON :", event.body);
                    if (event.body['resultado'] === false) {
                        this.dialogo.open(DialogoconfirmacionComponent, {
                            data: `Ocurrio un error en el servicio `,
                        });
                        console.log("error");
                    } else {
                        this.snackBar.open('Registro Tomado', undefined, {
                            duration: 1500,
                        });
                        //Envia a mis evaluaciones
                        //   this.router.navigate(['/accesoAdmin/AutorizarFuna/']);
                        // this.formListar.reset();
                    }
                }
            })
        } else {
            this.dialogo.open(DialogoconfirmacionComponent, {
                data: `Por favor complete todos los campos`,
            });
        }
    }


    DesbloquearRegistro() {
        // console.log("Esta Ejecutando desbloquear registro");
        // console.log("Desbloquiando obf:" + this.empleadoModel)
        this.empleadoModel.estado = "1";
        this.empleadoModel.Observacion = this.observacion;
        this.empleadoModel.idEvaluacion = this.idEvaluacion;
        this.empleadoModel.fechaingreso = "";
        this.empleadoModel.fechatermino = "";
        this.empleadoModel.idarticulo = "";
        this.empleadoModel.idinciso = "";
        this.empleadoModel.nombreempleado = "";
        this.empleadoModel.nombreempresa = "";
        this.empleadoModel.recomienda = "";
        this.empleadoModel.rutempleado = "";
        this.empleadoModel.rutempresa = "";

        console.log("id" + this.idEvaluacion);
        console.log("estado" + this.empleadoModel.estado);
        console.log("obs:" + this.observacion);

        console.log("despues de valores");
        console.log(this.empleadoModel);
        console.log("Se desbloqueo" + this.empleadoModel.estado);

        if (this.operacion === "2") {
            // console.log("Empezo a actualizarlo a 1");
            this.service.ActualizarEstadoEvaluacion(this.empleadoModel).subscribe(event => {
                if (event.type === HttpEventType.Response) {
                    console.log("With Parsed JSON :", event.body);
                    if (event.body['resultado'] === false) {
                        this.dialogo.open(DialogoconfirmacionComponent, {
                            data: `Ocurrio un error en el servicio `,
                        });
                        console.log("error");
                    } else {
                        this.snackBar.open('Registro desbloqueado', undefined, {
                            duration: 1500,
                        });
                        //Envia a mis evaluaciones
                        //   this.router.navigate(['/accesoAdmin/AutorizarFuna/']);
                        // this.formListar.reset();
                    }
                }

            })
        } else {
            this.dialogo.open(DialogoconfirmacionComponent, {
                data: `Por favor complete todos los campos`,
            });
        }
    }

    //Vuelve estado a 1
    Cancelar() {
        // console.log("paso por cancelareeeee");
        // console.log("estado:" + this.empleadoModel.estado);
        // console.log("operacion 1222:" + this.operacion);

        if (this.operacion === "3") {
            this.dialogRef.close();

        } else {
            // console.log("paso por cancelar 111");
            // console.log("valor tomado");
            // console.log(this.empleadoModel);
            if (this.operacion === "2") {
                this.empleadoModel.estado = "1";
                this.btnEjecutar = "Cancelar";
                this.empleadoModel.Observacion = this.observacion;
                this.service.ActualizarEstadoEvaluacion(this.empleadoModel).subscribe(event => {
                    if (event.type === HttpEventType.Response) {
                        console.log("With Parsed JSON :", event.body);
                        if (event.body['resultado'] === false) {
                            this.dialogo.open(DialogoconfirmacionComponent, {
                                data: `Ocurrio un error en el servicio `,
                            });
                            console.log("error");
                        } else {
                            this.snackBar.open(this.mensaje, undefined, {
                                duration: 1500,
                            });

                            // console.log("Estabelce boton cancelar");

                            this.dialogRef.close();
                            //Envia a mis evaluaciones
                            // this.router.navigate(['/accesoAdmin/AutorizarFuna/']);
                            //this.formListar.reset();
                        }
                    }
                })
            } else {

                if (this.origen === "autorizarfuna") {
                    this.dialogo.open(DialogoconfirmacionComponent, {
                        data: `Desdea Cerrar sin Autorizar`,
                    });
                }
                else {
                    this.dialogRef.close();
                }
            }
        }
        //Debe Forzar Recarga formulario Autorización, para actualizar data
        //  this.router.navigate(['/accesoAdmin/AutorizarFuna/', datoenviado]);  
        // th
    }


    imprimirLista(codImprimir: string) {
        const DATA = document.getElementById('imprimir_' + codImprimir);
        const doc = new jsPDF('l', 'pt', 'letter');
        const options = {
            background: 'white',
            scale: 3,
            fontSize: 15
        };
        html2canvas(DATA, options).then((canvas) => {

            const img = canvas.toDataURL('image/PNG');

            // Add image Canvas to PDF
            const bufferX = 15;
            const bufferY = 15;
            const imgProps = (doc as any).getImageProperties(img);
            const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');

            return doc;
        }).then((docResult) => {
            // doc.autoPrint();
            // doc.output('dataurlnewwindow', { filename: 'comprobante.pdf' });
            docResult.save(`${new Date().toISOString()}_tutorial.pdf`);
        });
    }


}
