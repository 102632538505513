<section id="login">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 mx-auto">
        <div class="wrapper fadeInDown">
          <div id="formContent">
            <h2>Reddinf - Empresa</h2>
            <div class="fadeIn first">
              <img width="100" height="100" src="https://image.flaticon.com/icons/svg/2943/2943270.svg" class="loaded">
            </div>
            <form [formGroup]="loginForm" (ngSubmit)="tryLogin(loginForm)" autocomplete="off">
              <div class="form-group">
                <input type="email" id="login" formControlName="email" class="form-control fadeIn second third" name="login"
                  placeholder="Usuario">
              </div>
              <div class="form-group">
                <input type="password" id="password" formControlName="password" class="form-control fadeIn third"
                  name="login" placeholder="Contraseña">
                <label class="error">{{errorMessage}}</label>
              </div>
              <input type="submit" class="fadeIn fourth" value="Ingresar">
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>