import { Globals } from './../../../globals';
import { ApiService } from './../../../services/login/api.service';
import { PubempleosService } from '../../../services/admin/pubempleos/pubempleos.service';
import { Lparametros } from '../../../core/parametros/parametros';
import { PostEmpleos, ListEmpleos } from '../../../core/admin/empleos';
import { Lregiones, Lcomuna } from '../../../core/parametros/regiones.model';
import { LisregionesService } from '../../../services/parametros/lisregiones.service';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { faCoffee, faTrash, faTrashAlt, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { TitulosService } from './../../../services/parametros/titulos.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogoconfirmacionComponent } from "../../../dialogoconfirmacion/dialogoconfirmacion.component";


import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-gestionar-empleo',
  templateUrl: './gestionar-empleo.component.html',
  styleUrls: ['./gestionar-empleo.component.css']
})
export class GestionarEmpleoComponent implements OnInit {

  dataSource = null;

  titulo: string;
  imagenTitulo: string;
  curso: string;
  formGestionaEmpleos: FormGroup;
  muestraTabla: string;

  minDate = new Date();

  ocultarColumna: string;
  
  faTrashAlt = faTrashAlt;
  faPencilAlt = faPencilAlt;

  iconoListar: string;
  grupoParametros: number;

  listarRegistro: boolean;
  mostrarFormEmpleo: boolean;

  public regionesModel: Lregiones[] = [new Lregiones(0, "prueba")];
  public comunaModel: Lcomuna[] = [new Lcomuna(0, '')];
  public parametroModel: Lparametros[] = [new Lparametros(0, '', 0)];
  public parametroTipoContrato: Lparametros[] = [new Lparametros(0, '', 0)];
  public parametroTurnos: Lparametros[] = [new Lparametros(0, '', 0)];

  public empleosModel: PostEmpleos = new PostEmpleos('', '', '', '', '', '', '', '', '', '', '', '', '', '','');
  public listadoEmpleo: ListEmpleos[] = [new ListEmpleos('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '','')];

  editarItem: any = {
    idEmpleo: '',
    nombre: 'CLAUDIO'

  }

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private regionService: LisregionesService,
    private pubEmpleosService: PubempleosService,
    public apiservice: ApiService,
    public global: Globals,
    private ruta: ActivatedRoute,
    private snackBar: MatSnackBar,
    private dialogo: MatDialog,
    private nombreTituloService: TitulosService,
    private datePipe: DatePipe


  ) {
    this.creaFormEmpleo();

  }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit(): void {
    this.minDate.setFullYear(this.minDate.getFullYear());

    this.titulo = "Gestión Empleo";
    this.imagenTitulo = "https://pyme.emol.com/wp-content/uploads/2020/06/apoyo-al-empleo.jpg";
    this.curso = "OS-10";
    this.obtenerRegiones();
    this.obtenerEduacacion();
    this.obtenerTipoContrato();
    this.obtenerTurnos();

    this.seteaBloques();

    // console.log("PRUEBAAA");
    // console.log(this.global.perfil);

    if (this.global.perfil === "2"){
      this.ocultarColumna = "none";
    }

  }

  private emailPattern: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  creaFormEmpleo() {
    this.formGestionaEmpleos = this.fb.group({
      cargoEmpleo: ['', Validators.required],
      profesion: ['', Validators.required],
      vacantes: ['', Validators.required],
      empresa: ['', Validators.required],
      region: ['', Validators.required],
      comuna: ['', Validators.required],
      direccion: ['', Validators.required],
      fecPostulacion: ['', Validators.required],
      jornada: ['', Validators.required],
      sueldo: ['', Validators.required],
      educacion: ['', Validators.required],
      experiencia: ['', Validators.required],
      tipoContrato: ['', Validators.required],
      descEmpleo: ['', Validators.required],
      correoEmpresa: ['', [Validators.required, Validators.pattern(this.emailPattern), Validators.minLength(7)]]
    })
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.formGestionaEmpleos.controls[controlName].hasError(errorName);
  }

  obtenerRegiones() {
    return this.regionService.getRegiones().subscribe((regionesModel: Lregiones[]) => this.regionesModel = regionesModel);
  }

  onChangeComuna(value) {
    return this.regionService.getComunas(value).subscribe((comunaModel: Lcomuna[]) => this.comunaModel = comunaModel);
  }

  obtenerEduacacion() {
    this.grupoParametros = 1;
    return this.regionService.getParametros(this.grupoParametros).subscribe((parametroModel: Lparametros[]) => this.parametroModel = parametroModel);
  }

  obtenerTipoContrato() {
    this.grupoParametros = 2;
    return this.regionService.getParametros(this.grupoParametros).subscribe((parametroTipoContrato: Lparametros[]) => this.parametroTipoContrato = parametroTipoContrato);
  }

  obtenerTurnos() {
    this.grupoParametros = 3;
    return this.regionService.getParametros(this.grupoParametros).subscribe((parametroTurnos: Lparametros[]) => this.parametroTurnos = parametroTurnos);
  }



  onSubmit() {
    console.log("onsubmit");
    console.log(this.empleosModel);
    this.empleosModel.fecPostulacion = this.datePipe.transform(this.empleosModel.fecPostulacion,"yyyy-MM-dd");
    
    this.global.perfil = this.apiservice.getPerfil();

    if (this.formGestionaEmpleos.valid) {
      this.pubEmpleosService.addEmpleos(this.empleosModel).subscribe(() => {

    
        if (this.global.perfil === "1") { 
          this.router.navigate(['/accesoAdmin/GestionarEmpleo/2']);
        }
        if (this.global.perfil === "2") { 
          this.router.navigate(['/accesoEmpresa/GestionarEmpleo/2']);
        }

        this.formGestionaEmpleos.reset();
      })
    } else {
      alert("FAVOR COMPLETAR TODOS LOS CAMPOS ")
    }
  }


  listadoEmpleos() {
    return this.pubEmpleosService.listadoEmpleos().
      subscribe((listadoEmpleo: ListEmpleos[]) => {
        this.listadoEmpleo = listadoEmpleo;
        this.dataSource = new MatTableDataSource<ListEmpleos>(this.listadoEmpleo);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }


  seteaBloques() {
    this.ruta.params.subscribe(params => {
      switch (params['id']) {
        case '1': {
          this.nombreTituloService.nombreTitulos = "Gestionar empleo";
          this.listarRegistro = false;
          this.mostrarFormEmpleo = true;
          this.muestraTabla = 'none';
          break;
        }
        case '2': {
          this.nombreTituloService.nombreTitulos = "Listar Empleo";
          this.listarRegistro = true;
          this.mostrarFormEmpleo = false;
          this.muestraTabla = 'block';
          this.listadoEmpleos();
          break;
        }
        default: {
          this.router.navigate(['/accesoAdmin']);
          break;
        }
      }
    })
  }


  editarEmpleo(editPubli) {
    this.editarItem = editPubli;
    this.editarItem
  }


  eliminarEmpleo(Item) {

    this.dialogo
      .open(DialogoconfirmacionComponent, {
        data: `¿Realmente quieres eliminar el empleo N° ` + Item + `?`
      })
      .afterClosed()
      .subscribe((confirmado: Boolean) => {
        if (!confirmado) return;
        this.pubEmpleosService
          .deleteEmpleo(Item)
          .subscribe(() => {
            this.snackBar.open('Empleo eliminado ', Item, {
              duration: 3000,
            });
            this.listadoEmpleos();
          });
      });
  }

}


