<!-- <form (ngSubmit)="onSubmit()" [formGroup]="formConsultaEmp"> -->

<div class="container-fluid">
  <div class="row">
    <div class="col-lg-8 col-md-8 col-sm-10 mx-auto">
      <div class="form-group">
        <div class="card card0">
          <div class="card-header">
            <label for="lblFiltro">Buscar Evaluados</label>
          </div>
          <div class="col-md-9 mx-auto">
            <div class="card-body">
              <div class="form-row">
                <!-- <div class="form-group col-md-3 ">
                  <label for="lblJornada">Traer Todos</label>
                  <div>
                    <label for="lblNo" ngClass="labelBeforeSlide">NO</label>
                    <mat-slide-toggle (change)="onChange($event.checked)">SI
                    </mat-slide-toggle>
                  </div>
                </div> -->
                <!-- </div>
                  <div class="form-row"> -->
                <div class="form-group col-md-6 ">
                  <div class="input-group mb-0">
                    <mat-form-field class="example-full-width-1">
                      <mat-label>Ingrese Rut a Consultar</mat-label>
                      <input matInput placeholder="12.345.678-0" id="rutConsultado" name="rutConsultado"
                        [(ngModel)]="rutConsultado" [disabled]='disableTextbox'>
                      <button mat-button matSuffix (click)="obtenerConsultado()">
                        <mat-icon>search</mat-icon>
                      </button>
                    </mat-form-field>
                  </div>
                  <p class="rutError" id="validacion-live"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<!-- </form> -->


<div class="container-fluid py-2 mx-auto">
  <div class="card card0 border-1">
    <div class="col-lg-12 col-md-12 col-sm-12 mx-auto mb-4">
      <div class="card-body">
        <div class="wrapper-editor">
          <h2>Listado de Evaluados en el sistema</h2>
          <table mat-table [dataSource]="dataSource" matSort matSortStart="desc" class="mat-elevation-z8"
            style="width: 100%;">

            <ng-container matColumnDef="ids">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
              <td mat-cell *matCellDef="let consultarempleado; let i = index;"> {{ (paginatorRef.pageIndex *
                paginatorRef.pageSize) + (i + 1) }} </td>
            </ng-container>


            <ng-container matColumnDef="idEvaluacion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>ID Evaluacion</th>
              <td mat-cell *matCellDef="let consultarempleado"> {{consultarempleado.idEvaluacion}} </td>
            </ng-container>

            <!-- <ng-container matColumnDef="rutempleado">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Rut Empleado</th>
              <td mat-cell *matCellDef="let consultarempleado"> {{consultarempleado.rutempleado}} </td>
            </ng-container> -->


            <ng-container matColumnDef="rutempresa">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Rut Empresa</th>
              <td mat-cell *matCellDef="let consultarempleado"> {{consultarempleado.rutempresa}} </td>
            </ng-container>

            <ng-container matColumnDef="nombreempresa">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre Empresa</th>
              <td mat-cell *matCellDef="let consultarempleado"> {{consultarempleado.nombreempresa}} </td>
            </ng-container>

            <ng-container matColumnDef="nombreempleado">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre Empleado</th>
              <td mat-cell *matCellDef="let consultarempleado"> {{consultarempleado.nombreempleado}} </td>
            </ng-container>




            <ng-container matColumnDef="idarticulo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Articulo</th>
              <td mat-cell *matCellDef="let consultarempleado"> {{consultarempleado.idarticulo}} </td>
            </ng-container>



            <ng-container matColumnDef="idinciso">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Inciso</th>
              <td mat-cell *matCellDef="let consultarempleado"> {{consultarempleado.descripcioninciso}} </td>
            </ng-container>


            <ng-container matColumnDef="fechaingreso">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha Ingreso</th>
              <td mat-cell *matCellDef="let consultarempleado"> {{consultarempleado.fechaingreso}} </td>
            </ng-container>

            <ng-container matColumnDef="fechatermino">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha Termino</th>
              <td mat-cell *matCellDef="let consultarempleado"> {{consultarempleado.fechatermino}} </td>
            </ng-container>

            <ng-container matColumnDef="observacion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Observación</th>
              <td mat-cell *matCellDef="let consultarempleado"> {{consultarempleado.Observacion}} </td>
            </ng-container>

            <ng-container matColumnDef="Detalle">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Detalle</th>
              <td mat-cell *matCellDef="let consultarempleado">
                <button (click)="verDetalle(consultarempleado)" mat-icon-button>
                  {{btnEjecutar}}
                  <mat-icon>launch</mat-icon>
                </button>
              </td>
            </ng-container>



            <tr mat-header-row *matHeaderRowDef="['ids', 'rutempresa', 'nombreempresa', 'nombreempleado', 'idarticulo','idinciso', 'fechaingreso','fechatermino',
                  'observacion','Detalle']"></tr>
            <tr mat-row *matRowDef="let fila; columns: ['ids', 'rutempresa', 'nombreempresa', 'nombreempleado', 'idarticulo','idinciso','fechaingreso','fechatermino',
                   'observacion','Detalle'];"></tr>
          </table>

          <div *ngIf="empleadosModel.length === 0">NO SE ENCONTRARON REGISTROS</div>

          <mat-paginator #paginatorRef [pageSizeOptions]="[5, 10, 15, 20]" showFirstLastButtons></mat-paginator>

        </div>

          

      </div>
    </div>
  </div>
</div>


<!-- </form> -->