<!-- <form [formGroup]="formMisEvaluaciones"> -->
<div class="container-fluid">
    <div class="row">
        <div class="container-fluid py-2 mx-auto">
            <div class="card card0 border-1">
                <div class="col-lg-11 col-md-11 col-sm-11 mx-auto mb-4">
                    <h2 class="mb-1">Mis Evaluaciones</h2>
                    <p class="mb-4 text-sm"></p>
                    <h2>Listado de Mis evaluados</h2>
                    <table mat-table [dataSource]="dataSource" matSort matSortStart="asc" class="mat-elevation-z8">

                        <ng-container matColumnDef="selected">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                <mat-checkbox (change)="$event ? masterToggle() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                                </mat-checkbox>
                            </th>

                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(row) : null;isAllSelected()"
                                    [checked]="selection.isSelected(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="ids">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
                            <td mat-cell *matCellDef="let consultarempleado; let i = index;"> {{
                                (paginatorRef.pageIndex *
                                paginatorRef.pageSize) + (i + 1) }} </td>
                        </ng-container>

                        <ng-container matColumnDef="idEvaluacion">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>ID Evaluacion</th>
                            <td mat-cell *matCellDef="let consultarempleado"> {{consultarempleado.idEvaluacion}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="rutempleado">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Rut Empleado</th>
                            <td mat-cell *matCellDef="let consultarempleado"> {{consultarempleado.rutempleado}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="nombreempleado">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre Empleado</th>
                            <td mat-cell *matCellDef="let consultarempleado">
                                {{consultarempleado.nombreempleado}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="nombrearticulo">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Articulo</th>
                            <td mat-cell *matCellDef="let consultarempleado">
                                {{consultarempleado.nombrearticulo}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="nombreincisocorto">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Inciso</th>
                            <td mat-cell *matCellDef="let consultarempleado">
                                {{consultarempleado.nombreincisocorto}} </td>
                        </ng-container>
                        <ng-container matColumnDef="fechaingreso">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha Ingreso</th>
                            <td mat-cell *matCellDef="let consultarempleado"> {{consultarempleado.fechaingreso}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="fechatermino">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha Termino</th>
                            <td mat-cell *matCellDef="let consultarempleado"> {{consultarempleado.fechatermino}}
                            </td>
                        </ng-container>

                         <ng-container matColumnDef="editar">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Editar</th>
                            <td mat-cell *matCellDef="let consultarempleado">
                                <a mat-flat-button color="accent" mat-icon-button *ngIf="consultarempleado.estado == 1"
                                    [routerLink]="['/accesoEmpresa/ReferirEmpleado', consultarempleado.idEvaluacion]">
                                    <mat-icon color="primary">edit</mat-icon>
                                </a>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="detalle">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Detalle</th>
                            <td mat-cell *matCellDef="let consultarempleado">
                                <button mat-raised-button color="primary"
                                    (click)="verDetalleEvaluacion(consultarempleado)" mat-icon-button>
                                    <mat-icon>launch</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="eliminar">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Eliminar</th>
                            <td mat-cell *matCellDef="let consultarempleado">
                                <button mat-raised-button color="warn" *ngIf="consultarempleado.estado == 1"
                                    (click)="eliminarEvaluacion(consultarempleado.idEvaluacion)" mat-icon-button>
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="['selected','idEvaluacion','rutempleado', 'nombreempleado', 'nombrearticulo','nombreincisocorto', 'fechaingreso','fechatermino','editar',
                    'detalle', 'eliminar']"></tr>
                        <tr mat-row *matRowDef="let fila; columns: ['selected','idEvaluacion','rutempleado', 'nombreempleado', 'nombrearticulo','nombreincisocorto','fechaingreso','fechatermino',
                            'editar','detalle', 'eliminar'];" (click)="selection.toggle(row)"></tr>
                    </table>
                    <mat-paginator #paginatorRef [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons>
                    </mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- </form> -->

<div>
    <button mat-raised-button (click)="logSelection()">Imprimir Selección</button>
</div>