<section id="postula">
<div class="col-lg-12 col-md-10 col-sm-10 mx-auto mb-4">
    <section id="team" class="pb-5">
       <div class="container">
          <h5 class="section-title h1">Selecciona tu perfil</h5>
          <div class="row">
             <!-- Team member -->
             <div class="col-xs-12 col-sm-6 col-md-4">
                <div class="image-flip" >
                   <div class="mainflip flip-0">
                      <div class="frontside">
                         <div class="card">
                            <div class="card-body text-center">
                               <p><img class="img-fluid" src="http://www.diarioeldia.cl/sites/default/files/styles/flexslider_full/public/092019/ayer_a_nivel_nacional.jpg?itok=3eUNFSuZ" alt="card image"></p>
                               <h4 class="card-title">Guardia de Seguridad</h4>
                               <p class="card-text">Para postular, como guardia de Seguridad Ingrese Aqui</p>
                               
                            </div>
                         </div>
                      </div>
                      <div class="backside">
                         <div class="card">
                            <div class="card-body text-center mt-4">
                               <h4 class="card-title">Postular a Guardia</h4>
                               <p class="card-text">Para postular, como guardia ingrese aqui</p>
                               <button class="btn btn-primary" [routerLink]="['/accesoPostulante/guardias/1']">Ingresa tu CV</button>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
             <div class="col-xs-12 col-sm-6 col-md-4">
                <div class="image-flip" >
                   <div class="mainflip flip-0">
                      <div class="frontside">
                         <div class="card">
                            <div class="card-body text-center">
                               <p><img class=" img-fluid" src="https://curso-os10.cl/wp-content/uploads/2019/01/supervisor-de-seguridad.png" alt="card image"></p>
                               <h4 class="card-title">Supervisor de Seguridad</h4>
                               <p class="card-text">Para postular, como guardia a Supervidor de Seguridad Ingrese Aqui</p>
                              
                            </div>
                         </div>
                      </div>
                      <div class="backside">
                         <div class="card">
                            <div class="card-body text-center mt-4">
                               <h4 class="card-title">Postular a Supervisor Guardia</h4>
                               <p class="card-text">Para postular, como Supervidor de Seguridad ingrese aqui</p>
                               <button class="btn btn-primary"  [routerLink]="['/accesoPostulante/guardias/2']" >Ingresa tu CV</button>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
             <div class="col-xs-12 col-sm-6 col-md-4">
                <div class="image-flip" >
                   <div class="mainflip flip-0">
                      <div class="frontside">
                         <div class="card">
                            <div class="card-body text-center">
                               <p><img class=" img-fluid" src="https://www.infogate.cl/wp-content/uploads/2019/04/empresas090401.jpg" alt="card image"></p>
                               <h4 class="card-title">Conserje</h4>
                               <p class="card-text">Para postular, como Conserje Ingrese Aqui</p>
                               
                            </div>
                         </div>
                      </div>
                      <div class="backside">
                         <div class="card">
                            <div class="card-body text-center mt-4">
                               <h4 class="card-title">Postular a conserje</h4>
                               <p class="card-text">Para postular, como Conserje ingrese aqui</p>
                               <button class="btn btn-primary" [routerLink]="['/accesoPostulante/guardias/3']">Ingresa tu CV</button>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>



             <div class="col-xs-12 col-sm-6 col-md-4">
               <div class="image-flip" >
                  <div class="mainflip flip-0">
                     <div class="frontside">
                        <div class="card">
                           <div class="card-body text-center">
                              <p><img class=" img-fluid" src="https://www.infogate.cl/wp-content/uploads/2019/04/empresas090401.jpg" alt="card image"></p>
                              <h4 class="card-title">Personal de Aseo</h4>
                              <p class="card-text">Para postular, como personal de aseo Ingrese Aqui</p>
                              
                           </div>
                        </div>
                     </div>
                     <div class="backside">
                        <div class="card">
                           <div class="card-body text-center mt-4">
                              <h4 class="card-title">Postular a Personal de Aseo</h4>
                              <p class="card-text">Para postular,  personal de aseo  ingrese aqui</p>
                              <button class="btn btn-primary" [routerLink]="['/accesoPostulante/guardias/4']">Ingresa tu CV</button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
          </div>
       </div>
    </section>
 </div>
</section>