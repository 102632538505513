<div>
   <button class="btn col col-lg-4 btn-danger btn-block ml-4" (click)="imprimirLista()">Descargar PDF</button>
</div>
<div class="container py-1 mx-auto">
   <div class="card card0 border-0">
      <div class="row d-flex">
         <div class="container-fluid">
            <div class="main-body">
               <div class="row gutters-sm">
                  <div class="col-md-12 ml-1 mb-1 mr-1">
                     <div id="htmlData">
                        <div class="card mb-1 card1">
                           <div class="form-row mt-4 my-1 mb-1">
                              <div class="form-group col-md-12 ">
                                 <div id="tituloEmpleo">
                                    <strong id="tituloEmpleo">{{empleosModel.cargoEmpleo}} ({{empleosModel.vacantes}}
                                       vacantes)</strong> {{empleosModel.profesion}}
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div class="card mb-1">
                           <div class="card-header card1">
                              <h6>DATOS CONTACTO</h6>
                           </div>
                           <div class="card-body">
                              <div class="form-row">
                                 <div class="form-group col-md-6 mt-1 my-1">
                                    <strong>EMPRESA :</strong>
                                    <br>
                                    {{empleosModel.empresa}}
                                 </div>
                                 <div class="form-group col-md-6 mt-1 my-1">
                                    <strong>COMUNA :</strong>
                                    <br>
                                    {{empleosModel.comuna_desc}}
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div class="card mb-1">
                           <div class="card-header card1">
                              <h6>DESCRIPCIÓN</h6>
                           </div>
                           <div class="card-body">
                              <div class="form-row">
                                 <div class="form-group col-md-12 mt-1 my-1">
                                    {{empleosModel.descEmpleo}}
                                 </div>
                                 <br>
                                 <br>
                                 <div class="form-group col-md-3 col-lg-3 col-sm-3 mt-1 my-1">
                                    <span>
                                       <mat-icon>location_on</mat-icon> {{empleosModel.comuna_desc}},
                                       {{empleosModel.region_desc}}
                                    </span>
                                 </div>

                                 <div class="form-group col-md-3 col-lg-3 col-sm-3 mt-1 my-1">
                                    <span>
                                       <mat-icon>event_available</mat-icon>{{empleosModel.fecPostulacion}}
                                    </span>
                                 </div>

                                 <div class="form-group col-md-3 col-lg-3 col-sm-3 mt-1 my-1">
                                    <span>
                                       <mat-icon>attach_money</mat-icon>{{empleosModel.sueldo |
                                       currency:'CLP':'symbol'}}
                                    </span>
                                 </div>

                                 <div class="form-group col-md-3 col-lg-3 col-sm-3 mt-1 my-1">
                                    <span>
                                       <mat-icon>access_time</mat-icon>{{empleosModel.jornada_desc}}
                                    </span>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div class="card mb-1">
                           <div class="card-header card1">
                              <h6>REQUISITOS SOLICITADOS</h6>
                           </div>
                           <div class="card-body">

                              <div class="form-row">
                                 <div class="form-group col-md-6 mt-1 my-1">
                                    <strong>Nivel educacional:</strong>
                                    <br>
                                    {{empleosModel.educacion_desc}}
                                 </div>
                                 <div class="form-group col-md-6 mt-1 my-1">
                                    <strong>Experiencia:</strong>
                                    <br>
                                    {{empleosModel.experiencia}} años
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div class="card mb-1">
                           <div class="card-header card1">
                              <h6>CARACTERISTICAS</h6>
                           </div>
                           <div class="card-body">
                              <div class="form-row">
                                 <div class="form-group col-md-6 mt-1 my-1">
                                    <strong>Tipo de contrato:</strong>
                                    <br>
                                    {{empleosModel.tipoContrato_desc}}
                                 </div>
                                 <div class="form-group col-md-6 mt-1 my-1">
                                    <strong>Profesión:</strong>
                                    <br>
                                    {{empleosModel.profesion}}
                                    {{empleosModel.correoEmpresa}}
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <br>
                     <div class="form-row">
                        <div class="form-group col-md-6 text-center">
                           <div class="col-md-6">
                              <button class="btn btn-outline-primary" (click)="volver()">
                                 Volver</button>
                           </div>
                        </div>
                        <div class="form-group col-md-6 text-center">
                           <button class="btn btn-outline-primary"
                           (click)="postularEmpleo(empleosModel)">Postular</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>