<div class="formPub" *ngIf="mostrarFormEmpleo">
    <form [formGroup]="formGestionaEmpleos" (ngSubmit)="onSubmit()">
        <section id="guardiaPostula">
            <div class="container py-2 mx-auto">
                <div class="card card0 border-0">
                    <div class="row d-flex">
                        <div class="container">
                            <div class="main-body">
                                <div class="row gutters-sm">
                                    <div class="col-md-3 mt-2 ml-4 mr-2 mb-2">
                                        <div class="form-group card mt-1">
                                            <div class="card-body">
                                                <div class="d-flex flex-column align-items-center text-center">
                                                    <img src="{{imagenTitulo}}" alt="Admin" class="rounded-circle"
                                                        width="180" />
                                                    <div class="mt-2">
                                                        <h4>{{titulo}}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group card mt-4 mx-auto">
                                            <div class="card-header">Descripción
                                                <span class="float-right" ngbTooltip="Ayuda">
                                                    <svg width="1em" height="1em" viewBox="0 0 16 16"
                                                        class="bi bi-info-circle" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd"
                                                            d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                        <path
                                                            d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z" />
                                                        <circle cx="8" cy="4.5" r="1" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <p class="text-justify mx-auto" style="width: 90%;">Lorem ipsum dolor sit
                                                amet
                                                consectetur adipisicing elit. Facilis perspiciatis nam quod soluta odit
                                                voluptatibus dicta a ipsam voluptates, eos at iure sunt tempora debitis
                                                dignissimos! Quidem, non. Beatae doloribus aperiam ex at eius obcaecati
                                                soluta voluptas culpa quidem dolor, dolorem sed voluptatum in magnam!
                                                Sequi
                                                autem animi non laudantium.</p>
                                        </div>
                                    </div>

                                    <div class="col-md-8 mt-3 ml-3 mb-2 mr-2">
                                        <div class="card mb-1">
                                            <div class="card-body">
                                                <div class="form-row">
                                                    <div class="form-group col-md-12">

                                                        <mat-form-field appearance="outline"
                                                            class="example-full-width-1">
                                                            <mat-label>Cargo Empleo</mat-label>
                                                            <input matInput id="cargoEmpleo"
                                                                formControlName="cargoEmpleo"
                                                                [(ngModel)]="empleosModel.cargoEmpleo"
                                                                (ngModelChange)="empleosModel.cargoEmpleo = $event.toUpperCase()" />
                                                        </mat-form-field>
                                                    </div>
                                                </div>


                                                <div class="form-row">
                                                    <div class="form-group col-md-12">
                                                        <mat-form-field appearance="outline"
                                                            class="example-full-width-1">
                                                            <mat-label>Profesión u Oficio</mat-label>
                                                            <input matInput id="profesion" formControlName="profesion"
                                                                [(ngModel)]="empleosModel.profesion" />
                                                        </mat-form-field>
                                                    </div>

                                                </div>
                                                <div class="form-row">
                                                    <div class="form-group col-md-3">

                                                        <mat-form-field appearance="outline"
                                                            class="example-full-width-1">
                                                            <mat-label>Vacantes</mat-label>
                                                            <input matInput id="vacantes" formControlName="vacantes"
                                                                [(ngModel)]="empleosModel.vacantes" />
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="form-group col-md-9">
                                                        <mat-form-field appearance="outline"
                                                            class="example-full-width-1">
                                                            <mat-label>Empresa</mat-label>

                                                            <input matInput id="empresa" formControlName="empresa"
                                                                [(ngModel)]="empleosModel.empresa" />
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="form-row">
                                                    <div class="form-group col-md-12">
                                                        <mat-form-field appearance="outline"
                                                            class="example-full-width-1">
                                                            <mat-label>Email Empresa</mat-label>
                                                            <input matInput id="correoEmpresa" formControlName="correoEmpresa"
                                                                [(ngModel)]="empleosModel.correoEmpresa" />
                                                                <mat-error *ngIf="checkError('correoEmpresa', 'pattern')">Formato de correo invalido</mat-error>
                                                                <mat-error *ngIf="checkError('correoEmpresa', 'minLength')">Correo debe tener más de 5 caracteres</mat-error> 
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <br>

                                                <div class="form-row">
                                                    <div class="form-group col-md-6">
                                                        <mat-form-field appearance="outline"
                                                            class="example-full-width-1">
                                                            <mat-label>Región</mat-label>
                                                            <mat-select name="regiones" id="region" ng-model="regiones"
                                                                (valueChange)="onChangeComuna($event)"
                                                                formControlName="region"
                                                                [(ngModel)]="empleosModel.region">
                                                                <mat-option value="">Seleccione Región</mat-option>
                                                                <mat-option *ngFor="let regiones of regionesModel"
                                                                    value="{{regiones.REGION_ID}}">
                                                                    {{regiones.REGION_NOMBRE
                                                                    | uppercase}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>

                                                    <div class="form-group col-md-6">
                                                        <mat-form-field appearance="outline"
                                                            class="example-full-width-1">
                                                            <mat-label>Comuna</mat-label>
                                                            <mat-select name="comuna" id="comuna" ng-model="comuna"
                                                                formControlName="comuna"
                                                                [(ngModel)]="empleosModel.comuna">
                                                                <mat-option value="">Seleccione Comuna</mat-option>
                                                                <mat-option *ngFor="let comuna of comunaModel"
                                                                    value="{{comuna.COMUNA_ID}}">{{comuna.COMUNA_NOMBRE
                                                                    |
                                                                    uppercase}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="form-row">
                                                    <div class="form-group col-md-12">
                                                        <mat-form-field appearance="outline"
                                                            class="example-full-width-1">
                                                            <mat-label>Dirección</mat-label>
                                                            <input matInput id="direccion" formControlName="direccion"
                                                                [(ngModel)]="empleosModel.direccion" />
                                                        </mat-form-field>
                                                    </div>
                                                </div>

                                                <div class="form-row">
                                                    <div class="form-group col-md-4">
                                                        <mat-form-field appearance="outline"
                                                            class="example-full-width-1">
                                                            <mat-label>Fecha Postulación</mat-label>
                                                            <input matInput [min]="minDate" [matDatepicker]="picker1"
                                                                id="fecPostulacion" formControlName="fecPostulacion"
                                                                [(ngModel)]="empleosModel.fecPostulacion" />
                                                            <mat-datepicker-toggle matSuffix [for]="picker1">
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #picker1></mat-datepicker>

                                                        </mat-form-field>
                                                    </div>
                                                    <div class="form-group col-md-4">
                                                        <mat-form-field appearance="outline"
                                                            class="example-full-width-1">
                                                            <mat-label>Jornada</mat-label>
                                                            <mat-select formControlName="jornada" id="jornada"
                                                                [(ngModel)]="empleosModel.jornada">
                                                                <mat-option value="" selected>Seleccione Turno
                                                                </mat-option>
                                                                <mat-option *ngFor="let turnos of parametroTurnos"
                                                                    value="{{turnos.pra_val}}">{{turnos.pra_des}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>

                                                    <div class="form-group col-md-4">
                                                        <mat-form-field appearance="outline"
                                                            class="example-full-width-1">
                                                            <mat-label>Sueldo</mat-label>
                                                            <input matInput id="sueldo" formControlName="sueldo"
                                                                [(ngModel)]="empleosModel.sueldo" />
                                                        </mat-form-field>
                                                    </div>
                                                </div>

                                                <div class="form-row">
                                                    <div class="form-group col-md-4">
                                                        <mat-form-field appearance="outline"
                                                            class="example-full-width-1">
                                                            <mat-label>Nivel Educacional</mat-label>
                                                            <mat-select formControlName="educacion"
                                                                [(ngModel)]="empleosModel.educacion">
                                                                <mat-option value="" selected>Seleccione Nivel
                                                                    Educacional
                                                                </mat-option>
                                                                <mat-option *ngFor="let nivelEdu of parametroModel"
                                                                    value="{{nivelEdu.pra_val}}">{{nivelEdu.pra_des}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="form-group col-md-4">

                                                        <mat-form-field appearance="outline"
                                                            class="example-full-width-1">
                                                            <mat-label>Experiencia (años)</mat-label>
                                                            <input matInput id="experiencia"
                                                                formControlName="experiencia"
                                                                [(ngModel)]="empleosModel.experiencia" />
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="form-group col-md-4">

                                                        <mat-form-field appearance="outline"
                                                            class="example-full-width-1">
                                                            <mat-label>Tipo Contrato</mat-label>
                                                            <mat-select formControlName="tipoContrato" id="tipoContrato"
                                                                [(ngModel)]="empleosModel.tipoContrato">
                                                                <mat-option value="" selected>Seleccione Tipo Contrato
                                                                </mat-option>
                                                                <mat-option
                                                                    *ngFor="let tipoContrato of parametroTipoContrato"
                                                                    value="{{tipoContrato.pra_val}}">
                                                                    {{tipoContrato.pra_des}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <mat-form-field appearance="outline" class="example-full-width-1">
                                                        <mat-label>Descripción de
                                                            Empleo</mat-label>
                                                        <textarea matInput id="descEmpleo" rows="2"
                                                            formControlName="descEmpleo"
                                                            [(ngModel)]="empleosModel.descEmpleo"></textarea>
                                                    </mat-form-field>
                                                </div>

                                                <div class="form-row">
                                                    <div class="form-group col-md-6 text-center">
                                                        <button type="submit"
                                                            class="btn btn-outline-primary">Enviar</button>
                                                    </div>
                                                    <div class="form-group col-md-6 text-center">
                                                        <button class="btn btn-outline-primary">Limpiar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </form>
</div>

<div [ngStyle]="{'display': muestraTabla }">
    <div class="container-fluid py-2 mx-auto">
        <div class="card card0 border-1">
            <div class="col-lg-11 col-md-11 col-sm-11 mx-auto mb-4">
                <div class="card-body">
                    <div class="wrapper-editor">
                        <!-- <h2 class="mb-1">Listado de Empleos</h2> -->
                        <!-- <p class="mb-4 text-sm"></p> -->
                        <!-- <h2>Listado de Mis evaluados</h2> -->
                        <table mat-table [dataSource]="dataSource" matSort matSortStart="desc" class="mat-elevation-z8">

                            <ng-container matColumnDef="ids">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
                                <td mat-cell *matCellDef="let listadoEmpleo; let i = index;"> {{
                                    (paginatorRef.pageIndex *
                                    paginatorRef.pageSize) + (i + 1) }} </td>
                            </ng-container>

                            <ng-container matColumnDef="cargoEmpleo">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Cargo Empleo</th>
                                <td mat-cell *matCellDef="let listadoEmpleo"> {{listadoEmpleo.cargoEmpleo}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="profesion">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Profesión</th>
                                <td mat-cell *matCellDef="let listadoEmpleo"> {{listadoEmpleo.profesion}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="empresa">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Empresa</th>
                                <td mat-cell *matCellDef="let listadoEmpleo"> {{listadoEmpleo.empresa}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="tipoContrato">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo Contrato</th>
                                <td mat-cell *matCellDef="let listadoEmpleo"> {{listadoEmpleo.tipoContrato}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="jornada">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Jornada</th>
                                <td mat-cell *matCellDef="let listadoEmpleo"> {{listadoEmpleo.jornada}}
                                </td>
                            </ng-container>

                            <ng-container  matColumnDef="Editar">
                                <th mat-header-cell [style.display]="this.ocultarColumna" *matHeaderCellDef mat-sort-header>Editar</th>
                                <td mat-cell *matCellDef="let listadoEmpleo">
                                    <a [style.display]="this.ocultarColumna" mat-flat-button color="primary" mat-icon-button
                                        [routerLink]="['/accesoAdmin/EditEmpleo', listadoEmpleo.idEmpleo]">
                                        <mat-icon>edit</mat-icon>
                                    </a>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Eliminar">
                                <th mat-header-cell [style.display]="this.ocultarColumna" *matHeaderCellDef mat-sort-header>Eliminar</th>
                                <td mat-cell *matCellDef="let listadoEmpleo">
                                    <button [style.display]="this.ocultarColumna" mat-raised-button color="warn"
                                        (click)="eliminarEmpleo(listadoEmpleo.idEmpleo)" mat-icon-button>
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </td>
                            </ng-container>

                            <!-- <ng-container matColumnDef="test">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Eliminar</th>
                                <td mat-cell *matCellDef="let listadoEmpleo">
                                </td>
                            </ng-container> -->


                            <tr mat-header-row
                                *matHeaderRowDef="['ids','cargoEmpleo','profesion','empresa','tipoContrato','jornada','Editar','Eliminar']">
                            </tr>
                            <tr mat-row
                                *matRowDef="let fila; columns: ['ids','cargoEmpleo','profesion','empresa','tipoContrato','jornada','Editar','Eliminar'];">
                            </tr>
                        </table>
                        <mat-paginator #paginatorRef [pageSizeOptions]="[5, 10, 15, 20]" showFirstLastButtons>
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>