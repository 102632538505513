import { TitulosService } from './../../services/parametros/titulos.service';
import { Router } from '@angular/router';
import { Globals } from './../../globals';
import { Empleo } from '../../core/admin/empleos';
import { PublicidadService } from '../../services/publicidad/publicidad.service';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { faCoffee, faTrash, faTrashAlt, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { ConstantPool } from '@angular/compiler';



declare var $: any;
@Component({
  selector: 'app-portal-empleo',
  templateUrl: './portal-empleo.component.html',
  styleUrls: ['./portal-empleo.component.css']
})
export class PortalEmpleoComponent implements OnInit {

  dataSource = null;
  public empleos: Empleo[] = [];


  faTrashAlt = faTrashAlt;
  faPencilAlt = faPencilAlt;


  title = 'app';

  constructor(
    private publicidadService: PublicidadService,
    public globals: Globals,
    private router: Router,
    private nombreTituloService: TitulosService   
  ) { }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  getRut(rut: string): void {
    console.log("wwwwww" + rut);
  }

  ngOnInit(): void {

    console.log("PORTAL EMPLEOS");
    console.log(this.globals.email);
    this.nombreTituloService.nombreTitulos = "Portal Empleo";
    this.obtenerEmpleos();

  }


  obtenerEmpleos() {
    return this.publicidadService.getPortalEmpleos().subscribe((empleos: Empleo[]) => {
        this.empleos = empleos
        this.dataSource = new MatTableDataSource<Empleo>(this.empleos);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
     });
  }


  // obtenerPublicidad() {
  //   return this.publicidadService.getPublicidad().subscribe((publicidades: ListPublicidad[]) => {
  //     this.publicidades = publicidades
  //     this.dataSource = new MatTableDataSource<ListPublicidad>(this.publicidades);
  //     this.dataSource.paginator = this.paginator;
  //     this.dataSource.sort = this.sort;

  //   });
  // }




  editar(id: string | number) {
    console.log("Llama al numero " + id);
  }


  detalleEmpleo(){
    this.router.navigate(['/detalleEmpleo']);
  }
  
}
