<div id="preloader"></div>
<nav class="header navbar navbar-expand-md navbar-dark bg-dark">
  <div class="container flexbox-center">
    <div class="col-lg-2">
      <a class="navbar-brand" href="#">
        <img src="assets/img/logo.png" alt="logo" />
      </a>
    </div>

    <div class="mr-auto ">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".dual-collapse2"
        aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>

    <div class="mainmenu mr-auto navbar-collapse collapse w-100 text-left order-1 order-md-0 dual-collapse2">

      <ul class="navbar-nav ">
        <!-- <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="/postula">Trabaje
            con nosotros</a>

        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show"
            routerLink="/portal">Empleos</a>
        </li> -->
        <li class="nav-item" *ngIf="!logoutbtn" routerLinkActive="active">
          <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show"
            routerLink="/registroPostulante">Registrarse</a>
        </li>
      </ul>

    </div>



    <button class="navbar-toggler sizeIcon mr-1" data-toggle="collapse2" data-target=".dual-collapse22"
      aria-expanded="false" aria-label="Toggle navigation" (click)="loginResponsive()">
      <!-- <mat-icon class="white-icon">person_outline</mat-icon> -->
    <mat-icon class="white-icon sizeIcon">person_outline</mat-icon>
    </button>

    <div class="mainmenu navbar-collapse collapse2 w-100 order-3 dual-collapse22 mt-2">
      <ul class="navbar-nav ml-auto">
        <div class="flex align-center">
          <div>
            <i class="icon icon--user" data-toggle="collapse2">
              <mat-icon aria-hidden="false" class="white-icon" >person_outline</mat-icon>
            </i>
          </div>
        </div>

        <div class="header_login nav-item">
          <div class="flex">
            <p class="hello_user mt-2" data-toggle="collapse">Hola, {{nombreTituloService.NombreUser}} </p>
            <a class="login-underline" data-toggle="collapse2" *ngIf="perfilAdmin" routerLink="/accesoAdmin">Mi cuenta
            </a>
            <a class="login-underline" data-toggle="collapse2" *ngIf="perfilEmpresa" routerLink="/accesoEmpresa">Mi
              cuenta </a>
            <a class="login-underline" data-toggle="collapse2" *ngIf="perfilPostulante"
              routerLink="/accesoPostulante">Mi cuenta </a>
            <a class="login-underline" data-toggle="collapse2" *ngIf="logoutbtn" title="Cerrar Sesión"
              [routerLink]="['/']" (click)="logout()">Cerrar Sesión</a>

              <a class="login-underline" data-toggle="collapse2" *ngIf="!logoutbtn" title="Iniciar Sesión"
              [routerLink]="['/']" (click)="loginResponsive()">Iniciar Sesión</a>
              
          </div>
        </div>


      </ul>
    </div>

  </div>
</nav>