<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet">

<br>
<br>
<div class="container">
    <div class="row justify-content-center">
        <div class="col-md-10 mx-auto">
            <div class="wrapper fadeInDown">

                <div id="formContent2" class="card-group mb-1">
                    <div class="card p-4">
                        <form [formGroup]="angForm" autocomplete="off">
                            <div class="card-body">
                                <h1>Recuperar Contraseña</h1>

                                <div class="fadeIn first">
                                    <img width="100" height="100"
                                        src="https://image.flaticon.com/icons/svg/2943/2943270.svg" class="loaded">
                                </div>

                                <div class="input-group mb-3 ">
                                    <mat-form-field appearance="outline" class="example-full-width-1">
                                        <mat-label>Email</mat-label>
                                        <!-- <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1"><i
                                                    class="fa fa-user"></i></span>
                                        </div> -->
                                        <input matInput id="email" id="email" name="email" formControlName="email"
                                            placeholder="Ingrese E-Mail" aria-label="Username"
                                            aria-describedby="basic-addon1">
                                        <mat-error *ngIf="checkError('email', 'pattern')">Formato de correo invalido
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-12 mx-auto mb-4">
                                        <button type="button" (click)="postdata(angForm)"
                                            class="btn btn-primary px-4">Recuperar</button>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>


                    <div class="card text-white bg-primary py-5 d-md-down-none" style="width:44%">
                        <div class="card-body text-center">
                            <div>
                                <h2>Registrese</h2>
                                <p>Registre para que tenga acceso a postular a ofertas de empleos y dejar su curriculum
                                </p>
                                <button type="submit" class="btn btn-primary active mt-3"
                                    [routerLink]="['/registroPostulante']">Registrese Ahora!</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>