import { RouterModule, Routes } from '@angular/router';
import { AccesoAdminModule } from './privado/acceso-admin/acceso-admin.module';
import { AccesoEmpresaModule } from './privado/acceso-empresa/acceso-empresa.module';
import { AccesoPostulanteModule } from './privado/acceso-postulante/acceso-postulante.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ApiPostulanteService } from './services/login/apiPostulante.service';
import { ApiService } from './services/login/api.service';
import { Globals } from './globals';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { environment } from '../environments/environment';

import { ConexionService } from './services/conexion.service';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { HashLocationStrategy, LocationStrategy, DatePipe } from '@angular/common';

// publicidad
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// servicio Api
import { ApiuserService } from './services/apiuser.service';
import { HttpClientModule } from '@angular/common/http';
import { LoginPersonaComponent } from './login-persona/login-persona.component';
import { RegistoPostulanteComponent } from './registo-postulante/registo-postulante.component';
import { AuthguardGuard } from './core/authguard.guard';

// Servicios II
import { EquipoService } from './equipo.service';
import { AuthService } from './core/auth.service';
import { UserService } from './core/user.service';

//Mensaje
import { DialogoconfirmacionComponent } from './dialogoconfirmacion/dialogoconfirmacion.component';
//Material
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSortModule } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';


import { DetallemisevaluacionesComponent } from './privado/empresa/detallemisevaluaciones/detallemisevaluaciones.component';
import { CifrarComponent } from './components/cifrar/cifrar.component';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { EncabezadoComponent } from './encabezado/encabezado.component';
import { FooterComponent } from './footer/footer.component';
import { EquipoComponent } from './equipo/equipo.component';
import { NosotrosComponent } from './nosotros/nosotros.component';
import { InicioComponent } from './inicio/inicio.component';
// import { ListaComponent } from './components/lista/lista.component';
// import { ListaAddComponent } from './components/lista-add/lista-add.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// import { UsuarioComponent } from './usuario/usuario.component';
import { PostulaComponent } from './publica/postula/postula.component';
import { PublicidadComponent } from './publica/publicidad/publicidad.component';
import { PortalEmpleoComponent } from './publica/portal-empleo/portal-empleo.component';
import { RutModule } from 'rut-chileno';
import { RecuperarPasswordComponent } from './recuperar-password/recuperar-password.component';
import { ListaevaluacionesempleadoComponent } from './privado/empresa/listaevaluacionesempleado/listaevaluacionesempleado.component';



const routes: Routes = [
  { path: '', component: InicioComponent, pathMatch: 'full' },
  { path: 'loginPersona', component: LoginPersonaComponent },
  { path: 'recuperarPassword', component: RecuperarPasswordComponent },
  { path: 'login', component: LoginComponent },
  { path: 'inicio', component: InicioComponent },
  { path: 'postula', component: PostulaComponent },
  { path: 'nosotros', component: NosotrosComponent, canActivate: [AuthguardGuard] },
  { path: 'publicaaqui', component: PublicidadComponent },
  { path: 'portal', component: PortalEmpleoComponent },
  { path: 'registroPostulante', component: RegistoPostulanteComponent },
  { path: 'cifrar', component: CifrarComponent },
  { path: 'equipo/:id', component: EquipoComponent },
  { path: '**', redirectTo: 'inicio', pathMatch: 'full' }
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    EncabezadoComponent,
    FooterComponent,
    EquipoComponent,
    NosotrosComponent,
    InicioComponent,
    // ListaComponent,
    // ListaAddComponent,
    // UsuarioComponent,
    PostulaComponent,
    PublicidadComponent,
    PortalEmpleoComponent,
    LoginPersonaComponent,
    RegistoPostulanteComponent,
    DialogoconfirmacionComponent,
    DetallemisevaluacionesComponent,
    CifrarComponent,
    RecuperarPasswordComponent,
    ListaevaluacionesempleadoComponent

  ],
  imports: [
    FormsModule,
    FontAwesomeModule, NgbModule,
    BrowserModule,
    RouterModule.forRoot(routes),
    ReactiveFormsModule,
    CarouselModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AccesoEmpresaModule,
    AccesoAdminModule,
    AccesoPostulanteModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    HttpClientModule,
    MatTableModule,
    MatDialogModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatNativeDateModule,
    MatInputModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatGridListModule,
    MatSortModule,
    MatSelectModule,
    RutModule
  ],
  providers: [
    AuthService,
    UserService,
    EquipoService,
    ConexionService,
    ApiuserService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    Globals,
    ApiService,
    ApiPostulanteService,
    DatePipe
  ],
  exports: [
    ReactiveFormsModule,
    RouterModule
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
// ccurin