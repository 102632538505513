
import { Component, OnInit } from '@angular/core';
import { Router, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EncriptarService } from './../services/seguridad/encriptar.service';
import { first } from 'rxjs/operators';
import { Globals } from './../globals';
import { ApiService } from '../services/login/api.service';
import { CifrarComponent } from './../components/cifrar/cifrar.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  errorMessage: string = '';
  

  constructor(
    // public authService: AuthService,
    private router: Router,
    private fb: FormBuilder,

    private dataService: ApiService,
    public globals: Globals,
    public encriptar: EncriptarService

  ) {
    this.createForm();
  }

  ngOnInit(): void {

  }

  createForm() {
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  tryLogin(loginForm) {


    console.log(loginForm);

    
    this.dataService.userlogin(loginForm.value.email, loginForm.value.password)
    .pipe(first())
    .subscribe(
      data => {

        console.log(data);

        // this.globals.perfil = this.dataService.getPerfil();

        if (this.globals.perfil == "3") {
          const redirect = this.dataService.redirectUrl ? this.dataService.redirectUrl : '/accesoPostulante';
          this.router.navigate([redirect]);
        }
        else
        {
          alert("SIN ACCESO")

        }
      },
      error => {
        alert("NOMBRE DE USUARIO O CONTRASEÑA INCORRECTOS");
      });

    // this.authService.doLogin(value)
    //   .then(res => {
    //     this.router.navigate(['/usuario']);
    //   }, err => {
    //     console.log(err);
    //     this.errorMessage = err.message;
    //   })
  }

  
  get email() { return this.loginForm.get('email'); }
  get password() { return this.loginForm.get('password'); }
}
