<!-- <form (ngSubmit)="onSubmit()" [formGroup]="formConsultarEmpleado"> -->
<div class="container-fluid">
  <div class="row">
    <div class="col-lg-10 col-md-10 col-sm-10 mx-auto mb-4">
      <!-- <mat-progress-spinner mode="determinate" value='66' diameter="45"></mat-progress-spinner>
         -->
      <div class="form-group">
        <div class="card card0">
          <div class="card-header">
            <label for="lblFiltro">Filtro Evaluados</label>
          </div>
          <div class="card-body">
            <div class="col-lg-10 col-md-10 col-sm-10 mx-auto">
              <mat-form-field appearance="outline" class="example-full-width-1">
                <mat-label>Seleccione Filtro</mat-label>
                <mat-select name="idarticulo" id="idarticulo" (valueChange)="onChangeFiltro($event)"
                  formControlName="idarticulo">
                  <mat-option value="">Seleccione Filtro</mat-option>
                  <mat-option *ngFor="let filtro of parametroEstado" value="{{filtro.pra_val}}">{{filtro.pra_des |
                    uppercase}}
                  </mat-option>
                </mat-select>
              </mat-form-field>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="container-fluid py-2 mx-auto">
      <div class=" card card0 border-1">
        <div class="col-lg-11 col-md-11 col-sm-11 mx-auto mb-4">

          <h2>Listado de Evaluados en el sistema - {{nombreEtapa}}</h2>
          <table mat-table [dataSource]="dataSource" matSort matSortStart="desc" class="mat-elevation-z8">

            <ng-container matColumnDef="index">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
              <td mat-cell *matCellDef="let consultarempleado; let i = index;"> {{ (paginatorRef.pageIndex *
                paginatorRef.pageSize) + (i + 1) }} </td>
            </ng-container>

            <ng-container matColumnDef="idEvaluacion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>ID Evaluacion</th>
              <td class="change_on_over" mat-cell *matCellDef="let consultarempleado">
                {{consultarempleado.idEvaluacion}} </td>
            </ng-container>
            <ng-container matColumnDef="rutempleado">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Rut Empleado</th>
              <td class="element-row" mat-cell *matCellDef="let consultarempleado"> {{consultarempleado.rutempleado}}
              </td>
            </ng-container>
            <ng-container matColumnDef="nombreempleado">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre Empleado</th>
              <td mat-cell *matCellDef="let consultarempleado"> {{consultarempleado.nombreempleado}} </td>
            </ng-container>
            <ng-container matColumnDef="idarticulo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Articulo</th>
              <td mat-cell *matCellDef="let consultarempleado"> {{consultarempleado.idarticulo}} </td>
            </ng-container>
            <ng-container matColumnDef="idinciso">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Inciso</th>
              <td mat-cell *matCellDef="let consultarempleado"> {{consultarempleado.idinciso}} </td>
            </ng-container>
            <ng-container matColumnDef="fechaingreso">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha Ingreso</th>
              <td mat-cell *matCellDef="let consultarempleado"> {{consultarempleado.fechaingreso}} </td>
            </ng-container>
            <ng-container matColumnDef="fechatermino">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha Termino</th>
              <td mat-cell *matCellDef="let consultarempleado"> {{consultarempleado.fechatermino}} </td>
            </ng-container>


            <ng-container matColumnDef="ver">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>ver Detalle</th>
              <td mat-cell *matCellDef="let consultarempleado">
                <button (click)="AutorizarEvaluacion(consultarempleado)" mat-icon-button>
                  <mat-icon *ngIf="consultarempleado.estado == 1">launch</mat-icon>
                  <mat-icon *ngIf="consultarempleado.estado == 3">check</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="['index','idEvaluacion','rutempleado', 'nombreempleado', 'idarticulo','idinciso', 'fechaingreso','fechatermino',
                    'ver']"></tr>
            <tr mat-row *matRowDef="let fila; columns: ['index','idEvaluacion','rutempleado', 'nombreempleado', 'idarticulo','idinciso','fechaingreso','fechatermino',
                     'ver'];"></tr>
          </table>
          <mat-paginator #paginatorRef [pageSizeOptions]="[5, 10, 15, 20]" showFirstLastButtons></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- </form> -->