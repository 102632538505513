import { TitulosService } from './../../../services/parametros/titulos.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { ApiService } from './../../../services/login/api.service';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { PostulantesService } from './../../../services/admin/postulantes/postulantes.service';
import { PostulantesModel, PostulanteEmpleoModel } from './../../../core/publico/postulantes.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-postular',
  templateUrl: './postular.component.html',
  styleUrls: ['./postular.component.css']
})
export class PostularComponent implements OnInit {

  public postulanteModel: PostulantesModel = new PostulantesModel('', '', '', '', '', '', '', '', '', '', '', '', '',
    '', '', '', '', '', '', '', '', '', '', '', '');
  public postulanteEmpleoModel: PostulanteEmpleoModel = new PostulanteEmpleoModel('', '', '', '','');

  nombreEmpresa: string;
  cargo: string;
  correoPostulante: string;
  nombrePostulante: string;
  mensajeError: string;
  correoEmpresa: string;

  constructor(
    public apiService: ApiService,
    public postulanteService: PostulantesService,
    private snackBar: MatSnackBar,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PostularComponent>,
  ) { }

  ngOnInit(): void {
    this.obtienePostulante();
    this.nombreEmpresa = this.data['nombreEmpresa'];
    this.cargo = this.data['cargo'];
    this.correoEmpresa = this.data['correoEmpresa'];

  }


  obtienePostulante() {
    this.postulanteService.getOnePostulantes(this.apiService.getRutEmpresa())
      .subscribe((postulanteModel: PostulantesModel) => {
        this.postulanteModel = postulanteModel;
        this.correoPostulante = this.postulanteModel.email;
        this.nombrePostulante = this.postulanteModel.nombreCompleto;
        
        this.postulanteEmpleoModel.idEmpleo = this.data['idEmpleo'];
        this.postulanteEmpleoModel.rutPostulante = this.apiService.getRutEmpresa();
        this.postulanteEmpleoModel.correoEmpresa = this.data['correoEmpresa'];
        this.postulanteEmpleoModel.correoPostulante =  this.postulanteModel.email;
        this.postulanteEmpleoModel.mensajeCorreo = "TEXTO CON ENVIO DE CORREO";
      })
  }

  onClose() {
    this.dialogRef.close();
  }

  envio() {

    console.log(this.postulanteEmpleoModel);

    this.postulanteService.addPostulantesEmpleo(this.postulanteEmpleoModel).subscribe(event => {
      if (event.type === HttpEventType.Response) {
        // console.log("With Parsed JSON :", event.body);

        if (String(event.body['resultado']) != 'true') {

          if (String(event.body['resultado']) == '23000') {
            this.mensajeError = "Estimado usuario usted ya postulo a este Empleo";
          }
          else {
            this.mensajeError = "Error de comunicacion codigo(" + String(event.body['resultado']) + ")";
          }

          let snackBarRef = this.snackBar.open(this.mensajeError, undefined, {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'right',
            panelClass: ["custom-style2"]
          });
          this.dialogRef.close();
        }
        else {

          console.log("AQUI SE ENVIA CORREO");
          console.log(this.postulanteEmpleoModel);

          this.postulanteService.sendMailPostulantesEmpleo(this.postulanteEmpleoModel).subscribe(event => {

          let snackBarRef = this.snackBar.open('Postulación enviada correctamente', undefined, {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'right',
            panelClass: ["custom-style"]
          });
          this.dialogRef.close();

          })

        }
      }

    })
  }

}
