<form [formGroup]="formRegEmpresa" (ngSubmit)="onSubmit()">
    <section id="guardiaPostula">
        <div class="container py-2 mx-auto">
            <div class="card card0 border-0">
                <div class="row d-flex">
                    <div class="container">
                        <div class="main-body">
                            <div class="row gutters-sm">
                                <div class="col-md-4 mt-2 ml-2 mr-1 mb-2">
                                    <div class="form-group card mt-1">
                                        <div class="card-body">
                                            <div class="d-flex flex-column align-items-center text-center">
                                                <img src="{{imagen}}" alt="Admin" class="rounded-circle" width="180" />
                                                <div>
                                                    <h4>{{titulo}}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group card mt-4 mx-auto">
                                        <div class="card-header">Descripción
                                            <span class="float-right" ngbTooltip="Ayuda">
                                                <svg width="1em" height="1em" viewBox="0 0 16 16"
                                                    class="bi bi-info-circle" fill="currentColor"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd"
                                                        d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                    <path
                                                        d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z" />
                                                    <circle cx="8" cy="4.5" r="1" />
                                                </svg>
                                            </span>
                                        </div>
                                        <p class="text-justify mx-auto" style="width: 90%;">Lorem ipsum dolor sit
                                            amet
                                            consectetur adipisicing elit. Facilis perspiciatis nam quod soluta odit
                                            voluptatibus dicta a ipsam voluptates, eos at iure sunt tempora debitis
                                            dignissimos! Quidem, non. Beatae doloribus aperiam ex at eius obcaecati
                                            soluta voluptas culpa quidem dolor, dolorem sed voluptatum in magnam!
                                            Sequi
                                            autem animi non laudantium.</p>
                                    </div>
                                </div>

                                <div class="col-md-7 mt-3 ml-3 mb-2 mr-2">
                                    <div class="card mb-1">
                                        <div class="card-body">
                                            <div class="form-row">
                                                <div class="form-group col-md-6">
                                                    <label for="lbltRut">
                                                        <h6>Rut Empresa</h6>
                                                    </label>
                                                    <input type="text" class="form-control my-1"
                                                        placeholder="Ingrese Rut Empresa" formControlName="rutEmpresa"
                                                        id="rutEmpresa" name="rutEmpresa"
                                                        [(ngModel)]="perfilempresaModel.rutEmpresa"
                                                        (blur)="validaRut(perfilempresaModel.rutEmpresa)" />
                                                    <p class="rutErrorEmp" id="validacion-live"></p>
                                                </div>


                                                <div class="form-group col-md-6">
                                                    <label for="lbltRut">
                                                        <h6>Nombre Empresa</h6>
                                                    </label>
                                                    <input type="text" id="nombreEmpresa" name="nomEmpresa"
                                                        formControlName="nomEmpresa" class="form-control my-1"
                                                        placeholder="Ingrese Nombre Empresa"
                                                        [(ngModel)]="perfilempresaModel.nomEmpresa">
                                                </div>

                                                <div class="form-group col-md-6">
                                                    <label for="lbltRut">
                                                        <h6>Rut Representante</h6>
                                                    </label>
                                                    <input type="text" id="rutRepresentante" name="rutRepresentante"
                                                        class="form-control my-1"
                                                        placeholder="Ingrese Rut Representante"
                                                        formControlName="rutRepresentante"
                                                        [(ngModel)]="perfilempresaModel.rutRepresentante">
                                                    <p class="rutErrorRep" id="validacion-live"></p>
                                                </div>

                                                <div class="form-group col-md-6">
                                                    <label for="lbltRut">
                                                        <h6>Nombre Representante</h6>
                                                    </label>
                                                    <input type="text" id="nomRepresentante" name="nomRepresentante"
                                                        class="form-control my-1"
                                                        placeholder="Ingrese Nombre Representante"
                                                        formControlName="nomRepresentante"
                                                        [(ngModel)]="perfilempresaModel.nomRepresentante">
                                                </div>

                                                <!-- <div class="form-group col-md-6">
                                                    <label for="lbltRut">
                                                        <h6>Repita Contraseña</h6>
                                                    </label>
                                                    <input type="password" id="RepetirContrasenia"
                                                        name="RepetirContrasenia" class="form-control my-1"
                                                        formControlName="passEmpresa1"
                                                        [(ngModel)]="perfilempresaModel.passEmpresa1"
                                                        placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;">
                                                </div> -->

                                                <div class="form-group col-md-6">
                                                    <label for="lbltRut">
                                                        <h6>Dirección Empresa</h6>
                                                    </label>
                                                    <input type="text" formControlName="dirEmpresa" id="direccionEmp"
                                                        class="form-control my-1" name="dirEmpresa"
                                                        placeholder="Ingrese Dirección"
                                                        [(ngModel)]="perfilempresaModel.dirEmpresa">
                                                </div>

                                                <div class="form-group col-md-6">
                                                    <label for="lbltRut">
                                                        <h6>Telefono Empresa</h6>
                                                    </label>
                                                    <input type="text" formControlName="telEmpresa" id="telEmpresa"
                                                        class="form-control my-1" name="telefonoEmp"
                                                        placeholder="Ingrese Telefono"
                                                        [(ngModel)]="perfilempresaModel.telEmpresa">
                                                </div>

                                                <div class="form-group col-md-6">
                                                    <label for="lbltRut">
                                                        <h6>Actualizar Contraseña</h6>
                                                    </label>
                                                    <input type="password" id="Contrasenia" name="Contrasenia"
                                                        class="form-control my-1" formControlName="passEmpresa"
                                                        [(ngModel)]="perfilempresaModel.passEmpresa"
                                                        placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;">
                                                </div>
                                                
                                                <div class="form-group col-md-12">
                                                    <label for="lbltRut">
                                                        <h6>Email Empresa</h6>
                                                    </label>
                                                    <input type="text" id="email" name="email" class="form-control my-1"
                                                        formControlName="emailEmpresa"
                                                        placeholder="EmailEmpresa@email.com"
                                                        [(ngModel)]="perfilempresaModel.emailEmpresa">
                                                </div>

                                            </div>

                                            <div class="form-row">
                                                <div class="form-group col-md-6 text-center">
                                                    <button type="submit" [disabled]="disableButton"
                                                        class="btn btn-outline-primary">Actualizar</button>
                                                </div>
                                                <div class="form-group col-md-6 text-center">
                                                    <button class="btn btn-outline-primary" (click)="volver()">
                                                        Volver</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</form>