export class perfil {
  constructor(
    public rut: string,
    public nombreCompleto: string,
    public fechaNacimiento: string,
    public sexo: string,
    public nombreSexo: string,
    public nacionalidad: string,
    public estadoCivil: string,
    public celular: string,
    public telefono: string,
    public email: string,
    public region: string,
    public REGION_NOMBRE: string,
    public comuna: string,
    public COMUNA_NOMBRE: string,
    public calle: string,
    public numero: string,
    public depto: string,
    public puestoTrabajo: string,
    public nombrePuesto: string,
    public cursoAcreditacion: string,
    public nombreCurso: string,
    public fechaAcreditacion: string,
    public nombreTurno: string,
    public especialidad: string,
    public nivelComputacion: string,
    public turnos: string,
    public evaluacion: string
  ) { }
}

export class busquedaPerfil {

  constructor(
    public idcargo: string,
    public idcurso: string,
    public idsexo: string,
    public idregion: string,
    public idcomuna: string,
    public idturno: string
  ) { }
}
