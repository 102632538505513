<div class="formPub" *ngIf="mostrarFormPublicidad">
    <form [formGroup]="formGestionPublicidad" (ngSubmit)="onSubmit()">
        <section id="guardiaPostula">
            <div class="container py-2 mx-auto">
                <div class="card card0 border-0">
                    <div class="row d-flex">
                        <div class="container">
                            <div class="main-body">
                                <div class="row gutters-sm">
                                    <div class="col-md-3 mt-2 ml-4 mr-2 mb-2">
                                        <div class="form-group card mt-1">
                                            <div class="card-body">
                                                <div class="d-flex flex-column align-items-center text-center">
                                                    <img src="{{this.imagenTitulo}}" alt="Admin" class="rounded-circle"
                                                        width="180" />
                                                    <div class="mt-2">
                                                        <h4>{{this.titulo}}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group card mt-4 mx-auto">
                                            <div class="card-header">Descripción
                                                <span class="float-right" ngbTooltip="Ayuda">
                                                    <svg width="1em" height="1em" viewBox="0 0 16 16"
                                                        class="bi bi-info-circle" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd"
                                                            d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                        <path
                                                            d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z" />
                                                        <circle cx="8" cy="4.5" r="1" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <p class="text-justify mx-auto" style="width: 90%;">Lorem ipsum dolor sit
                                                amet
                                                consectetur adipisicing elit. Facilis perspiciatis nam quod soluta odit
                                                voluptatibus dicta a ipsam voluptates, eos at iure sunt tempora debitis
                                                dignissimos! Quidem, non. Beatae doloribus aperiam ex at eius obcaecati
                                                soluta voluptas culpa quidem dolor, dolorem sed voluptatum in magnam!
                                                Sequi
                                                autem animi non laudantium.</p>
                                        </div>
                                    </div>

                                    <div class="col-md-8 mt-3 ml-3 mb-2 mr-2">
                                        <div class="card mb-1">
                                            <div class="card-body">
                                                <div class="form-row">
                                                    <div class="form-group col-md-6">
                                                        <mat-form-field appearance="outline"
                                                            class="example-full-width-1">
                                                            <mat-label>Rut Empresa </mat-label>
                                                            <input matInput id="rutEmpresa" formControlName="rutEmpresa"
                                                                [(ngModel)]="publicidadModel.rutEmpresa" />

                                                        </mat-form-field>
                                                        <p class="rutErrorEmp" id="validacion-live"></p>
                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <mat-form-field appearance="outline"
                                                            class="example-full-width-1">
                                                            <mat-label>Nombre Empresa</mat-label>

                                                            <input matInput formControlName="NombreEmpresa"
                                                                [(ngModel)]="publicidadModel.nomEmpresa"
                                                                id="NombreEmpresa" />
                                                        </mat-form-field>
                                                    </div>
                                                </div>

                                                <div class="form-row">
                                                    <div class="form-group col-md-6">

                                                        <mat-form-field appearance="outline"
                                                            class="example-full-width-1">
                                                            <mat-label>Nombre Contacto</mat-label>
                                                            <input matInput formControlName="nomContacto"
                                                                id="nomContacto"
                                                                [(ngModel)]="publicidadModel.nomContacto" />
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <mat-form-field appearance="outline"
                                                            class="example-full-width-1">
                                                            <mat-label>Teléfono Contacto</mat-label>
                                                            <input matInput formControlName="telefonoContacto"
                                                                id="telefonoContacto"
                                                                [(ngModel)]="publicidadModel.telefonoContacto" />
                                                        </mat-form-field>
                                                    </div>
                                                </div>

                                                <div class="form-row">
                                                    <div class="form-group col-md-6">
                                                        <mat-form-field appearance="outline"
                                                            class="example-full-width-1">
                                                            <mat-label>Fecha Inicio</mat-label>
                                                            <input matInput [min]="minDate" [matDatepicker]="picker1" id="fecIniPub"
                                                                [(ngModel)]="publicidadModel.fecIniPub"
                                                                formControlName="fecIniPub" />
                                                            <mat-datepicker-toggle matSuffix [for]="picker1">
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #picker1></mat-datepicker>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <mat-form-field appearance="outline"
                                                            class="example-full-width-1">
                                                            <mat-label>Fecha Termino</mat-label>
                                                            <input matInput [min]="minDate" [matDatepicker]="picker2" id="fecTerPub"
                                                                [(ngModel)]="publicidadModel.fecTerPub"
                                                                formControlName="fecTerPub" />
                                                            <mat-datepicker-toggle matSuffix [for]="picker2">
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #picker2></mat-datepicker>
                                                        </mat-form-field>
                                                    </div>
                                                </div>


                                                <div class="form-row">
                                                    <div class="form-group col-md-12">

                                                        <mat-form-field appearance="outline"
                                                            class="example-full-width-1">
                                                            <mat-label>Email</mat-label>
                                                            <input matInput id="email"
                                                                [(ngModel)]="publicidadModel.email"
                                                                formControlName="email" />
                                                                <mat-error *ngIf="checkError('email', 'pattern')">Formato de correo invalido</mat-error>
                                                                <mat-error *ngIf="checkError('email', 'minLength')">Correo debe tener más de 5 caracteres</mat-error> 
                                                            </mat-form-field>
                                                    </div>

                                                    <div class="form-group col-md-12">

                                                        <mat-form-field appearance="outline"
                                                            class="example-full-width-1">
                                                            <mat-label>Url Sitio</mat-label>
                                                            <input matInput id="url"
                                                                [(ngModel)]="publicidadModel.url"
                                                                formControlName="url" 
                                                                placeholder="http://www.ejemplo.cl"/>
                                                                <mat-error *ngIf="checkError('url', 'minLength')">Dirección Web debe tener más de 15 caracteres</mat-error> 
                                                            </mat-form-field>
                                                    </div>
                                                </div>
                                                <!-- <div class="alert alert-danger"
                                                    *ngIf="formGestionPublicidad.get('email').errors && formGestionPublicidad.get('email').touched">
                                                    <p *ngIf="formGestionPublicidad.get('email').hasError('email')">
                                                        Correo invalido</p>
                                                    <p *ngIf="formGestionPublicidad.get('email').hasError('required')">
                                                        Campo Requerido</p>
                                                </div> -->

                                                <div class="form-row">
                                                    <div class="form-group col-md-12">
                                                        <label for="lblSubirArchivo">Subir Archivo</label>
                                                        <input type="file" (change)="fileChange($event)"
                                                            formControlName="subirArchivo" class="form-control my-1"
                                                            id="subirArchivo1" />



                                                        <img [src]="imageSrc" *ngIf="imageSrc"
                                                            style="height: 300px; width:300px">
                                                        <div>
                                                            <button type="button" class="btn btn-outline-primary"
                                                                (click)="uploadFile()">SUBIR </button>
                                                        </div>
                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <input type="hidden" class="form-control my-1" id="imagenUrl"
                                                            [(ngModel)]="publicidadModel.imagenUrl"
                                                            formControlName="imagenUrl"
                                                            [ngClass]="{ 'is-invalid': submitted && f.imagenUrl.errors }" />


                                                        <div *ngIf="submitted && f.imagenUrl.errors">
                                                            <div class="alert alert-secondary"
                                                                *ngIf="f.imagenUrl.errors.required">Debe subir archivo
                                                                antes de enviar formulario
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>


                                                <div class="form-row">
                                                    <div class="form-group col-md-12">
                                                        <mat-form-field appearance="outline"
                                                            class="example-full-width-1">
                                                            <mat-label>Descripción de su
                                                                publicidad</mat-label>

                                                            <textarea matInput class="form-control my-1"
                                                                id="descPublicidad" rows="2"
                                                                formControlName="descPublicidad"
                                                                [(ngModel)]="publicidadModel.descPublicidad"></textarea>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="form-row">
                                                    <div class="form-group col-md-6 text-center">
                                                        <button type="submit"
                                                            class="btn btn-outline-primary">Enviar</button>
                                                    </div>
                                                    <div class="form-group col-md-6 text-center">
                                                        <button class="btn btn-outline-primary">Limpiar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </form>
</div>


<div [ngStyle]="{'display': muestraTabla }">
    <div class="container-fluid py-2 mx-auto">
        <div class="card card0 border-1">
            <div class="col-lg-11 col-md-11 col-sm-11 mx-auto mb-4">
                <div class="card-body">
                    <div class="wrapper-editor">
                        <h2 class="mb-1">Listado de Publicidad</h2>
                        <p class="mb-4 text-sm"></p>
                        <table mat-table [dataSource]="dataSource" matSort matSortStart="desc" class="mat-elevation-z8">

                            <ng-container matColumnDef="ids">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
                                <td mat-cell *matCellDef="let publicidades; let i = index;"> {{
                                    (paginatorRef.pageIndex *
                                    paginatorRef.pageSize) + (i + 1) }} </td>
                            </ng-container>

                            <ng-container matColumnDef="rutEmpresa">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Cargo Empleo</th>
                                <td mat-cell *matCellDef="let publicidades"> {{publicidades.rutEmpresa}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="nomEmpresa">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre Empresa</th>
                                <td mat-cell *matCellDef="let publicidades"> {{publicidades.nomEmpresa}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="nomContacto">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre Contacto</th>
                                <td mat-cell *matCellDef="let publicidades"> {{publicidades.nomContacto}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="fecIniPub">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha Publicación</th>
                                <td mat-cell *matCellDef="let publicidades"> {{publicidades.fecIniPub}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="fecTerPub">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha Termino</th>
                                <td mat-cell *matCellDef="let publicidades"> {{publicidades.fecTerPub}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Editar">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Editar</th>
                                <td mat-cell *matCellDef="let publicidades">
                                    <a mat-raised-button color="primary" mat-icon-button
                                        [routerLink]="['/accesoAdmin/EditPublicidad', publicidades.idpublicidad]">
                                        <mat-icon>edit</mat-icon>
                                    </a>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Eliminar">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Eliminar</th>
                                <td mat-cell *matCellDef="let publicidades">
                                    <button mat-raised-button color="warn" data-toggle="modal"
                                        (click)="eliminarPublicidad(publicidades.idpublicidad)" mat-icon-button>
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                    <!-- 
                                <a mat-raised-button color="warn" href="#deleteEmployeeModal" class="delete"
                                    data-toggle="modal" (click)="editarPublicidad(item)">
                                    <mat-icon>delete</mat-icon>
                                </a> -->

                                </td>
                            </ng-container>


                            <tr mat-header-row
                                *matHeaderRowDef="['ids','rutEmpresa','nomEmpresa','nomContacto','fecIniPub','fecTerPub','Editar','Eliminar']">
                            </tr>
                            <tr mat-row
                                *matRowDef="let fila; columns: ['ids','rutEmpresa','nomEmpresa','nomContacto','fecIniPub','fecTerPub','Editar','Eliminar'];">
                            </tr>
                        </table>
                        <mat-paginator #paginatorRef [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons>
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>