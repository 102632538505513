import { TitulosService } from './../../../services/parametros/titulos.service';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { FaConfig, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faBellSlash, faHandPaper, faUser } from '@fortawesome/free-regular-svg-icons';
import { faCoffee, faTrash, faTrashAlt, faPencilAlt, faTh, faCalendar, faCalendarAlt, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

import { SelectionModel } from '@angular/cdk/collections';

import * as $ from 'jquery';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogoconfirmacionComponent } from "../../../dialogoconfirmacion/dialogoconfirmacion.component";
import { AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { consultarempleado } from '../../../core/empresa/consultarempleado';
import { ConsultarempleadoService } from '../../../services/empresa/consultarempleado/consultarempleado.service';
import { DetallemisevaluacionesComponent } from '../../empresa/detallemisevaluaciones/detallemisevaluaciones.component';
import { ApiService } from '../../../services/login/api.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-misevaluaciones',
  templateUrl: './misevaluaciones.component.html',
  styleUrls: ['./misevaluaciones.component.css']
})


export class MisevaluacionesComponent implements OnInit {
  formMisEvaluaciones: FormGroup;
  public rutConsultado;
  public rutEmpleado;
  public rutEmpresa;

  public empleados: consultarempleado[] = [];
  //   new consultarempleado("1", "1-9", 'Juan Perez', "empresa x", "2021-02-02", "2021-02-02", "", "articulo", "", "inciso", "observacion", "si", "si", "")
  // ];

  dataSource = null;
  selection = null;

  constructor
    (
      private ruta: ActivatedRoute,
      private router: Router,
      private servicioConsulta: ConsultarempleadoService,
      private dialogo: MatDialog,
      private snackBar: MatSnackBar,
      private dialog: MatDialog,
      public apiService: ApiService,
      private changeDetectorRefs: ChangeDetectorRef,
      private nombreTituloService: TitulosService,
      private datePipe: DatePipe

    ) { }


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;



  ngOnInit(): void {
    this.nombreTituloService.nombreTitulos = "Mis Evaluaciones";
    let valorEntrada = this.ruta.snapshot.paramMap.get("id");
    let tag = valorEntrada.indexOf("!");
    this.rutEmpleado = valorEntrada.substr(0, tag);
    this.rutEmpresa = valorEntrada.substr(tag + 1, valorEntrada.length);

    console.log("valorEntrada---->");
    console.log(valorEntrada);

    if (valorEntrada === "0") {

      console.log("----> ENTRO CON 0");
      this.rutEmpleado = "0";
      this.rutEmpresa = this.apiService.getRutEmpresa();
      this.obtenerEvaluacionRutEmpresa();

    } else {

      console.log("----> ENTRO DISTINTO A 0");
      this.rutConsultado = this.rutEmpleado;
      //LLAMAR al evento del servicio
      this.obtenerConsultado();
    }

  }

  //Otros Eventos
  onChangeFiltro(value) {
    // console.log("valor seleccionado" + value);

    // if(value === "1"){
    //Obtener sin autorizar
    return this.servicioConsulta.getListarEvaluadosPorEstado(value).
      subscribe((empleados: consultarempleado[]) => {
        this.empleados = empleados;
        this.dataSource = new MatTableDataSource<consultarempleado>(this.empleados);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }


  obtenerEvaluacionRutEmpresa() {
    return this.servicioConsulta.getMisEvaluacionEmpresa(this.rutEmpresa).
      subscribe((empleados: consultarempleado[]) => {
        this.empleados = empleados
        this.dataSource = new MatTableDataSource<consultarempleado>(this.empleados);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.selection = new SelectionModel<consultarempleado>(true, []);
      });
  }

  obtenerConsultado() {
    return this.servicioConsulta.getEvaluacionEmpresaEmpleado(this.rutEmpleado, this.rutEmpresa).
      subscribe((empleados: consultarempleado[]) => {
        this.empleados = empleados
        this.dataSource = new MatTableDataSource<consultarempleado>(this.empleados);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.selection = new SelectionModel<consultarempleado>(true, []);
      });
  }

  eliminarEvaluacion(value) {
    this.dialogo
      .open(DialogoconfirmacionComponent, {
        data: `¿Realmente quieres eliminar la Evaluación?`
      })
      .afterClosed()
      .subscribe((confirmado: Boolean) => {
        if (!confirmado) return;
        this.servicioConsulta
          .deleteEvaluacion(value)
          .subscribe(() => {
            this.snackBar.open('Evaluación eliminada', value, {
              duration: 3000,
            });
          });
        this.obtenerConsultado();
      })
  }


  verDetalleEvaluacion(empleado: consultarempleado) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.dialog.open(DetallemisevaluacionesComponent, {
      height: '600px',
      width: '800px',
      data: {

        idEvaluacion: empleado.idEvaluacion,
        dataorigen: "misevaluaciones",
        estado: empleado.estado,
        observacion: "",
        generaPdf: false
      }
    });
  }


  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }


  isSomeSelected() {
    return this.selection.selected.length > 0;
  }



  logSelection() {
    this.selection.selected.forEach(s => {
      this.dialog.open(DetallemisevaluacionesComponent, {
        height: '600px',
        width: '800px',
        data: {
          idEvaluacion: s.idEvaluacion,
          dataorigen: "misevaluaciones",
          estado: s.estado,
          observacion: "",
          generaPdf: true
        }
      });

    });
  }



}
