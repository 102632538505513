import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiuserService {

  constructor(protected http: HttpClient) { }


  getUsers() {
    return this.http.get('http://desarrollos.curin.cl/APIS/restapi/v1/auto');
  }

}
