<div class="container-fluid py-2 mx-auto">
    <div class="card card0 border-1">
        <div class="col-lg-10 col-md-10 col-sm-10 mx-auto mb-4">
            <div class="card-body">
                <div class="wrapper-editor">
                    <div class="text-center">
                        <h3>Empleos Recientes</h3>
                    </div>

                    <table class="table-job-offers table-responsive">
                        <tbody>
                            <tr *ngFor="let item of empleos; index as i">
                                <td class="table-job-offers-badge">
                                    <button mat-raised-button class="badge badge-secondary"
                                        [routerLink]="['/accesoPostulante/detalleEmpleo', item.idEmpleo]">
                                        Postular
                                    </button>
                                </td>

                                <td class="table-job-offers-date">
                                    <span>{{item.fecPostulacion}}</span>
                                </td>
                                <td class="table-job-offers-meta">
                                    <!-- Company Light-->
                                    <!-- <article class="company-light"> -->
                                    <!-- <figure class="company-light-figure"><img class="company-light-image"
                                                                        src="../../../assets/img/company-1-45x45.png" alt="" width="45"
                                                                        height="45">
                                                                </figure> -->
                                    <div class="company-light-main">
                                        <h5 class="company-light-title"><a
                                                [routerLink]="['/accesoPostulante/detalleEmpleo', item.idEmpleo]">{{item.profesion}}</a>
                                        </h5>
                                        <p>{{item.empresa}}</p>
                                    </div>
                                    <!-- </article> -->
                                </td>
                                <td class="table-job-offers-meta">
                                    <div class="object-inline"><span
                                            class="icon icon-sm text-primary mdi mdi-cash"></span><span>$
                                            {{item.sueldo | currency:'CLP':'symbol'}}</span>
                                    </div>
                                </td>
                                <td class="table-job-offers-meta">
                                    <div class="object-inline"><span
                                            class="icon icon-1 text-primary mdi mdi-map-marker"></span><span>{{item.comuna}}</span>
                                    </div>
                                </td>
                                <td class="table-job-offers-badge">

                                    <div class="object-inline"><span
                                            class="icon icon-1 text-primary mdi mdi-map-marker"></span><span>{{item.jornada}}</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>
</div>