import { Resolve } from '@angular/router';
import { Injectable } from "@angular/core";
// import 'rxjs/add/operator/toPromise';
// import { AngularFireAuth } from '@angular/fire/auth';
// import * as firebase from 'firebase/app';
import { map } from 'rxjs/operators';
// import { auth } from 'firebase/app';


@Injectable()
export class AuthService {

  constructor(
  //  public afAuth: AngularFireAuth
 ){}

  // doRegister(value){
  //   return new Promise<any>((resolve, reject) => {
  //     firebase.auth().createUserWithEmailAndPassword(value.email, value.password)
  //     .then(res => {
  //       resolve(res);
  //     }, err => reject(err))
  //   })
  // }

  // doLogin(value){
  //   return new Promise<any>((resolve, reject) => {
  //     firebase.auth().signInWithEmailAndPassword(value.email, value.password)
  //     .then(res => {
  //       resolve(res);
  //     }, err => reject(err))
  //   })
  // }

  // doLogout(){
  //   return new Promise((resolve, reject) => {
  //     if(firebase.auth().currentUser){
  //       this.afAuth.auth.signOut();
  //       resolve();
  //     }
  //     else{
  //       reject();
  //     }
  //   });
  // }

  // isAuth(){
  //   return this.afAuth.authState.pipe(map(auth => auth));
  // }

}